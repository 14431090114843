import React, { useState, useEffect } from "react";
import {
  Button,
  Tabs,
  Tab,
  Accordion,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { serverPath } from "../../config/key";
import Layout from "../layout/layout";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ConsultationPopup from "../modals/consultation";
import stars from "../../assets/images/home/stars.svg";
import hero1 from "../../assets/images/home/hero1.webp";
import hero2 from "../../assets/images/home/hero2.webp";
import hero3 from "../../assets/images/home/hero3.webp";
import hero4 from "../../assets/images/home/hero4.webp";
import herom1 from "../../assets/images/home/herom1.webp";
import herom2 from "../../assets/images/home/herom2.webp";
import herom3 from "../../assets/images/home/herom3.webp";
import herom4 from "../../assets/images/home/herom4.webp";
import goal1 from "../../assets/images/home/goal1.svg";
import goal2 from "../../assets/images/home/goal2.svg";
import goal3 from "../../assets/images/home/goal3.svg";
import studentV from "../../assets/images/home/studentV.webp";
import workingV from "../../assets/images/home/workingV.webp";
import businessV from "../../assets/images/home/businessV.webp";
import whV from "../../assets/images/home/whV.webp";
import familyV from "../../assets/images/home/familyV.webp";
import blog1 from "../../assets/images/home/blog1.webp";
import blog2 from "../../assets/images/home/blog2.webp";
import blog3 from "../../assets/images/home/blog3.webp";
import blog4 from "../../assets/images/home/blog4.webp";
import blog5 from "../../assets/images/home/blog5.webp";
import newslettericon from "../../assets/images/home/newsletter-icon.svg";
import faqshell from "../../assets/images/home/shell.svg";
import accessiblity from "../../assets/images/header/accessibility.svg";
import chatbot from "../../assets/images/header/chat-bot.svg";
import checkicon from "../../assets/images/header/success.svg";
import parse from "html-react-parser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import "./home.css";
import { NewsLetterSchema } from "../../utils/validationSchema";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
// import Eligibility from "./eligibility";
const Home = () => {
  const { t } = useTranslation();
  const [succ, setSucc] = useState(false);
  const { userGeoData } = useContext(UserContext);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(NewsLetterSchema) });
  const [key, setKey] = useState(localStorage.getItem("key") || "permanent");
  useEffect(() => {
    localStorage.setItem("key", key);
  }, [key]);
  const [show] = useState(false);
  const handleClose = () => {};
  // const handleShow = () => setShow(true);
  const handleSubmitForm = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/forms/newsletter`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      });
      const {
        data: { msg, success },
        status,
      } = response;
      if (success || status === 200 || status === 201) {
        setSucc(true);
        toast.success(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  var hero = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 9000,
    fade: true,
    cssEase: "linear",
  };
  const [postcontent, setPostContent] = useState([]);
  const productData = () => {
    axios
      .get(`${serverPath}/social/feed`, {
        params: {
          limit: 5,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setPostContent(data);
      });
  };
  useEffect(() => {
    productData();
  }, []);
  return (
    <Layout>
      <div className="home-page">
        {/* ------------------------------------------------S0 - HERO------------------------------------------------ */}
        <div className="home-hero-banner">
          <div className="home-hero-slider">
            <Slider {...hero}>
              {/* slide1  */}
              <div>
                <div class="image">
                  <img className="d-none d-lg-block" src={hero1} alt="" />
                  <img className="d-lg-none" src={herom1} alt="" />
                </div>
              </div>
              {/* slide2  */}
              <div>
                <div class="image">
                  <img className="d-none d-lg-block" src={hero2} alt="" />
                  <img className="d-lg-none" src={herom2} alt="" />
                </div>
              </div>
              {/* slide3  */}
              <div>
                <div class="image">
                  <img className="d-none d-lg-block" src={hero3} alt="" />
                  <img className="d-lg-none" src={herom3} alt="" />
                </div>
              </div>
              {/* slide4  */}
              <div>
                <div class="image">
                  <img className="d-none d-lg-block" src={hero4} alt="" />
                  <img className="d-lg-none" src={herom4} alt="" />
                </div>
              </div>
            </Slider>
          </div>
          <div className="container">
            <div className="home-hero-content">
              <div className="floating-menus">
                <Link to="">
                  <img src={accessiblity} alt="" />
                </Link>
                <Link to="">
                  <img src={chatbot} alt="" />
                </Link>
              </div>
              <div className="home-hero-content-wrapper">
                <div className="rating-box">
                  <Link to="/reviews">
                    <div className="stars">
                      <img src={stars} alt="" />
                    </div>
                    <span className="review">4.7</span>
                    <span>{t("homeBanner.review")}</span>
                  </Link>
                </div>

                <h2>
                  Australian visas:
                  <br />
                  {t("homeBanner.heading")}
                </h2>
                {/* <p>{t('homeBanner.paragraph')}</p> */}

                <div className="home-hero-button">
                  <Button className="blue-button" onClick={() => { window.location.href = "https://www.uisaustralia.com/lp/en/global/immigration-v3/" } }>
                    {t("homeBanner.bluebutton")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------------------------------------S1 - ABOUT------------------------------------------- */}

        <div className="home-about">
          <div className="container">
            <div className="home-about-grid ">
              <div className="home-about-content">
                <h2>{t("homeAbout.heading")}</h2>
                <p>{t("homeAbout.paragraph1")}</p>
                <p>{t("homeAbout.paragraph2")}</p>
                <Link className="link-hover" to="/about">
                  {t("homeAbout.readmore")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------------------------------------S2 - VISA------------------------------------------- */}

        <div className="home-visa-type" id="visa-type">
          <div className="container">
            <div className="home-visa-heading">
              <h2>{t("homeVisa.heading")}</h2>
              <p>{parse(t("homeVisa.paragraph"))}</p>
            </div>

            <div className="home-visa-tab" id="visas">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="permanent" title={t("homeVisa.permanent")}>
                  <div className="home-visa-grid">
                    <div className="home-visa-content">
                      <div className="home-visa-grid">
                        {/* visa1   */}
                        <div className="home-visa-box">
                          <a
                            href="/visa/worker"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="home-visa-gutter">
                              <div className="home-visa-cover">
                                <img src={workingV} alt="" />
                              </div>

                              <div className="home-visa-content-text">
                                <h4>{t("homevisabox1.heading")}</h4>
                                <p>{t("homevisabox1.paragraph")}</p>
                                <a
                                  className="link-hover"
                                  href="/visa/worker"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("homevisabox1.learnmore")}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>

                        {/* visa2   */}
                        <div className="home-visa-box">
                          <a
                            href="/visa/family"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="home-visa-gutter">
                              <div className="home-visa-cover">
                                <img src={familyV} alt="" />
                              </div>

                              <div className="home-visa-content-text">
                                <h4>{t("homevisabox2.heading")}</h4>
                                <p>{t("homevisabox2.paragraph")}</p>
                                <a
                                  className="link-hover"
                                  href="/visa/family"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("homevisabox2.learnmore")}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>

                        {/* visa3   */}
                        <div className="home-visa-box">
                          <a
                            href="/visa/business"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="home-visa-gutter">
                              <div className="home-visa-cover">
                                <img src={businessV} alt="" />
                              </div>

                              <div className="home-visa-content-text">
                                <h4>{t("homevisabox3.heading")}</h4>
                                <p>{t("homevisabox3.paragraph")}</p>
                                <a
                                  className="link-hover"
                                  href="/visa/business"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("homevisabox3.learnmore")}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="temporary" title={t("homeVisa.temporary")}>
                  <div className="home-visa-grid">
                    <div className="home-visa-content">
                      <div className="home-visa-grid">
                        {/* visa4   */}
                        <div className="home-visa-box">
                          <a
                            href="/visa/holiday"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="home-visa-gutter">
                              <div className="home-visa-cover">
                                <img src={whV} alt="" />
                              </div>

                              <div className="home-visa-content-text">
                                <h4>{t("homevisabox4.heading")}</h4>
                                <p>{t("homevisabox4.paragraph")}</p>
                                <a
                                  className="link-hover"
                                  href="/visa/holiday"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("homevisabox4.learnmore")}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>

                        {/* visa6   */}
                        <div className="home-visa-box">
                          <a
                            href="/visa/student"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="home-visa-gutter">
                              <div className="home-visa-cover">
                                <img src={studentV} alt="" />
                              </div>

                              <div className="home-visa-content-text">
                                <h4>{t("homevisabox6.heading")}</h4>
                                <p>{t("homevisabox6.paragraph")}</p>
                                <a
                                  className="link-hover"
                                  href="/visa/student"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("homevisabox6.learnmore")}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------S3 - form------------------------------------------- */}
        {/* -------------------------------------------------S4 - goals------------------------------------------- */}

        <div className="home-goal">
          <div className="container">
            <h2>{t("homeGoal.heading")}</h2>
            {/* <img class="bg d-none d-lg-block" src={leafL} alt="" /> */}
            <div className="home-goal-grid">
              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal1} alt="" />
                <h4>{t("homeGoalgrid1.heading")}</h4>
                <p>{t("homeGoalgrid1.paragraph")}</p>
              </div>

              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal2} alt="" />
                <h4>{t("homeGoalgrid2.heading")}</h4>
                <p>{t("homeGoalgrid2.paragraph")}</p>
              </div>

              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal3} alt="" />
                <h4>{t("homeGoalgrid3.heading")}</h4>
                <p>{t("homeGoalgrid3.paragraph")}</p>
              </div>
            </div>

            <div className="home-goal-button">
              <p>{t("homeGoalbutton.paragraph")}</p>
              <Button className="blue-button" onClick={() => { window.location.href = "https://www.uisaustralia.com/lp/en/global/immigration-v3/" } }>
                {t("homeGoalbluebutton.paragraph")}
              </Button>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------S5 - face------------------------------------------- */}
        <div className="home-blog-face">
          <div className="container">
            <div className="home-facebook">
              <div class="social">
                <h2>{t("homeBlog.homeFacebookheading")}</h2>
                <div className="social-icons">
                  <p className="hashtag">#UISAUSTRALIA</p>
                  <a
                    className="fb-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uisAustraliaoffical"
                  >
                    {" "}
                  </a>
                  <a
                    className="in-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/uis_australia/"
                  >
                    {" "}
                  </a>
                  <a
                    className="ln-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                  >
                    {" "}
                  </a>
                  <a
                    className="tw-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_australia"
                  >
                    {" "}
                  </a>
                </div>
              </div>
              <div className="home-facebook-grid">
                {postcontent.map((post, i) => (
                  <div className="home-facebook-box" key={i}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={post.permalink_url}
                    >
                      <div className="home-facebook-cover">
                        <img src={post.full_picture} alt="" />
                      </div>
                      <div className="home-facebook-content">
                        <p>{post.message}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------S - blog------------------------------------------- */}
        <div className="home-blog">
          <div className="container">
            {/* <img className="blog-kenguru d-none d-lg-block" src={kenguru} alt="" />
            <img className="blog-flower d-none d-lg-block" src={flower2} alt="" /> */}
            <h2>{t("homeBlog.homeBlogheading")}</h2>
            <div className="blogs">
              <div className="home-blog-grid">
                {/* blog1  */}
                <div className="home-blog-box">
                  <a
                    href="https://www.uisaustralia.com/blog/immigration-to-australia-agricultural-immigration-visa-for-farmers/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog1} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>August 28, 2022</span>
                        <h5>{t("homeBlogcontent1.homeBlogheading")}</h5>
                        <a
                          className="link-hover d-none d-lg-block"
                          href="https://www.uiscanada.com/blog/the-4-best-apps-to-learn-english-before-moving-to-canada/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("homeBlogcontent1.readmore")}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>

                {/* blog2  */}
                <div className="home-blog-box">
                  <a
                    href="https://www.uisaustralia.com/blog/immigration-to-australia-5-smart-finance-tips-to-be-prepared/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog2} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>August 28, 2022</span>
                        <h5>{t("homeBlogcontent2.homeBlogheading")}</h5>
                        <a
                          className="link-hover d-none d-lg-block"
                          href="https://www.uiscanada.com/blog/7-things-youll-wish-someone-told-you-before-moving-to-canada/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("homeBlogcontent2.readmore")}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="home-blog-grid2">
                {/* blog3  */}
                <div className="home-blog-box">
                  <a
                    href="https://www.uisaustralia.com/blog/the-14-easiest-jobs-to-land-in-australia/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog3} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>August 28, 2022</span>

                        <h5>{t("homeBlogcontent3.homeBlogheading")}</h5>
                      </div>
                    </div>
                  </a>
                </div>
                {/* blog4  */}
                <div className="home-blog-box">
                  <a
                    href="https://www.uisaustralia.com/blog/moving-to-australia-as-a-nurse-a-quick-guide/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog4} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>August 28, 2022</span>

                        <h5>{t("homeBlogcontent4.homeBlogheading")}</h5>
                      </div>
                    </div>
                  </a>
                </div>

                {/* blog5  */}
                <div className="home-blog-box">
                  <a
                    href="https://www.uisaustralia.com/blog/everything-you-should-know-about-opening-a-bank-account-in-australia/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog5} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>August 28, 2022</span>

                        <h5>{t("homeBlogcontent5.homeBlogheading")}</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="home-blog-button">
              <a
                href="https://www.uisaustralia.com/blog/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="blue-button">
                  {" "}
                  {t("homeBlogbutton.outlineButton")}
                </Button>
              </a>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------S - FAQ------------------------------------------- */}
        <div className="home-faq" id="faq">
          <div className="container">
            <h2>{t("homeFaq.heading")}</h2>
            <img className="faq-shell" src={faqshell} alt="" />
            <div className="home-faq-grid">
              <Accordion>
                {/* <Accordion defaultActiveKey="0"> */}
                {/* <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t("homeFaq0.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq0.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {t("homeFaq1.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq1.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {t("homeFaq2.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq2.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {t("homeFaq3.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq3.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {t("homeFaq4.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {parse(t("homeFaq4.accordionparagraph"))}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {t("homeFaq5.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq5.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    {t("homeFaq6.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq6.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    {t("homeFaq7.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq7.accordionparagraph")}
                    <a
                      className="link-hover link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/electronic-travel-authority-601#Eligibility"
                    >
                      {t("homeFaq7.accordionlink")}
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    {t("homeFaq8.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq8.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    {t("homeFaq9.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq9.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    {t("homeFaq10.accordionheading")}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t("homeFaq10.accordionparagraph")}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="home-faq-button">
              <p className="text-center">{t("homeFaqbutton.buttonheading")}</p>
              <Link to="/contact">
                <Button
                  className="blue-button"
                  onClick={() => (window.location.href = "/contact")}
                >
                  {t("homeFaqbutton.outlineButton")}
                </Button>
              </Link>
            </div>
          </div>
        </div>

        {/* -------------------------------------------------S - newsletter------------------------------------------- */}
        <div className="home-newsletter">
          <div className="container">
            <div className="newletter-grid">
              <div className="newletter-content">
                <img src={newslettericon} alt="" />
                <h2>{t("homeNewsletter.homeNewsheading")}</h2>
                <Form
                  className="d-flex"
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <FormControl
                    className={`${errors?.email ? `error-brd` : ``}`}
                    type="text"
                    name="email"
                    placeholder={
                      errors?.email
                        ? errors?.email?.message
                        : t("visaContact.emailaddress")
                    }
                    {...register("email")}
                  />

                  <Button
                    type="submit"
                    className={`blue-button ${succ ? `success-btn` : ``}`}
                  >
                    {succ
                      ? t("homeNewsletter.successButton")
                      : t("homeNewsletter.blueButton")}
                    {succ ? <img src={checkicon} alt="" /> : ""}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="consultation-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ConsultationPopup />
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Home;
