import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";

import business from "../../assets/images/visa/business.webp";
import "./visa.css";
import parse from 'html-react-parser';
import businessfoot from "../../assets/images/visa/businessfoot.webp";
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
const BusinessVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='visa-page worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <a
                className="breadcrum"
                href="/#visa-type"
              >
                {t('visas.businessHeading1')}
              </a>
              <div className='worker-visa-banner-heading-title'>
                <h2>{t("visaBusiness.businessHeading1")}</h2>
              </div>
            </div>

            <div className='worker-grid first'>
              <div className='worker-content'>
                <p>{t("gridBusiness.businessParagraph1")}</p>
                <p>{t("gridBusiness.businessParagraph2")}</p>
                <p>{t("gridBusiness.businessParagraph3")}</p>
                <p>{t("gridBusiness.businessParagraph4")}</p>
              </div>

              <div className='worker-cover rightimg'>
                <img src={business} alt='' />
              </div>
            </div>

            <div className='worker-visa-Types'>
              <h2>{t("contentBusiness.businessHeading1")}</h2>
              <p>{t("contentBusiness.businessParagraph1")}</p>

              <h4>{t("contentBusiness.businessHeading2")}</h4>

              <p>{parse(t("contentBusiness.businessParagraph2"))}</p>
              <ul>
                <li>{t("contentBusiness.businessList1")}</li>
                <li>{t("contentBusiness.businessList2")}</li>
                <li>{t("contentBusiness.businessList3")}</li>
                <li>{t("contentBusiness.businessList4")}</li>
                <li>{t("contentBusiness.businessList5")}</li>
                <li>{t("contentBusiness.businessList6")}</li>
                <li>{t("contentBusiness.businessList7")}</li>
                <li>{t("contentBusiness.businessList8")}</li>
              </ul>


              <h4>{t("contentBusiness.businessHeading3")}</h4>
              <p>{t("contentBusiness.businessParagraph3")}</p>
              <p>{t("contentBusiness.businessParagraph4")}</p>
              <ul>
                <li>{t("contentBusiness.businessList1")}</li>
                <li>{t("contentBusiness.businessList2")}</li>
                <li>{t("contentBusiness.businessList3")}</li>
                <li>{t("contentBusiness.businessList4")}</li>
                <li>{t("contentBusiness.businessList5")}</li>
                <li>{t("contentBusiness.businessList9")}</li>
                <li>{t("contentBusiness.businessList10")}</li>
              </ul>
              <p>{parse(t("contentBusiness.businessParagraph5"))}</p>

              <h4>{t("contentBusiness.businessHeading4")}</h4>
              <p>{t("contentBusiness.businessParagraph6")}</p>
              <p>{t("contentBusiness.businessParagraph7")}</p>
              <ul>
                <li>{t("contentBusiness.businessList2")}</li>
                <li>{t("contentBusiness.businessList11")}</li>
                <li>{t("contentBusiness.businessList4")}</li>
                <li>{t("contentBusiness.businessList5")}</li>
                <li>{t("contentBusiness.businessList12")}</li>
              </ul>
              <p>{parse(t("contentBusiness.businessParagraph8"))}</p>


              <h4>{t("contentBusiness.businessHeading5")}</h4>
              <p>{parse(t("contentBusiness.businessParagraph9"))}</p>
              <ul>
                <li>{t("contentBusiness.businessList2")}</li>
                <li>{t("contentBusiness.businessList13")}</li>
                <li>{t("contentBusiness.businessList14")}</li>
                <li>{t("contentBusiness.businessList15")}</li>
                <li>{t("contentBusiness.businessList16")}</li>
                <li>{t("contentBusiness.businessList17")}</li>
              </ul>


            </div>

            <div className='worker-visa-Types'>
              <h2>{t("contentBusiness.businessHeading6")}</h2>
              <p>{parse(t("contentBusiness.businessParagraph10"))}</p>
              <div className="hourglass">
                <ul>
                  <li>{t("contentBusiness.businessList18")}</li>
                  <li>{t("contentBusiness.businessList19")}</li>
                  <li>{t("contentBusiness.businessList20")}</li>
                  <li>{t("contentBusiness.businessList21")}</li>
                  {/* <li>{t("contentBusiness.businessList22")}</li> */}
                </ul>
              </div>
              <p>{t("contentBusiness.businessParagraph11")}</p>




            </div>

            <div className='worker-visa-Types'>
              <h2>{t("contentBusiness.businessHeading7")}</h2>
              <p>{parse(t("contentBusiness.businessParagraph12"))}</p>
              <ul>
                <li>{t("contentBusiness.businessList22")}</li>
                <li>{t("contentBusiness.businessList23")}</li>
                <li>{t("contentBusiness.businessList24")}</li>
                <li>{t("contentBusiness.businessList25")}</li>
                <li>{t("contentBusiness.businessList26")}</li>
                <li>{t("contentBusiness.businessList27")}</li>
                <li>{t("contentBusiness.businessList28")}</li>
                <li>{t("contentBusiness.businessList29")}</li>
              </ul>
              <p>{t("contentBusiness.businessParagraph13")}</p>
              <ul>
                <li>{t("contentBusiness.businessList30")}</li>
                <li>{t("contentBusiness.businessList31")}</li>
                <li>{t("contentBusiness.businessList32")}</li>
              </ul>
              <p>{t("contentBusiness.businessParagraph14")}</p>

            </div>

            <div className='worker-visa-Types'>
              <h2>{t("contentBusiness.businessHeading8")}</h2>
              <p>{t("contentBusiness.businessParagraph15")}</p>
              <ol>
                <li>{t("contentBusiness.businessList33")}</li>
                <li>{t("contentBusiness.businessList34")}</li>
                <li>{t("contentBusiness.businessList35")}</li>
                <li>{t("contentBusiness.businessList36")}</li>
              </ol>
              <p>{t("contentBusiness.businessParagraph16")}</p>
            </div>

          </div>
        </div>

        <div className="visa-contact text-center">
          <h4>{t("visaContactFoot.Heading1")}</h4>
          <p>{t("visaContactFoot.Paragraph1")}</p>
          <p className="text-center">
            <Link to="/contact">
              <Button
                className="blue-button"
                onClick={() => (window.location.href = '/contact')}
              >
                {t('visaContactFoot.ContactBtn')}
              </Button>
            </Link>
          </p>
        </div>
        <div className="visa-foot"><img src={businessfoot} alt='' /></div>

      </div>
    </Layout>
  );
};

export default BusinessVisa;
