import React from "react";
import Layout from "../layout/layout";
import aboutbanner from "../../assets/images/about/teamBanner.png";
import mara from "../../assets/images/about/mara.webp";
import aboutBanner from "../../assets/images/about/aboutBanner.webp";
import aboutBannerMob from "../../assets/images/about/aboutBannerMob.webp";
//import trustpilot from "../../assets/images/reviews/trustpilot.svg";
import "./about.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
const About = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="about-page">
        {/* --------------------------------S1 - ABOUT ------------------------*/}
        <div className="about-team">
          <div className="container">
            <h2>{t("aboutUs.aboutHeading")}</h2>
            <div className="about-about-help-first-content">
              <div className="about-about-help-content">


                <p>{t("aboutUs.aboutParagraph1")}</p>

                <p>{t("aboutUs.aboutParagraph2")}</p>
                <p>{t("aboutUs.aboutParagraph3")}</p>
                <p>{t("aboutUs.aboutParagraph4")}</p>

              </div>
              <div className="about-social">
                <div className="social-icons">
                  <a
                    className="fb-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uisAustraliaoffical"
                  >
                    {" "}
                  </a>
                  <a
                    className="in-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/uis_australia/"
                  >
                    {" "}
                  </a>
                  <a
                    className="ln-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                  >
                    {" "}
                  </a>
                  <a
                    className="tw-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_australia"
                  >
                    {" "}
                  </a>
                </div>
              </div></div>

            <div>

              <h3>{t("aboutUs.aboutHeading2")}</h3>

              <p className="d-lg-none">{t("aboutUs.aboutParagraph5")}</p>
              <div className="team-cover">
                <img className="d-none d-lg-block" src={aboutBanner} alt="" />
                <img className="d-lg-none" src={aboutBannerMob} alt="" />
              </div>
              <p className="d-none d-lg-block">{t("aboutUs.aboutParagraph5")}</p>
            </div>

          </div>
        </div>

        {/* --------------------------------S3 - Team ------------------------*/}
        <div className="about-team">
          <div className="container">
            <div className="about-banner-grid">
              <div className="about-banner-cover d-none d-lg-block">
                <img src={aboutbanner} alt="" />
              </div>

              <div className="about-banner-content">
                <h2>{t("aboutTeam.aboutHeading")}</h2>

                <p>{t("aboutTeam.aboutParagraph1")}</p>

                <p>{t("aboutTeam.aboutParagraph2")}</p>

                <p>{t("aboutTeam.aboutParagraph3")}</p>
                <div className=" team-count ">
                  <div>
                    <h3>320,000+</h3>
                    <p className="small">
                      {t("aboutTeam.aboutsmallParagraph1")}
                    </p>
                  </div>
                  <div>
                    <h3>200</h3>
                    <p className="small">
                      {t("aboutTeam.aboutsmallParagraph2")}
                    </p>
                  </div>
                  <div>
                    <h3>60+</h3>
                    <p className="small">
                      {t("aboutTeam.aboutsmallParagraph3")}
                    </p>
                  </div>
                  <div>
                    <h3>86%</h3>
                    <p className="small">
                      {t("aboutTeam.aboutsmallParagraph4")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="about-banner-cover d-lg-none">
                <img src={aboutbanner} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* --------------------------------S3 - MARA ------------------------*/}
        <div className="about-mara">
          <div className="container">
            <div className="about-mara-grid">
              <div className="about-mara-content">
                <h2>{t("aboutMaracover.aboutHeading")}</h2>
                <div className="about-mara-headng">
                  <div className="mara-content d-block">
                    <p>{t("aboutMaracover.aboutParagraph1")}</p>
                    <p>{t("aboutMaracover.aboutParagraph2")}</p>
                  </div>

                  <div className="about-mara-cover">
                    <img src={mara} alt="" />
                  </div>
                </div>
                <div className="busineselig">
                  <ul>
                    <li>{t("aboutMaracover.aboutList1")}</li>
                    <li>{t("aboutMaracover.aboutList2")}</li>
                    <li>{t("aboutMaracover.aboutList3")}</li>
                  </ul>
                  <ul>
                    {/* <li>{t("aboutMaracover.aboutList4")}</li> */}
                    <li>{t("aboutMaracover.aboutList6")}</li>
                    <li>{t("aboutMaracover.aboutList5")}</li>
                  </ul>
                  <ul>
                    
                    <li>{t("aboutMaracover.aboutList7")}</li>
                  </ul>
                </div>
                <p className="text-center">
                  <Link to="/contact">
                    <Button
                      className="blue-button"
                      onClick={() => (window.location.href = "/contact")}
                    >
                      {t("homeFaqbutton.outlineButton")}
                    </Button>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------S3 - MARA ------------------------*/}
        
      </div>
    </Layout>
  );
};

export default About;
