import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import holiday from "../../assets/images/visa/holiday.webp";
import holiday2 from "../../assets/images/visa/holiday2.webp";
import whvfoot from "../../assets/images/visa/whvfoot.webp";
import flags1 from "../../assets/images/visa/flags1.webp";
import flags2 from "../../assets/images/visa/flags2.webp";
import flags3 from "../../assets/images/visa/flags3.webp";
import flags1mob from "../../assets/images/visa/flags1.svg";
import flags2mob from "../../assets/images/visa/flags2.svg";
import flags3mob from "../../assets/images/visa/flags3.svg";
import "./visa.css";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const HolidayVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='visa-page worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <a
                className="breadcrum"
                href="/#visa-type"
              >
                {t('visas.businessHeading1')}
              </a>
              <div className='worker-visa-banner-heading-title'>
                <h2>{t("visaHoliday.holidayHeading1")}</h2>
              </div>
            </div>

            <div className='worker-grid'>
              <div className='worker-content'>
                <p>{t("visaHoliday.holidayParagraph1")}</p>
                <p>{parse(t("visaHoliday.holidayParagraph2"))}</p>
                <p>{parse(t("visaHoliday.holidayParagraph3"))}</p>
              </div>
              <div className='worker-cover rightimg'>
                <img src={holiday} alt='' />
              </div>
            </div>

            <div className='worker-visa-Types'>
              <h2>{t("visaHolidaycontent.holidayHeading1")}</h2>
              <h4>{t("visaHolidaycontent.holidayHeading2")}</h4>
              <p>{parse(t("visaHolidaycontent.holidayParagraph1"))}</p>

              <h5>{t("visaHolidaycontent.holidayHeading3")}</h5>
              <ul>
                <li>{t("visaHolidaycontent.holidayList1")}</li>
                <li>{t("visaHolidaycontent.holidayList2")}</li>
                {/* <li>{t("visaHolidaycontent.holidayList3")}</li> */}
                <li>{t("visaHolidaycontent.holidayList4")}</li>
                <li>{t("visaHolidaycontent.holidayList5")}</li>
                <li>{t("visaHolidaycontent.holidayList6")}</li>
                <li>{t("visaHolidaycontent.holidayList7")}</li>
              </ul>


              <h5>{t("visaHolidaycontent.holidayHeading4")}</h5>
              <div className=" Subclass417 d-lg-flex d-md-block">

                <div className="till30">
                  <p>{parse(t("visaHolidaycontent.holidayParagraph2"))}</p>
                  <img className="d-none d-lg-block" src={flags1} alt='' />
                  <img className="d-lg-none" src={flags1mob} alt='' />
                </div>
                <div className="till35">
                  <p>{parse(t("visaHolidaycontent.holidayParagraph3"))}</p>
                  <img className="d-none d-lg-block" src={flags2} alt='' />
                  <img className="d-lg-none" src={flags2mob} alt='' />
                </div>
              </div>


              <h4>{t("visaHolidaycontent.holidayHeading5")}</h4>
              <p>{parse(t("visaHolidaycontent.holidayParagraph4"))}</p>
              <h5>{t("visaHolidaycontent.holidayHeading6")}</h5>
              <ul>
                <li>{t("visaHolidaycontent.holidayList8")}</li>
                <li>{t("visaHolidaycontent.holidayList9")}</li>
                {/* <li>{t("visaHolidaycontent.holidayList10")}</li> */}
                <li>{t("visaHolidaycontent.holidayList11")}</li>
                <li>{t("visaHolidaycontent.holidayList12")}</li>
                <li>{t("visaHolidaycontent.holidayList13")}</li>
                <li>{t("visaHolidaycontent.holidayList14")}</li>
                <li>{t("visaHolidaycontent.holidayList15")}</li>
                <li>{t("visaHolidaycontent.holidayList16")}</li>
              </ul>
              <h5>{t("visaHolidaycontent.holidayHeading4")}</h5>
              <p>{parse(t("visaHolidaycontent.holidayParagraph2"))}</p>
              <div className="Subclass462">
                <img className="d-none d-lg-block" src={flags3} alt='' />
                <img className="d-lg-none" src={flags3mob} alt='' />
                </div>
            </div>

            <div className='worker-grid'>
              <div className='worker-cover'>
                <img src={holiday2} alt='' />
              </div>
              <div className='worker-content righttext'>
                <p className="d-lg-none"></p>
                <h3>{t("visaHolidaycontent.holidayHeading7")}</h3>
                <p>{t("visaHolidaycontent.holidayParagraph5")}</p>
                <p>{t("visaHolidaycontent.holidayParagraph6")}</p>
                <p>{t("visaHolidaycontent.holidayParagraph7")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="visa-contact text-center">
          <h4>{t("visaContactFoot.Heading1")}</h4>
          <p>{t("visaContactFoot.Paragraph1")}</p>
          <p className="text-center">
            <Link to="/contact">
              <Button
                className="blue-button"
                onClick={() => (window.location.href = '/contact')}
              >
                {t('visaContactFoot.ContactBtn')}
              </Button>
            </Link>
          </p>
        </div>
        <div className="visa-foot"><img src={whvfoot} alt='' /></div>

      </div>
    </Layout>
  );
};

export default HolidayVisa;
