import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import student1 from "../../assets/images/visa/student1.webp";
import student2 from "../../assets/images/visa/student2.webp";
import student3 from "../../assets/images/visa/student3.webp";
import student4 from "../../assets/images/visa/student4.webp";
import student5 from "../../assets/images/visa/student5.webp";
import studentfoot from "../../assets/images/visa/studentfoot.webp";
import mara from "../../assets/images/about/mara.webp";
import "./visa.css";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const StudentVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='visa-page worker-visa-page student-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <a
                className="breadcrum"
                href="/#visa-type"
              >
                {t('visas.businessHeading1')}
              </a>
              <div className='worker-visa-banner-heading-title'>
                <h2>{t("visaStudent.studentHeading1")}</h2>
              </div>
            </div>

            <div className='worker-grid first'>
              <div className='worker-content'>
                <p>{parse(t("visaStudent.studentParagraph1"))}</p>
                <p>{t("visaStudent.studentParagraph3")}</p>
                <p>{t("visaStudent.studentParagraph4")}</p>
                <p>{parse(t("visaStudent.studentParagraph5"))}</p>
              </div>
              <div className='worker-cover rightimg'>
                <img src={student1} alt='' />
              </div>
            </div>

            <div className='worker-visa-Types second'>
              <h2>{t("visaStudentcontent.Heading1")}</h2>
              <p>{t("visaStudentcontent.Paragraph1")}</p>
            </div>

            <div className='worker-grid star p-0'>
              <div className='worker-cover righttext p-0'>
                <img src={student2} alt='' />
              </div>
              <div className='worker-content righttext'>
                <h4>&#9733;  {t("visaStudentcontent.Heading2")}</h4>
                <p>{parse(t("visaStudentcontent.Paragraph2"))}</p>
                <h4>&#9733;  {t("visaStudentcontent.Heading3")}</h4>
                <p>{parse(t("visaStudentcontent.Paragraph3"))}</p>
              </div>
            </div>


            <div className='worker-grid star'>
              <div className='worker-cover rightimg d-lg-none'>
                <img src={student3} alt='' />
              </div>
              <div className='worker-content '>
                {/* <p className="d-lg-none"></p> */}
                <h4>&#9733;  {t("visaStudentcontent.Heading4")}</h4>
                <p>{parse(t("visaStudentcontent.Paragraph4"))}</p>
                <h4>&#9733;  {t("visaStudentcontent.Heading5")}</h4>
                <p>{t("visaStudentcontent.Paragraph5")}</p>
              </div>
              <div className='worker-cover rightimg d-none d-lg-block'>
                <img src={student3} alt='' />
              </div>
            </div>


            <div className='worker-grid star'>
              <div className='worker-cover righttext'>
                <img src={student4} alt='' />
              </div>
              <div className='worker-content righttext'>
                {/* <p className="d-lg-none"></p> */}
                <h4>&#9733;  {t("visaStudentcontent.Heading6")}</h4>
                <p>{parse(t("visaStudentcontent.Paragraph6"))}</p>

              </div>
            </div>

          </div>
        </div>
        {/*------------------------ Universities ------------------------*/}
        <div className='worker-visa-banner universities'>
          <div className='container'>
            <div className='worker-visa-Types ' >
              <h2>{t("visaStudentUniversities.Heading1")}</h2>
              <p className="text-center">{parse(t("visaStudentUniversities.Paragraph1"))}</p>
              
              <table className="d-none d-lg-table">
                <tr>
                  <td className="firsttd">#1</td>
                  <td><a href="https://www.unimelb.edu.au/" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph2")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph3")}</td>
                  <td>{t("visaStudentUniversities.Paragraph4")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#2</td>
                  <td><a href="https://www.sydney.edu.au/" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph5")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph6")}</td>
                  <td>{t("visaStudentUniversities.Paragraph7")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#3</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/australian-national-university" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph8")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph9")}</td>
                  <td>{t("visaStudentUniversities.Paragraph10")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#4</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/university-queensland" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph11")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph12")}</td>
                  <td>{t("visaStudentUniversities.Paragraph13")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#5</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/monash-university" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph14")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph15")}</td>
                  <td>{t("visaStudentUniversities.Paragraph16")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#6</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/unsw-sydney" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph17")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph18")}</td>
                  <td>{t("visaStudentUniversities.Paragraph19")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#7</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/university-adelaide" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph20")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph21")}</td>
                  <td>{t("visaStudentUniversities.Paragraph22")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#8</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/university-western-australia" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph23")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph24")}</td>
                  <td>{t("visaStudentUniversities.Paragraph25")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#9</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/university-technology-sydney" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph26")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph27")}</td>
                  <td>{t("visaStudentUniversities.Paragraph28")}</td>
                </tr>
                <tr>
                  <td className="firsttd">#10</td>
                  <td><a href="https://www.timeshighereducation.com/world-university-rankings/university-canberra" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph29")}</a></td>
                  <td>{t("visaStudentUniversities.Paragraph30")}</td>
                  <td>{t("visaStudentUniversities.Paragraph31")}</td>
                </tr>
              </table>            
              <div className="universities-list d-block d-lg-none">
                <div className="universities-list-line d-block">
                  <p className="firstp">#1</p>
                  <p><a href="https://www.unimelb.edu.au/" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph2")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph3")}</p>
                  <p>{t("visaStudentUniversities.Paragraph4")}</p>
                </div>
                <div className="universities-list-line  d-block">
                  <p className="firstp">#2</p>
                  <p><a href="https://www.sydney.edu.au/" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph5")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph6")}</p>
                  <p>{t("visaStudentUniversities.Paragraph7")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#3</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/australian-national-university" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph8")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph9")}</p>
                  <p>{t("visaStudentUniversities.Paragraph10")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#4</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/university-queensland" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph11")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph12")}</p>
                  <p>{t("visaStudentUniversities.Paragraph13")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#5</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/monash-university" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph14")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph15")}</p>
                  <p>{t("visaStudentUniversities.Paragraph16")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#6</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/unsw-sydney" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph17")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph18")}</p>
                  <p>{t("visaStudentUniversities.Paragraph19")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#7</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/university-adelaide" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph20")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph21")}</p>
                  <p>{t("visaStudentUniversities.Paragraph22")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#8</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/university-western-australia" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph23")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph24")}</p>
                  <p>{t("visaStudentUniversities.Paragraph25")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#9</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/university-technology-sydney" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph26")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph27")}</p>
                  <p>{t("visaStudentUniversities.Paragraph28")}</p>
                </div>
                <div className="universities-list-line d-block">
                  <p className="firstp">#10</p>
                  <p><a href="https://www.timeshighereducation.com/world-university-rankings/university-canberra" className="link" target="_blank" rel="noreferrer">{t("visaStudentUniversities.Paragraph29")}</a></p>
                  <p>{t("visaStudentUniversities.Paragraph30")}</p>
                  <p>{t("visaStudentUniversities.Paragraph31")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------------------------  VIsa Types  ------------------------*/}
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-Types'>
              <h2>{t("visaStudentcontent.Heading7")}</h2>
              <h4>{t("visaStudentcontent.Heading8")}</h4>
              <p>{parse(t("visaStudentcontent.Paragraph7"))}</p>
              <ul>
                <li>{t("visaStudentcontent.List1")}</li>
                <li>{t("visaStudentcontent.List2")}</li>
                <li>{t("visaStudentcontent.List3")}</li>
                <li>{t("visaStudentcontent.List4")}</li>
                <li>{t("visaStudentcontent.List5")}</li>
                <li>{t("visaStudentcontent.List6")}</li>
                <li>{t("visaStudentcontent.List7")}</li>
              </ul>
              <p>{t("visaStudentcontent.Paragraph8")}</p>



              <h4>{t("visaStudentcontent.Heading9")}</h4>
              <p>{parse(t("visaStudentcontent.Paragraph9"))}</p>
              <h5>{t("visaStudentcontent.Heading10")}</h5>
              <p>{parse(t("visaStudentcontent.Paragraph10"))}</p>
              <ul>
                <li>{t("visaStudentcontent.List8")}</li>
                <li>{t("visaStudentcontent.List9")}</li>
                <li>{t("visaStudentcontent.List10")}</li>
                <li>{t("visaStudentcontent.List11")}</li>
                <li>{t("visaStudentcontent.List12")}</li>
                <li>{t("visaStudentcontent.List13")}</li>
                <li>{t("visaStudentcontent.List14")}</li>
              </ul>
              <p>{t("visaStudentcontent.Paragraph11")}</p>
              <h5>{t("visaStudentcontent.Heading11")}</h5>
              <p>{parse(t("visaStudentcontent.Paragraph12"))}</p>
              <ul>
                <li>{t("visaStudentcontent.List8")}</li>
                <li>{t("visaStudentcontent.List15")}</li>
                <li>{t("visaStudentcontent.List16")}</li>
                <li>{t("visaStudentcontent.List17")}</li>
                <li>{t("visaStudentcontent.List18")}</li>
                <li>{t("visaStudentcontent.List19")}</li>
              </ul>
              <p>{t("visaStudentcontent.Paragraph13")}</p>
              <ul>
                <li>{t("visaStudentcontent.List20")}</li>
                <li>{t("visaStudentcontent.List21")}</li>
              </ul>
              <p>{t("visaStudentcontent.Paragraph14")}</p>


              <h4>{t("visaStudentcontent.Heading12")}</h4>
              <p>{parse(t("visaStudentcontent.Paragraph15"))}</p>
              <h5>{t("visaStudentcontent.Heading13")}</h5>
              <p>{parse(t("visaStudentcontent.Paragraph16"))}</p>
              <ul>
                <li>{t("visaStudentcontent.List22")}</li>
                <li>{t("visaStudentcontent.List23")}</li>
                <li>{t("visaStudentcontent.List24")}</li>
              </ul>
              <p>{t("visaStudentcontent.Paragraph17")}</p>
            </div>


            <h2>{parse(t("visaStudentcontent.Heading14"))}</h2>

            <div className='worker-grid'>
              <div className='worker-content'>
                <p>{t("visaStudentcontent.Paragraph18")}</p>
                <div className="process">
                  <ul>
                    <li>{t("visaStudentcontent.List25")}</li>
                    <li>{t("visaStudentcontent.List26")}</li>
                    <li>{t("visaStudentcontent.List27")}</li>
                    <li>{t("visaStudentcontent.List28")}</li>
                    <li>{t("visaStudentcontent.List29")}</li>
                  </ul>
                </div>
              </div>
              <div className='worker-cover rightimg'>
                <img src={student5} alt='' />
              </div>
            </div>


            <div className='worker-visa-Types'>
              <p>{parse(t("visaStudentcontent.Paragraph19"))}</p>
              <div className="prodlist">
                <ol>
                  <li>{t("visaStudentcontent.List25")}</li>
                  <li>{t("visaStudentcontent.List26")}</li>
                  <li>{t("visaStudentcontent.List27")}</li>
                  <li>{t("visaStudentcontent.List28")}</li>
                  <li>{t("visaStudentcontent.List29")}</li>
                </ol>
              </div>
              <p>{parse(t("visaStudentcontent.Paragraph20"))}</p>
            </div>


            {/* -------------------------------- MARA ------------------------*/}
            <div className='about-mara'>
              <div className='container'>
                <div className='about-mara-grid'>
                  <div className='about-mara-content'>

                    <h2>{t("visaStudentcontent.Heading15")}</h2>
                    <div className="about-mara-headng">
                      <div className="mara-content d-bloc">
                        <p>{t("visaStudentcontent.Paragraph21")}</p>
                        <p>{t("visaStudentcontent.Paragraph22")}</p>
                      </div>
                      <div className='about-mara-cover'>
                        <img src={mara} alt='' />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="visa-contact text-center">
          <h4>{t("visaContactFoot.Heading1")}</h4>
          <p>{t("visaContactFoot.Paragraph1")}</p>
          <p className="text-center">
            <Link to="/contact">
              <Button
                className="blue-button"
                onClick={() => (window.location.href = '/contact')}
              >
                {t('visaContactFoot.ContactBtn')}
              </Button>
            </Link>
          </p>
        </div>
        <div className="visa-foot"><img src={studentfoot} alt='' /></div>

      </div>
    </Layout>
  );
};

export default StudentVisa;
