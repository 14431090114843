import axios from "axios";
import { useContext } from "react";

// import uploadimg from "../../assets/images/visa/upload.svg";
import { serverPath } from "../../config/key";
import UserContext from "../../context/UserContext";
// import attached from '../../assets/images/application/attached.svg'
// import trash from '../../assets/images/application/trash.png'
import { toast } from "react-toastify";
import check from "../../assets/images/application/check-circle.svg";
import deleteimg from "../../assets/images/application/delete.svg";
import UploadIcon from "../../assets/icons/UploadIcon";
// import upload from '../../assets/images/application/hover-upload.svg'

const GeneralInfo = ({ documentInfo, setDocumentInfo, documentDis }) => {
  const { selectedVisa } = useContext(UserContext);
  const handleFile = async (e) => {
    try {
      const {
        target: { files, name },
      } = e;
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("type", name);

      const res = await axios.post(
        `${serverPath}/visa/visaDocuments`,
        formData,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            visaTypeId: selectedVisa?.visaId,
            invoiceId: selectedVisa?.invoiceId,
          },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo({ ...documentInfo, ...data });
      }
    } catch (err) {
      toast.error(err.response.data.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeDocument`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo(data);
      }
    } catch (err) {
      toast.error(err.message, { toastId: "erro" });
    }
  };
  return (
    <div className="general-info">
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Passport Copy</h6>
        </div>
        <div className="middle-info">
          <h6>(front page + stamp / visa pages if any) </h6>
        </div>

        {documentInfo?.frontPassportCopy ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("frontPassportCopy")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="upload1"
                type="file"
                name="frontPassportCopy"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload1">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Updated CV</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.updatedCv ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("updatedCv")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="upload2"
                type="file"
                name="updatedCv"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload2">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Digital Photo</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.digitalPhoto ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("digitalPhoto")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="upload3"
                type="file"
                name="digitalPhoto"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload3">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Copy of Birth Certificate </h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.birthCertificate ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("birthCertificate")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="upload4"
                type="file"
                name="birthCertificate"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload4">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Marriage Certificate </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.marriageCertificate ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("marriageCertificate")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="upload5"
                type="file"
                name="marriageCertificate"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload5">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Divorce Certificate </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.divorceCertificate ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("divorceCertificate")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="upload6"
                type="file"
                name="divorceCertificate"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload6">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Death Certificate </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.deathCertificate ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("deathCertificate")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="upload7"
                type="file"
                name="deathCertificate"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload7">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Common-Law Declaration</h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.commonLawDeclaration ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={check} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("commonLawDeclaration")}>
                <img
                  src={deleteimg}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="upload8"
                type="file"
                name="commonLawDeclaration"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="upload8">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default GeneralInfo;
