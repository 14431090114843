import { Button, Form, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContactSchema } from '../../utils/validationSchema'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useContext, useRef, useState } from 'react'
import UserContext from '../../context/UserContext'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import checkicon from '../../assets/images/header/success.svg'
import { phone } from 'phone'
const ContactForm = () => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
    watch,
    reset,
    control,
  } = useForm({ resolver: yupResolver(ContactSchema) })
  const [succ, setSucc] = useState(false)
  const { userGeoData } = useContext(UserContext)
  const [phoneCountryCode, setPhoneCountryCode] = useState('')
  const [loader, setLoader] = useState(false)
  const termsCheck = watch('terms')
  const phoneInputRef = useRef(null);
  const handleChange = (e, country) => {
    setValue('phoneNumber', '+' + e)
    setPhoneCountryCode(country?.countryCode)
    setError('phoneNumber', { message: '' })
  }
  const handleSubmitForm = async (values) => {
    try {
      const validate = phone(values.phoneNumber, {
        country: phoneCountryCode || userGeoData?.country_code,
      })
      if (!validate?.isValid) {
        setError('phoneNumber', { message: 'Your phone number is Invalid' })
        return
      }
      const [first, last] = values?.name?.split(' ')
      values.firstName = first ? first : 'NA'
      values.lastName = last ? last : 'NA'
      setLoader(true)
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      })
      setLoader(false)
      const {
        data: { msg, success },
        status,
      } = response
      if (success || status === 200 || status === 201) {
        reset({
          name: '',
          email: '',
          phoneNumber: '',
        })
        setSucc(true)
        toast.success(msg|| 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className="immigration-form home-eligibility">
      <div className="container">
        <div className="eligibility-grid">
          <div className="eligibility-content">
            <h4>{t('Immigrationform.formheading')}</h4>
            <p>{t('Immigrationform.paragraph')}</p>

            <Form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className=" d-lg-flex d-sm-block elForm">
                <Form.Group>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder={t('visaContact.fullname')}
                    {...register('name')}
                  />
                  {errors?.name ? (
                    <span className="error-msg">{errors.name.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder={t('visaContact.emailaddress')}
                    {...register('email')}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="phoneNumber" // Replace with the actual name of your input field
                    control={control}
                    defaultValue="" // Set a default empty value
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        country={
                          userGeoData?.country_code
                            ? userGeoData?.country_code?.toLowerCase()
                            : 'de'
                        }
                        ref={phoneInputRef}
                        onChange={(e, country) => handleChange(e, country)}
                        inputProps={{
                          name: 'phoneNumber', // Replace with the actual name of your input field
                        }}
                      />
                    )}
                  />

                  {errors?.phoneNumber ? (
                    <span className="error-msg">
                      {errors.phoneNumber.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group class="checkbox d-lg-none">
                  <Form.Check.Input type="checkbox" defaultChecked />
                  <Form.Check.Label>
                    {parse(t('homeEligibility.checkbox'))}
                  </Form.Check.Label>
                </Form.Group>

                <Button
                  type="submit"
                  className={`blue-button ${succ ? `success-btn` : ``}`}
                  disabled={
                    (termsCheck === undefined && !loader) ||
                    (termsCheck && !loader)
                      ? false
                      : loader
                      ? loader
                      : true
                  }
                >
                  {loader && (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  )}
                  &nbsp;
                  {succ
                    ? t('Immigrationform.successButton')
                    : t('Immigrationform.blueButton')}
                  {succ ? <img src={checkicon} alt="" /> : ''}
                </Button>
              </div>
              <Form.Group class="checkbox d-none d-lg-flex">
                <Form.Check.Input
                  type="checkbox"
                  name="terms"
                  defaultChecked={true}
                  {...register('terms')}
                />
                <Form.Check.Label>
                  {parse(t('homeEligibility.checkbox'))}
                </Form.Check.Label>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactForm
