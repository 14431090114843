import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import { serverPath } from '../../../config/key'
import ApplicationLayout from '../layout'
const Products = () => {
  const [products, setProducts] = useState()
  const getProducts = async () => {
    const response = await axios.get(`${serverPath}/product/getAllProduct`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const { products } = response.data || []
    if (products?.length > 0) {
      setProducts(products)
    }
  }
  useEffect(() => {
    getProducts()
  }, [])
  // const checkOutProduct = async (proId) => {
  //   try {
  //     // const payload = {
  //     //   userId: user?._id,
  //     //   productId: proId,
  //     //   continent: userGeoData?.continent_code,
  //     // }
  //     // const res = await axios.post(
  //     //   `${serverPath}/payment-provider/checkOutProduct`,
  //     //   payload,
  //     // )
  //     // if (res.data.url) {
  //     //   window.location.href = res.data.url
  //     // }
  //   } catch (error) {
  //     toast.error(error.message, { toastId: 'errr' })
  //   }
  // }
  return (
    <ApplicationLayout>
      <div className={`invoices-page`}>
        <div
          className="container"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h3>Products</h3>
          {products &&
            products?.length > 0 &&
            products.map((pro) => (
              <Card style={{ width: '15rem' }}>
                <Card.Img variant="top" src={`${pro?.productId?.image}`} />
                <Card.Body>
                  <Card.Title>{pro?.productId?.name}</Card.Title>
                  <Card.Text>
                    {pro?.productId?.description}
                    <p>{pro.productId?.price}</p>
                  </Card.Text>
                  {/* <Button
                    variant="primary"
                    onClick={() => checkOutProduct(pro._id)}
                  >
                    Buy
                  </Button> */}
                </Card.Body>
              </Card>
            ))}
        </div>
      </div>
    </ApplicationLayout>
  )
}
export default Products
