import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { invoiceSchema } from '../../utils/validationSchema'
const ManualInvoice = () => {
  const initialValue = {
    companyId: '',
    productId: '',
    clientEmail: '',
    clientName: '',
    clientAddress: '',
    clientCity: '',
    clientCountry: '',
    clientPhone: '',
    paymentMethod: '',
    invoiceNumber: '',
    userId: '',
    brandId: '',
  }
  const navigate = useNavigate()
  const [allCompany, setAllCompany] = useState([])
  const [allProduct, setAllProduct] = useState([])
  const [allBrand, setAllBrand] = useState([])
  const [values, setValues] = useState(initialValue)
  const [errors, setErrors] = useState('')
  const getAllCompany = async () => {
    try {
      const response = await axios.get(`${serverPath}/company/getAllCompany`)
      const { company } = response?.data || []
      if (company.length > 0) {
        setAllCompany(company)
      }
    } catch (err) {
      console.log('error is here-->', err)
    }
  }

  const getAllBrand = async () => {
    try {
      const response = await axios.get(`${serverPath}/brands`)
      const { brands } = response?.data || []
      if (brands.length > 0) {
        setAllBrand(brands)
      }
    } catch (err) {
      console.log('error is here-->', err)
    }
  }
  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${serverPath}/product/getProducts`)
      const { products } = response?.data || []
      if (products?.length > 0) {
        setAllProduct(products)
      }
    } catch (err) {
      console.log('error is here-->', err)
    }
  }
  useEffect(() => {
    getAllCompany()
    getAllBrand()
    getAllProduct()
  }, [])
  const findUserDetail = async (e) => {
    const { value } = e.target
    setValues({ ...values, clientEmail: value })
    const validEmail = /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/
    if (validEmail.test(value)) {
      const res = await axios.get(
        `${serverPath}/users/findUserByEmail/${value}`,
      )
      const { userDetail } = res?.data || {}
      if (Object.keys(userDetail).length !== 0) {
        setValues({
          ...values,
          clientEmail: userDetail?.email,
          clientName: userDetail?.name,
          clientAddress: userDetail?.address,
          clientCity: userDetail?.city,
          clientCountry: userDetail?.country,
          clientPhone: userDetail?.mobile,
          userId: userDetail._id,
        })
      }
      //
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const getInvoiceNumber = async (e) => {
    if (e.target.value.length <= 6) {
      setErrors({
        ...errors,
        invoiceNumber: 'Invoice Number must be at least 7 character long',
      })
      return
    }
    try {
      const res = await axios.post(
        `${serverPath}/invoice/getInvoiceById`,
        {
          invoiceNumber: e.target.value,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem}` },
        },
      )
      const {
        clientAddress,
        clientCity,
        clientCountry,
        clientEmail,
        clientName,
        clientPhone,
        invoiceNumber,
        userId: { _id: userId },
        companyId: { _id: companyId },
        productId: { _id: productId },
        brandId,
      } = res.data.invoice
      setValues({
        ...values,
        invoiceNumber,
        clientAddress,
        clientCity,
        clientCountry,
        clientEmail,
        clientName,
        brandId,
        userId,
        clientPhone,
        companyId,
        productId,
      })
      setErrors({ ...errors, invoiceNumber: '' })
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrors({
          ...errors,
          invoiceNumber: 'Not a valid Invoice Number',
        })
      }
    }
  }
  const handleGenerateInvoice = async (e) => {
    e.preventDefault()
    try {
      const validate = await invoiceSchema.validate(values, {
        abortEarly: false,
      })
      if (validate) {
        const response = await axios.post(
          `${serverPath}/invoice/createInvoice`,
          values,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        const { status } = response
        if (status === 200) {
          setValues(initialValue)
          navigate('/allinvoices')
          toast.success('Invoice generated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
    } catch (err) {
      console.log('Error is here-->', err)
      let er = {}
      err.inner.forEach((el) => {
        er[el.path] = el.message
      })
      setErrors({ ...er })
    }
  }
  return (
    <Layout>
      <div className={`manual-invoices-page`}>
        <div className="container">
          <h3>Manual Invoice</h3>
          <div className={`manual-invoices-content`}>
            <Form>
              <div className="invoice-form-grid">
                <Form.Group className="form-group">
                  <Form.Label>Choose Invoice Type</Form.Label>
                  <Form.Select
                    aria-label="Choose Invoice Type"
                    name="paymentMethod"
                    value={values?.paymentMethod}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="Wire Transfer">Wire Transfer</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Refund">Refund</option>
                  </Form.Select>
                  {errors?.paymentMethod && (
                    <span className="error-msg">{errors.paymentMethod}</span>
                  )}
                </Form.Group>
                {values.paymentMethod === 'Refund' ? (
                  <Form.Group className="form-group">
                    <Form.Label>Ref Invoice #</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Invoice #"
                      name="invoiceNumber"
                      onBlur={getInvoiceNumber}
                    />
                    {errors?.invoiceNumber && (
                      <span className="error-msg">{errors.invoiceNumber}</span>
                    )}
                  </Form.Group>
                ) : (
                  ''
                )}
                <Form.Group className="form-group">
                  <Form.Label>Choose Company</Form.Label>
                  <Form.Select
                    aria-label="Choose Company"
                    name="companyId"
                    value={values?.companyId}
                    onChange={handleChange}
                  >
                    {values?.companyId === '' && (
                      <option disabled value="">
                        Choose Company
                      </option>
                    )}
                    {allCompany &&
                      allCompany?.length > 0 &&
                      allCompany.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.companyId && (
                    <span className="error-msg">{errors.companyId}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Choose Brand</Form.Label>
                  <Form.Select
                    aria-label="Choose Brand"
                    name="brandId"
                    value={values?.brandId}
                    onChange={handleChange}
                  >
                    {values?.brandId === '' && (
                      <option disabled value="">
                        Select
                      </option>
                    )}
                    {allBrand &&
                      allBrand?.length > 0 &&
                      allBrand.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.brandId && (
                    <span className="error-msg">{errors.brandId}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Choose Product</Form.Label>
                  <Form.Select
                    aria-label="Choose Product"
                    name="productId"
                    value={values?.productId}
                    onChange={handleChange}
                  >
                    {values?.productId === '' && (
                      <option disabled value="">
                        Choose Product
                      </option>
                    )}
                    {allProduct &&
                      allProduct?.length > 0 &&
                      allProduct?.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.productId && (
                    <span className="error-msg">{errors.productId}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="clientEmail"
                    value={values?.clientEmail}
                    onChange={findUserDetail}
                  />
                  {errors?.clientEmail && (
                    <span className="error-msg">{errors.clientEmail}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="clientName"
                    value={values.clientName}
                    onChange={handleChange}
                  />
                  {errors?.clientName && (
                    <span className="error-msg">{errors.clientName}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    name="clientAddress"
                    value={values.clientAddress}
                    onChange={handleChange}
                  />
                  {errors?.clientAddress && (
                    <span className="error-msg">{errors.clientAddress}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="clientCity"
                    value={values.clientCity}
                    onChange={handleChange}
                  />
                  {errors?.clientCity && (
                    <span className="error-msg">{errors.clientCity}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    name="clientCountry"
                    value={values.clientCountry}
                    onChange={handleChange}
                  />
                  {errors?.clientCountry && (
                    <span className="error-msg">{errors.clientCountry}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone number"
                    name="clientPhone"
                    value={values.clientPhone}
                    onChange={handleChange}
                  />
                  {errors?.clientPhone && (
                    <span className="error-msg">{errors.clientPhone}</span>
                  )}
                </Form.Group>
                <div className="invoice-btn">
                  <Button type="submit" onClick={handleGenerateInvoice}>
                    Generate Invoice
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ManualInvoice
