import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import "./legal.css";


const PrivacyPolicy = () => {

    const { t } = useTranslation();
    return (

        <Layout>


            <div className="legal-page">


                <div className="container">

                    <a
                        className="breadcrum"
                        href="/"
                    >
                        {t('visas.businessHeading2')}
                    </a>
                    <div className="legal-content privacy">

                        <h2>{t("privacy.Heading")}</h2>

                        <div>{parse(t("privacy.content"))}</div>


                    </div>



                </div>


            </div>

        </Layout>

    );
}

export default PrivacyPolicy;