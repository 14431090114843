import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";

import timeicon from "../../assets/images/visa/Time.svg";
import financeicon from "../../assets/images/visa/Finance.svg";
import headphoneicon from "../../assets/images/visa/Headphone.svg";
import family from "../../assets/images/visa/family.webp";
import familyfoot from "../../assets/images/visa/familyfoot.webp";
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import parse from 'html-react-parser';
import "./visa.css";

const FamilyVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='visa-page worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <a
                className="breadcrum"
                href="/#visa-type"
              >
                {t('visas.businessHeading1')}
              </a>
              <div className='worker-visa-banner-heading-title'>
                <h2>{t("visaFamily.familyHeading1")}</h2>
              </div>
            </div>

            <p>{t("visaFamily.familyParagraph1")}</p>
            <p>{t("visaFamily.familyParagraph2")}</p>

            <div className='worker-grid'>
              <div className='worker-content'>
                <h3>{t("visaFamily.familyHeading2")}</h3>
                <p>{t("visaFamily.familyParagraph3")}</p>
                <p>{t("visaFamily.familyParagraph4")}</p>

              </div>
              <div className='worker-cover rightimg'>
                <img src={family} alt='' />
              </div>
            </div>

            <div className='worker-visa-Types'>
              <h2>{parse(t("visaFamilycontent.familyHeading1"))}</h2>
              <h4>{t("visaFamilycontent.familyHeading2")}</h4>
              <p>{t("visaFamilycontent.familyParagraph1")}</p>
             
              <h5>{t("visaFamilycontent.familyHeading3")}</h5>
              <p>{t("visaFamilycontent.familyParagraph2")}</p>
              <p>{t("visaFamilycontent.familyParagraph3")}</p>


              <h5>{t("visaFamilycontent.familyHeading4")}</h5>
              <p>{t("visaFamilycontent.familyParagraph4")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist1")}</li>
                <li>{t("visaFamilycontent.familylist2")}</li>
                <li>{t("visaFamilycontent.familylist3")}</li>
                <li>{t("visaFamilycontent.familylist4")}</li>
              </ul>


              <h5>{t("visaFamilycontent.familyHeading5")}</h5>
              <p>{t("visaFamilycontent.familyParagraph5")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist5")}</li>
                <li>{t("visaFamilycontent.familylist6")}</li>
                <li>{t("visaFamilycontent.familylist7")}</li>
                <li>{t("visaFamilycontent.familylist1")}</li>
              </ul>
              <p>{t("visaFamilycontent.familyParagraph6")}</p>



              <h4>{t("visaFamilycontent.familyHeading6")}</h4>
              <h5>{t("visaFamilycontent.familyHeading7")}</h5>
              <p>{t("visaFamilycontent.familyParagraph7")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist8")}</li>
                <li>{t("visaFamilycontent.familylist9")}</li>
                <li>{t("visaFamilycontent.familylist10")}</li>
              </ul>
              <p>{t("visaFamilycontent.familyParagraph8")}</p>



              <h4>{t("visaFamilycontent.familyHeading9")}</h4>
              <p>{t("visaFamilycontent.familyParagraph9")}</p>
              <h5>{t("visaFamilycontent.familyHeading10")}</h5>
              <p>{t("visaFamilycontent.familyParagraph10")}</p>
              <p>{t("visaFamilycontent.familyParagraph11")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist11")}</li>
                <li>{t("visaFamilycontent.familylist12")}</li>
                <li>{t("visaFamilycontent.familylist13")}</li>
              </ul>
              <p className="text-uppercase">{t("visaFamilycontent.familyParagraph12")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist14")}</li>
                <li>{t("visaFamilycontent.familylist15")}</li>
                <li>{t("visaFamilycontent.familylist16")}</li>
              </ul>
              <p className="text-uppercase">{t("visaFamilycontent.familyParagraph13")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist17")}</li>
                <li>{t("visaFamilycontent.familylist18")}</li>
                <li>{t("visaFamilycontent.familylist19")}</li>
                <li>{t("visaFamilycontent.familylist20")}</li>
                <li>{t("visaFamilycontent.familylist16")}</li>
              </ul>


              <h5>{t("visaFamilycontent.familyHeading11")}</h5>
              <p>{t("visaFamilycontent.familyParagraph14")}</p>
              <p>{t("visaFamilycontent.familyParagraph15")}</p>
              <ul>
                <li>{t("visaFamilycontent.familylist21")}</li>
                <li>{t("visaFamilycontent.familylist22")}</li>
              </ul>

            </div>
            <div className="family-visa-benefits text-center">
              <h3>{t("visaFamilycontent.familyHeading12")}</h3>
              <p>{t("visaFamilycontent.familyParagraph16")}</p>
              <div className="family-visa-benefits-flex">
                <div className="visa-benefit"><img src={timeicon} alt='' />
                  <p>{t("visaFamilycontent.familyParagraph17")}</p></div>
                <div className="visa-benefit"><img src={financeicon} alt='' />
                  <p>{t("visaFamilycontent.familyParagraph18")}</p></div>
                <div className="visa-benefit"><img src={headphoneicon} alt='' />
                  <p>{t("visaFamilycontent.familyParagraph19")}</p></div>
              </div>
            </div>

          </div>
        </div>

        <div className="visa-contact text-center">
          <h4>{t("visaContactFoot.Heading1")}</h4>
          <p>{t("visaContactFoot.Paragraph1")}</p>
          <p className="text-center">
            <Link to="/contact">
              <Button
                className="blue-button"
                onClick={() => (window.location.href = '/contact')}
              >
                {t('visaContactFoot.ContactBtn')}
              </Button>
            </Link>
          </p>
        </div>
        <div className="visa-foot"><img src={familyfoot} alt='' /></div>

      </div>
    </Layout>

  );
};

export default FamilyVisa;
