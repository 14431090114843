import axios from "axios";
import { useContext } from "react";
import { toast } from "react-toastify";
import { serverPath } from "../../config/key";
import UserContext from "../../context/UserContext";
import attached from "../../assets/images/application/check-circle.svg";
import trash from "../../assets/images/application/delete.svg";
import UploadIcon from "../../assets/icons/UploadIcon";
const SponsorshipDocument = ({
  documentInfo,
  setDocumentInfo,
  documentDis,
}) => {
  const { selectedVisa } = useContext(UserContext);
  const handleFile = async (e) => {
    const {
      target: { files, name },
    } = e;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", name);

    const res = await axios.post(`${serverPath}/visa/visaDocuments`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
      },
    });
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc;
      setDocumentInfo({ ...documentInfo, ...data });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeDocument`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo(data);
      }
    } catch (err) {
      toast.error(err.message, { toastId: "erro" });
    }
  };
  return (
    <div className="general-info">
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Parent Passport </h6>
        </div>
        <div className="middle-info">
          <h6>(the sponsoring parent) </h6>
        </div>
        {documentInfo?.parentPassport ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("parentPassport")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            <div className="upload-image">
              <input
                id="sponsorshipDoc1"
                type="file"
                name="parentPassport"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsorshipDoc1">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Financial Support Letter </h6>
        </div>
        <div className="middle-info">
          <h6>(signed by the sponsor) </h6>
        </div>
        {documentInfo?.financialSupportLetter ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("financialSupportLetter")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            <div className="upload-image">
              <input
                id="sponsorshipDoc2"
                type="file"
                name="financialSupportLetter"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsorshipDoc2">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Employment Reference Letter</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.sponsorReferenceLetter ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("sponsorReferenceLetter")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            <div className="upload-image">
              <input
                id="sponsorshipDoc3"
                type="file"
                name="sponsorReferenceLetter"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsorshipDoc3">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SponsorshipDocument;
