/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Form, Accordion, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { toast } from 'react-toastify'

import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
const WorkExperience = ({ experienceInfo, setExperienceInfo }) => {
  let intialState = {
    officialJobTitle: '',
    jobDescription: '',
    yearsExperience: '',
    fullTimePosition: '',
    hoursPerWeek: '',
    jobStartDate: '',
    jobEndTime: '',
  }
  let intialOnshoreState = {
    InCanOfficialJobTitle: '',
    InCanJobDescription: '',
    InCanYearsExperience: '',
    InCanFullTimePosition: '',
    InCanHoursPerWeek: '',
    InCanJobStartDate: '',
    InCanJobEndTime: '',
  }
  const { handleSubmit, register } = useForm()
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [jobs, setJobs] = useState([{ ...intialState }])
  const [sponsoredJobOffshore, setSponsoredJobsOffshore] = useState([
    { ...intialState },
  ])
  const [sponsoredJobOnshore, setSponsoredJobsOnshore] = useState([
    { ...intialOnshoreState },
  ])
  const [sponsoringJobOffshore, setSponsoringJobsOffshore] = useState([
    { ...intialState },
  ])
  const [sponsoringJobOnshore, setSponsoringJobsOnshore] = useState([
    { ...intialOnshoreState },
  ])
  const [jobsOnshore, setJobsOnshore] = useState([{ ...intialOnshoreState }])
  const [spouseJobsOnshore, setSpouseJobsOnshore] = useState([
    { ...intialOnshoreState },
  ])
  const [spouseJobsOffshore, setSpouseJobsOffshore] = useState([
    { ...intialState },
  ])
  const [offshoreDis, setOffshoreDis] = useState(false)
  const [onshoreDis, setOnshoreDis] = useState(false)
  const [spouseOffshoreDis, setSpouseOffshoreDis] = useState(false)
  const [spouseOnshoreDis, setSpouseOnshoreDis] = useState(false)
  useEffect(() => {
    //set fetched value of main applicant offshore job
    if (experienceInfo && experienceInfo?.offshoreJob?.length !== 0) {
      setOffshoreDis(true)
      setJobs(experienceInfo?.offshoreJob)
    }
    //set fetched value of main applicant onshore job
    if (experienceInfo && experienceInfo?.onshoreJob?.length !== 0) {
      setJobsOnshore(experienceInfo?.onshoreJob)
      setOnshoreDis(true)
    }
    //set fetched spouse offshore job
    if (experienceInfo && experienceInfo?.spouseOffshoreJob?.length !== 0) {
      setSpouseJobsOffshore(experienceInfo?.spouseOffshoreJob)
      setSpouseOffshoreDis(true)
    }
    //set fetched spouse onshore job
    if (experienceInfo && experienceInfo?.spouseOnshoreJob?.length !== 0) {
      setSpouseJobsOnshore(experienceInfo?.spouseOnshoreJob)
      setSpouseOnshoreDis(true)
    }
    //set fetched sponsored offshore job
    if (experienceInfo && experienceInfo?.sponsoredOffshoreJob?.length !== 0) {
      setSponsoredJobsOffshore(experienceInfo?.sponsoredOffshoreJob)
      setOffshoreDis(true)
    }
    //set fetched sponsoredOnshore job
    if (experienceInfo && experienceInfo?.sponsoredOnshoreJob?.length !== 0) {
      setSponsoredJobsOnshore(experienceInfo?.sponsoredOnshoreJob)
      setOnshoreDis(true)
    }
    //set fetched sponsoring offshore job
    if (experienceInfo && experienceInfo?.sponsoringOffshoreJob?.length !== 0) {
      setSponsoringJobsOffshore(experienceInfo?.sponsoringOffshoreJob)
      setOffshoreDis(true)
    }
    //set fetched sponsoring onshore job
    if (experienceInfo && experienceInfo?.sponsoringOnshoreJob?.length !== 0) {
      setSponsoringJobsOnshore(experienceInfo?.sponsoringOnshoreJob)
      setOnshoreDis(true)
    }
  }, [experienceInfo])
  //add main applicant job offshore form
  const handleAddJob = () => {
    setJobs([...jobs, { ...intialState }])
  }
  //Handle sponsored job offshore form
  // const handleAddSponsoredJobOffshore = () => {
  //   setSponsoredJobsOffshore([...sponsoredJobOffshore, { ...intialState }])
  // }
  //remove sponsored job offshore form
  // const removeSponsoredJobOffshore = (index) => {
  //   const oldJob = [...sponsoredJobOnshore]
  //   oldJob.splice(index, 1)
  //   setSponsoredJobsOnshore(oldJob)
  // }
  //Handle sponsored job onshore form
  // const handleAddSponsoredJobOnshore = () => {
  //   setSponsoredJobsOnshore([...sponsoredJobOnshore, { ...intialState }])
  // }

  //remove sponosored job onshore from
  // const removeSponsoredJobOnshore = (index) => {
  //   const oldJob = [...sponsoredJobOnshore]
  //   oldJob.splice(index, 1)
  //   setSponsoredJobsOnshore(oldJob)
  // }
  //Handle sponsoring job offshore form
  // const handleAddSponsoringJobOffshore = () => {
  //   setSponsoringJobsOffshore([...sponsoringJobOffshore, { ...intialState }])
  // }
  //remove sponsored job offshore form
  // const removeSponsoringJobOffshore = (index) => {
  //   const oldJob = [...sponsoringJobOffshore]
  //   oldJob.splice(index, 1)
  //   setSponsoringJobsOffshore(oldJob)
  // }
  //Handle sponsored job onshore form
  // const handleAddSponsoringJobOnshore = () => {
  //   setSponsoringJobsOnshore([...sponsoringJobOnshore, { ...intialState }])
  // }

  //remove sponosored job onshore from
  // const removeSponsoringJobOnshore = (index) => {
  //   const oldJob = [...sponsoringJobOnshore]
  //   oldJob.splice(index, 1)
  //   setSponsoringJobsOnshore(oldJob)
  // }
  //remove main offshore job form
  const removeAddJob = (index) => {
    const oldJob = [...jobs]
    oldJob.splice(index, 1)
    setJobs(oldJob)
  }
  const handleAddJobOnshore = () => {
    setJobsOnshore([...jobsOnshore, { ...intialOnshoreState }])
  }
  const removeAddJobOnshore = (index) => {
    const oldJob = [...jobsOnshore]
    oldJob.splice(index, 1)
    setJobsOnshore(oldJob)
  }
  const handleAddSpouseJob = () => {
    setSpouseJobsOnshore([...spouseJobsOnshore, { ...intialOnshoreState }])
  }
  const removeAddSpouseJob = (index) => {
    const oldJob = [...spouseJobsOnshore]
    oldJob.splice(index, 1)
    setSpouseJobsOnshore(oldJob)
  }
  const handleAddSpouseOffshoreJob = () => {
    setSpouseJobsOffshore([...spouseJobsOffshore, { ...intialOnshoreState }])
  }
  const removeAddSpouseOffshoreJob = (index) => {
    const oldJob = [...spouseJobsOffshore]
    oldJob.splice(index, 1)
    setSpouseJobsOffshore(oldJob)
  }
  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...jobs]
    prevData[index][name] = value
    setJobs([...prevData])
  }
  const handleSpouseOnshoreChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...spouseJobsOnshore]
    prevData[index][name] = value
    setSpouseJobsOnshore([...prevData])
  }
  const handleSpouseOffshoreChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...spouseJobsOffshore]
    prevData[index][name] = value
    setSpouseJobsOffshore([...prevData])
  }
  const handleChangeInsideCan = (e, index) => {
    const { name, value } = e.target
    let prevData = [...jobsOnshore]
    prevData[index][name] = value
    setJobsOnshore([...prevData])
  }
  const handleJobStartDatePicker = (date, index, type) => {
    if (type === 'jobStartDate') {
      let prevData = [...jobs]
      prevData[index][type] = date
      setJobs([...prevData])
    }
    if (type === 'InCanJobStartDate') {
      let prevData = [...jobsOnshore]
      prevData[index][type] = date
      setJobsOnshore([...prevData])
    }
    if (type === 'spouseOffshoreStartDate') {
      let prevData = [...spouseJobsOffshore]
      prevData[index].jobStartDate = date
      setSpouseJobsOffshore([...prevData])
    }
    if (type === 'spouseOnshoreInCanJobStartDate') {
      let prevData = [...spouseJobsOnshore]
      prevData[index].InCanJobStartDate = date
      setSpouseJobsOnshore([...prevData])
    }
    if (type === 'sponsoredOffshoreJobStartDate') {
      let prevData = [...sponsoredJobOffshore]
      prevData[index].jobStartDate = date
      setSponsoredJobsOffshore([...prevData])
    }
    if (type === 'sponsoredInCanJobStartDate') {
      let prevData = [...sponsoredJobOnshore]
      prevData[index].InCanJobStartDate = date
      setSponsoredJobsOnshore([...prevData])
    }
    if (type === 'sponsoringInCanJobStartDate') {
      let prevData = [...sponsoringJobOnshore]
      prevData[index].InCanJobStartDate = date
      setSponsoringJobsOnshore([...prevData])
    }
    if (type === 'sponsoringOffshoreJobStartDate') {
      let prevData = [...sponsoringJobOffshore]
      prevData[index].jobStartDate = date
      setSponsoringJobsOffshore([...prevData])
    }
  }
  const handleJobEndTimePicker = (date, index, type) => {
    if (type === 'jobEndTime') {
      let prevData = [...jobs]
      prevData[index][type] = date
      setJobs([...prevData])
    }
    if (type === 'InCanJobEndTime') {
      let prevData = [...jobsOnshore]
      prevData[index][type] = date
      setJobsOnshore([...prevData])
    }
    if (type === 'spouseOffshoreJobEndTime') {
      let prevData = [...spouseJobsOffshore]
      prevData[index].jobEndTime = date
      setSpouseJobsOffshore([...prevData])
    }
    if (type === 'spouseOnshoreInCanJobEndTime') {
      let prevData = [...spouseJobsOnshore]
      prevData[index].InCanJobEndTime = date
      setSpouseJobsOnshore([...prevData])
    }
    if (type === 'sponsoredOffshoreJobEndTime') {
      let prevData = [...sponsoredJobOffshore]
      prevData[index].jobEndTime = date
      setSponsoredJobsOffshore([...prevData])
    }
    if (type === 'sponsoredInCanJobEndTime') {
      let prevData = [...sponsoredJobOnshore]
      prevData[index].InCanJobEndTime = date
      setSponsoredJobsOnshore([...prevData])
    }
    if (type === 'sponsoringOffshoreJobEndTime') {
      let prevData = [...sponsoringJobOffshore]
      prevData[index].jobEndTime = date
      setSponsoringJobsOffshore([...prevData])
    }
    if (type === 'sponsoringInCanJobEndTime') {
      let prevData = [...sponsoringJobOnshore]
      prevData[index].InCanJobEndTime = date
      setSponsoringJobsOnshore([...prevData])
    }
  }
  //handle value change of sponsored offshore job
  // const handleSponsoredOffshoreChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...sponsoredJobOffshore]
  //   prevData[index][name] = value
  //   setSponsoredJobsOffshore([...prevData])
  // }
  //handle value change of sponsored onshore job
  // const handleSponsoredOnshoreChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...sponsoredJobOnshore]
  //   prevData[index][name] = value
  //   setSponsoredJobsOnshore([...prevData])
  // }
  //handle value change of sponsoring offshore job
  // const handleSponsoringOffshoreChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...sponsoringJobOffshore]
  //   prevData[index][name] = value
  //   setSponsoringJobsOffshore([...prevData])
  // }
  //handle value change of sponsoring onshore job
  // const handleSponsoringOnshoreChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...sponsoringJobOnshore]
  //   prevData[index][name] = value
  //   setSponsoringJobsOnshore([...prevData])
  // }
  const workExperience = async (values) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/visaWorkExperience`,
        {
          ...values,
          offshoreJob: jobs,
          onshoreJob: jobsOnshore,
          spouseOffshoreJob: spouseJobsOffshore,
          spouseOnshoreJob: spouseJobsOnshore,
          sponsoredJobOffshore,
          sponsoredJobOnshore,
          sponsoringJobOnshore,
          sponsoringJobOffshore,
          visaTypeId: selectedVisa.visaId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setExperienceInfo(create)
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          visaId: create?.visaTypeId,
        })
        toast.success('Your information was saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.success(err?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    }
  }

  return (
    <div className="personal-info work-exp-section">
      <Form onSubmit={handleSubmit(workExperience)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>
            <Accordion.Body>
              <div className="outside-country">
                <h6>Offshore (Outside Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    name="numberOfJobOffshore"
                    defaultValue={experienceInfo?.numberOfJobOffshore}
                    disabled={offshoreDis ? offshoreDis : false}
                    {...register('numberOfJobOffshore')}
                  />
                </Form.Group>
                {/* {jobs?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddJob}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                )} */}
                {jobs &&
                  jobs.length > 0 &&
                  jobs?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Offshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="officialJobTitle"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.officialJobTitle}
                              onChange={(e) => handleChange(e, i)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              name="jobDescription"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.jobDescription}
                              onChange={(e) => handleChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="fullTimePosition"
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value="">
                                {child?.fullTimePosition
                                  ? child?.fullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="hoursPerWeek"
                              defaultValue={child?.hoursPerWeek}
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) => handleChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="yearsExperience"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.yearsExperience}
                              onChange={(e) => handleChange(e, i)}
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobStartDate"
                              name="jobStartDate"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobStartDate
                                  ? new Date(child?.jobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'jobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobEndTime"
                              name="jobEndTime"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobEndTime
                                  ? new Date(child?.jobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(date, i, 'jobEndTime')
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {jobs && jobs.length === 1 ? (
                            <Button
                              onClick={handleAddJob}
                              disabled={offshoreDis ? offshoreDis : false}
                            >
                              Add Job <i className="las la-plus"></i>
                            </Button>
                          ) : (
                            ''
                          )}
                          {i >= 0 && i !== 0 && (
                            <>
                              <Button
                                className="delete-btn"
                                disabled={offshoreDis ? offshoreDis : false}
                                onClick={() => removeAddJob(i)}
                              >
                                Delete Job #{i + 1} <i class="las la-minus"></i>
                              </Button>
                              <Button
                                onClick={handleAddJob}
                                disabled={offshoreDis ? offshoreDis : false}
                              >
                                Add Job <i className="las la-plus"></i>
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="inside-country">
                <h6>Onshore (In Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="numberOfJobOnshore"
                    disabled={onshoreDis ? onshoreDis : false}
                    defaultValue={experienceInfo?.numberOfJobOnshore}
                    {...register('numberOfJobOnshore')}
                  />
                </Form.Group>
                {/* {jobsOnshore?.length === 0 ? (
                  <div className="add-btn">
                    <Button onClick={handleAddJobOnshore}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                ) : (
                  ''
                )} */}
                {jobsOnshore &&
                  jobsOnshore?.length > 0 &&
                  jobsOnshore?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Onshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanOfficialJobTitle"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanOfficialJobTitle}
                              onChange={(e) => handleChangeInsideCan(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="InCanJobDescription"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanJobDescription}
                              onChange={(e) => handleChangeInsideCan(e, i)}
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="InCanFullTimePosition"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanFullTimePosition}
                              onChange={(e) => handleChangeInsideCan(e, i)}
                            >
                              <option value="">
                                {child?.InCanFullTimePosition
                                  ? child?.InCanFullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanHoursPerWeek"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanHoursPerWeek}
                              onChange={(e) => handleChangeInsideCan(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="InCanYearsExperience"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanYearsExperience}
                              onChange={(e) => handleChangeInsideCan(e, i)}
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobStartDate"
                              name="InCanJobStartDate"
                              placeholderText="Select Date"
                              disabled={onshoreDis ? onshoreDis : false}
                              selected={
                                child?.InCanJobStartDate
                                  ? new Date(child?.InCanJobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'InCanJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobEndTime"
                              name="InCanJobEndTime"
                              disabled={onshoreDis ? onshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.InCanJobEndTime
                                  ? new Date(child?.InCanJobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'InCanJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {jobsOnshore && jobsOnshore?.length === 1 ? (
                            <Button
                              onClick={handleAddJobOnshore}
                              disabled={onshoreDis ? onshoreDis : false}
                            >
                              Add Job <i className="las la-plus"></i>
                            </Button>
                          ) : (
                            ''
                          )}
                          {i >= 0 && i !== 0 && (
                            <>
                              <Button
                                className="delete-btn"
                                disabled={onshoreDis ? onshoreDis : false}
                                onClick={() => removeAddJobOnshore(i)}
                              >
                                Delete Job #{i + 1} <i class="las la-minus"></i>
                              </Button>
                              <Button
                                onClick={handleAddJobOnshore}
                                disabled={onshoreDis ? onshoreDis : false}
                              >
                                Add Job <i className="las la-plus"></i>
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Add Spouse Information</Accordion.Header>
            <Accordion.Body>
              <div className="outside-country">
                <h6>Offshore (Outside Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    name="sNumberOfJobOffshore"
                    disabled={spouseOffshoreDis ? spouseOffshoreDis : false}
                    defaultValue={experienceInfo?.sNumberOfJobOffshore}
                    {...register('sNumberOfJobOffshore')}
                  />
                </Form.Group>
                {spouseJobsOffshore &&
                  spouseJobsOffshore.length > 0 &&
                  spouseJobsOffshore.map((child, i) => (
                    <>
                      <h6 className="child-heading">Offshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="officialJobTitle"
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              defaultValue={child?.officialJobTitle}
                              onChange={(e) => handleSpouseOffshoreChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              name="jobDescription"
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              defaultValue={child?.jobDescription}
                              onChange={(e) => handleSpouseOffshoreChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="fullTimePosition"
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              onChange={(e) => handleSpouseOffshoreChange(e, i)}
                            >
                              <option value="">
                                {child?.fullTimePosition
                                  ? child?.fullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="hoursPerWeek"
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              defaultValue={child?.hoursPerWeek}
                              onChange={(e) => handleSpouseOffshoreChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="yearsExperience"
                              defaultValue={child?.yearsExperience}
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              onChange={(e) => handleSpouseOffshoreChange(e, i)}
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobStartDate"
                              name="jobStartDate"
                              placeholderText="Select Date"
                              //selected={child.jobStartDate}
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              selected={
                                child?.jobStartDate
                                  ? new Date(child?.jobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'spouseOffshoreStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobEndTime"
                              name="jobEndTime"
                              placeholderText="Select Date"
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                              selected={
                                child?.jobEndTime
                                  ? new Date(child?.jobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'spouseOffshoreJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {spouseJobsOffshore &&
                          spouseJobsOffshore.length === 1 ? (
                            <Button
                              onClick={handleAddSpouseOffshoreJob}
                              disabled={
                                spouseOffshoreDis ? spouseOffshoreDis : false
                              }
                            >
                              Add Job <i className="las la-plus"></i>
                            </Button>
                          ) : (
                            ''
                          )}
                          {i >= 0 && i !== 0 && (
                            <>
                              <Button
                                className="delete-btn"
                                disabled={
                                  spouseOffshoreDis ? spouseOffshoreDis : false
                                }
                                onClick={() => removeAddSpouseOffshoreJob(i)}
                              >
                                Delete Job #{i + 1} <i class="las la-minus"></i>
                              </Button>
                              <Button
                                onClick={handleAddSpouseOffshoreJob}
                                disabled={
                                  spouseOffshoreDis ? spouseOffshoreDis : false
                                }
                              >
                                Add Job <i className="las la-plus"></i>
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="inside-country">
                <h6>Onshore (In Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="sNumberOfJobOnshore"
                    disabled={spouseOnshoreDis ? spouseOnshoreDis : false}
                    defaultValue={experienceInfo?.sNumberOfJobOnshore}
                    {...register('sNumberOfJobOnshore')}
                  />
                </Form.Group>
                {spouseJobsOnshore &&
                  spouseJobsOnshore?.length > 0 &&
                  spouseJobsOnshore.map((child, i) => (
                    <>
                      <h6 className="child-heading">Onshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanOfficialJobTitle"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              defaultValue={child?.InCanOfficialJobTitle}
                              onChange={(e) => handleSpouseOnshoreChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="InCanJobDescription"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              defaultValue={child?.InCanJobDescription}
                              onChange={(e) => handleSpouseOnshoreChange(e, i)}
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="InCanFullTimePosition"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              defaultValue={child?.InCanFullTimePosition}
                              onChange={(e) => handleSpouseOnshoreChange(e, i)}
                            >
                              <option value="">
                                {child?.InCanFullTimePosition
                                  ? child?.InCanFullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanHoursPerWeek"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              defaultValue={child?.InCanHoursPerWeek}
                              onChange={(e) => handleSpouseOnshoreChange(e, i)}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="InCanYearsExperience"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              defaultValue={child?.InCanYearsExperience}
                              onChange={(e) => handleSpouseOnshoreChange(e, i)}
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobStartDate"
                              name="InCanJobStartDate"
                              placeholderText="Select Date"
                              // selected={child.InCanJobStartDate}
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              selected={
                                child?.InCanJobStartDate
                                  ? new Date(child?.InCanJobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'spouseOnshoreInCanJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobEndTime"
                              name="InCanJobEndTime"
                              placeholderText="Select Date"
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                              selected={
                                child?.InCanJobEndTime
                                  ? new Date(child?.InCanJobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'spouseOnshoreInCanJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {spouseJobsOnshore &&
                          spouseJobsOnshore.length === 1 ? (
                            <Button
                              onClick={handleAddSpouseJob}
                              disabled={
                                spouseOnshoreDis ? spouseOnshoreDis : false
                              }
                            >
                              Add Job <i className="las la-plus"></i>
                            </Button>
                          ) : (
                            ''
                          )}
                          {i >= 0 && i !== 0 && (
                            <>
                              <Button
                                className="delete-btn"
                                disabled={
                                  spouseOnshoreDis ? spouseOnshoreDis : false
                                }
                                onClick={() => removeAddSpouseJob(i)}
                              >
                                Delete Job #{i + 1} <i class="las la-minus"></i>
                              </Button>
                              <Button
                                onClick={handleAddSpouseJob}
                                disabled={
                                  spouseOnshoreDis ? spouseOnshoreDis : false
                                }
                              >
                                Add Job <i className="las la-plus"></i>
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="2">
            <Accordion.Header>Sponsored Applicant</Accordion.Header>
            <Accordion.Body>
              <div className="outside-country">
                <h6>Offshore (Outside Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    name="sponsoredNumberOfJobOffshore"
                    defaultValue={experienceInfo?.sponsoredNumberOfJobOffshore}
                    disabled={offshoreDis ? offshoreDis : false}
                    {...register('sponsoredNumberOfJobOffshore')}
                  />
                </Form.Group>
                {sponsoredJobOffshore && sponsoredJobOffshore?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddSponsoredJobOffshore}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
                {sponsoredJobOffshore &&
                  sponsoredJobOffshore.length > 0 &&
                  sponsoredJobOffshore?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Offshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="officialJobTitle"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.officialJobTitle}
                              onChange={(e) =>
                                handleSponsoredOffshoreChange(e, i)
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              name="jobDescription"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.jobDescription}
                              onChange={(e) =>
                                handleSponsoredOffshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="fullTimePosition"
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) =>
                                handleSponsoredOffshoreChange(e, i)
                              }
                            >
                              <option value="">
                                {child?.fullTimePosition
                                  ? child?.fullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="hoursPerWeek"
                              defaultValue={child?.hoursPerWeek}
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) =>
                                handleSponsoredOffshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="yearsExperience"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.yearsExperience}
                              onChange={(e) =>
                                handleSponsoredOffshoreChange(e, i)
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobStartDate"
                              name="jobStartDate"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobStartDate
                                  ? new Date(child?.jobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'sponsoredOffshoreJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobEndTime"
                              name="jobEndTime"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobEndTime
                                  ? new Date(child?.jobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'sponsoredOffshoreJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {i >= 1 && (
                            <Button
                              className="delete-btn"
                              disabled={offshoreDis ? offshoreDis : false}
                              onClick={() => removeSponsoredJobOffshore(i)}
                            >
                              Delete Job #{i + 1} <i class="las la-minus"></i>
                            </Button>
                          )}

                          <Button
                            onClick={handleAddSponsoredJobOffshore}
                            disabled={offshoreDis ? offshoreDis : false}
                          >
                            Add Job <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="inside-country">
                <h6>Onshore (In Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="sponsoredNumberOfJobOnshore"
                    disabled={onshoreDis ? onshoreDis : false}
                    defaultValue={experienceInfo?.sponsoredNumberOfJobOnshore}
                    {...register('sponsoredNumberOfJobOnshore')}
                  />
                </Form.Group>
                {sponsoredJobOnshore && sponsoredJobOnshore?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddSponsoredJobOnshore}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
                {sponsoredJobOnshore &&
                  sponsoredJobOnshore?.length > 0 &&
                  sponsoredJobOnshore?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Onshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanOfficialJobTitle"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanOfficialJobTitle}
                              onChange={(e) =>
                                handleSponsoredOnshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="InCanJobDescription"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanJobDescription}
                              onChange={(e) =>
                                handleSponsoredOnshoreChange(e, i)
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="InCanFullTimePosition"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanFullTimePosition}
                              onChange={(e) =>
                                handleSponsoredOnshoreChange(e, i)
                              }
                            >
                              <option value="">
                                {child?.InCanFullTimePosition
                                  ? child?.InCanFullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanHoursPerWeek"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanHoursPerWeek}
                              onChange={(e) =>
                                handleSponsoredOnshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="InCanYearsExperience"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanYearsExperience}
                              onChange={(e) =>
                                handleSponsoredOnshoreChange(e, i)
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobStartDate"
                              name="InCanJobStartDate"
                              placeholderText="Select Date"
                              disabled={onshoreDis ? onshoreDis : false}
                              selected={
                                child?.InCanJobStartDate
                                  ? new Date(child?.InCanJobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'sponsoredInCanJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobEndTime"
                              name="InCanJobEndTime"
                              disabled={onshoreDis ? onshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.InCanJobEndTime
                                  ? new Date(child?.InCanJobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'sponsoredInCanJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {i >= 1 && (
                            <Button
                              className="delete-btn"
                              disabled={onshoreDis ? onshoreDis : false}
                              onClick={() => removeSponsoredJobOnshore(i)}
                            >
                              Delete Job #{i + 1} <i class="las la-minus"></i>
                            </Button>
                          )}

                          <Button
                            onClick={handleAddSponsoredJobOnshore}
                            disabled={onshoreDis ? onshoreDis : false}
                          >
                            Add Job <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Add Sponsoring Applicant Information
            </Accordion.Header>
            <Accordion.Body>
              <div className="outside-country">
                <h6>Offshore (Outside Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    name="sponsoringNumberOfJobOffshore"
                    defaultValue={experienceInfo?.sponsoringNumberOfJobOffshore}
                    disabled={offshoreDis ? offshoreDis : false}
                    {...register('sponsoringNumberOfJobOffshore')}
                  />
                </Form.Group>
                {sponsoringJobOffshore && sponsoringJobOffshore?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddSponsoringJobOffshore}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
                {sponsoringJobOffshore &&
                  sponsoringJobOffshore.length > 0 &&
                  sponsoringJobOffshore?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Offshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="officialJobTitle"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.officialJobTitle}
                              onChange={(e) =>
                                handleSponsoringOffshoreChange(e, i)
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              name="jobDescription"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.jobDescription}
                              onChange={(e) =>
                                handleSponsoringOffshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="fullTimePosition"
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) =>
                                handleSponsoringOffshoreChange(e, i)
                              }
                            >
                              <option value="">
                                {child?.fullTimePosition
                                  ? child?.fullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="hoursPerWeek"
                              defaultValue={child?.hoursPerWeek}
                              disabled={offshoreDis ? offshoreDis : false}
                              onChange={(e) =>
                                handleSponsoringOffshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="yearsExperience"
                              disabled={offshoreDis ? offshoreDis : false}
                              defaultValue={child?.yearsExperience}
                              onChange={(e) =>
                                handleSponsoringOffshoreChange(e, i)
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobStartDate"
                              name="jobStartDate"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobStartDate
                                  ? new Date(child?.jobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'sponsoringOffshoreJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="jobEndTime"
                              name="jobEndTime"
                              disabled={offshoreDis ? offshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.jobEndTime
                                  ? new Date(child?.jobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'sponsoringOffshoreJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {i >= 1 && (
                            <Button
                              className="delete-btn"
                              disabled={offshoreDis ? offshoreDis : false}
                              onClick={() => removeSponsoringJobOffshore(i)}
                            >
                              Delete Job #{i + 1} <i class="las la-minus"></i>
                            </Button>
                          )}

                          <Button
                            onClick={handleAddSponsoringJobOffshore}
                            disabled={offshoreDis ? offshoreDis : false}
                          >
                            Add Job <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="inside-country">
                <h6>Onshore (In Canada)</h6>
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>Number of Jobs in the Past 10 Years</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="sponsoringNumberOfJobOnshore"
                    disabled={onshoreDis ? onshoreDis : false}
                    defaultValue={experienceInfo?.sponsoringNumberOfJobOnshore}
                    {...register('sponsoringNumberOfJobOnshore')}
                  />
                </Form.Group>
                {sponsoringJobOnshore && sponsoringJobOnshore?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddSponsoringJobOnshore}>
                      Add Job <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
                {sponsoringJobOnshore &&
                  sponsoringJobOnshore?.length > 0 &&
                  sponsoringJobOnshore?.map((child, i) => (
                    <>
                      <h6 className="child-heading">Onshore Job #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Official Job Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanOfficialJobTitle"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanOfficialJobTitle}
                              onChange={(e) =>
                                handleSponsoringOnshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="InCanJobDescription"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanJobDescription}
                              onChange={(e) =>
                                handleSponsoringOnshoreChange(e, i)
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Full-Time Position?</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="InCanFullTimePosition"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanFullTimePosition}
                              onChange={(e) =>
                                handleSponsoringOnshoreChange(e, i)
                              }
                            >
                              <option value="">
                                {child?.InCanFullTimePosition
                                  ? child?.InCanFullTimePosition
                                  : 'Select'}
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>How many hours per week?</Form.Label>
                            <Form.Control
                              type="text"
                              name="InCanHoursPerWeek"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanHoursPerWeek}
                              onChange={(e) =>
                                handleSponsoringOnshoreChange(e, i)
                              }
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Years of Experience</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="InCanYearsExperience"
                              disabled={onshoreDis ? onshoreDis : false}
                              defaultValue={child?.InCanYearsExperience}
                              onChange={(e) =>
                                handleSponsoringOnshoreChange(e, i)
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job Start Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobStartDate"
                              name="InCanJobStartDate"
                              placeholderText="Select Date"
                              disabled={onshoreDis ? onshoreDis : false}
                              selected={
                                child?.InCanJobStartDate
                                  ? new Date(child?.InCanJobStartDate)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobStartDatePicker(
                                  date,
                                  i,
                                  'sponsoringInCanJobStartDate',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                          <Form.Group
                            className="form-group"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Job End Date (Month/Year)</Form.Label>
                            <DatePicker
                              type="InCanJobEndTime"
                              name="InCanJobEndTime"
                              disabled={onshoreDis ? onshoreDis : false}
                              placeholderText="Select Date"
                              selected={
                                child?.InCanJobEndTime
                                  ? new Date(child?.InCanJobEndTime)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleJobEndTimePicker(
                                  date,
                                  i,
                                  'sponsoringInCanJobEndTime',
                                )
                              }
                              className="form-select"
                            />
                          </Form.Group>
                        </div>
                        <div className="add-btn">
                          {i >= 1 && (
                            <Button
                              className="delete-btn"
                              disabled={onshoreDis ? onshoreDis : false}
                              onClick={() => removeSponsoringJobOnshore(i)}
                            >
                              Delete Job #{i + 1} <i class="las la-minus"></i>
                            </Button>
                          )}

                          <Button
                            onClick={handleAddSponsoringJobOnshore}
                            disabled={onshoreDis ? onshoreDis : false}
                          >
                            Add Job <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <div className="visa-btn">
          <Button
            type="submit"
            disabled={spouseOnshoreDis ? spouseOnshoreDis : false}
          >
            Save
          </Button>
          <div className="visa-desc-text">
            <p>
              Once your form is completed, you will be able to make new changes
              by contacting our Support team.
            </p>
            <p>When you're ready, click "Submit"!</p>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default WorkExperience
