import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import { toast } from 'react-toastify'
import { Form, Button } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import logo from '../../assets/images/footer/logo.svg'
import envelope from '../../assets/images/login/envelope.svg'
import call from '../../assets/images/login/call.svg'
import facebook from '../../assets/images/login/facebook.svg'
import linkedin from '../../assets/images/login/linkedin.svg'
import twitter from '../../assets/images/login/twitter.svg'
import 'react-toastify/dist/ReactToastify.css'
import './login.css'
import { RegisterSchema } from '../../utils/validationSchema'
import UserContext from '../../context/UserContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const Register = () => {
  const navigate = useNavigate()
  const [logInData, setLogInData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    country: '',
    countryCode: '',
  })
  const { setUser, countries } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(RegisterSchema) })
  const { t } = useTranslation()
  const handlePhoneChange = (e) => {
    setValue('phone', '+' + e)
  }
  const handleCountryChange = (e) => {
    const data = countries.filter((el) => el.name === e.target.value)
    setValue('country', e.target.value)
    setValue('countryCode', data[0]?.countryCode)
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setLogInData({
      ...logInData,
      [name]: value,
    })
  }
  const registerUser = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/users/create`, values)
      const { status, user } = response.data
      if (status === 200) {
        setUser(user)
        navigate('/verifyUser')
        toast.success('Otp sent on your phone number', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">
          <div className="login-content">
            <Link to="/">
              <img src={logo} alt="" className="logo-login" />
            </Link>

            <h2>Welcome!</h2>

            <div className="login-contact-info">
              <span>
                <img src={envelope} alt="" />
                {t('contactEmail')}
              </span>
              <span>
                <img src={call} alt="" />
                {t('contactPhone')}
              </span>
            </div>

            <div className="login-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="login-form">
            <div className="login-popup-content">
              <h2>Sign Up</h2>
              {/* <p>Enter your email and password to sign in</p> */}

              <Form onSubmit={handleSubmit(registerUser)}>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.name ? `error-border` : ``}`}
                    type="text"
                    placeholder="name"
                    name="name"
                    onChange={handleChange}
                    defaultValue={logInData.name}
                    {...register('name')}
                  />
                  {errors?.name ? (
                    <span className="error-msg">{errors.name.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    {...register('email')}
                    defaultValue={logInData.email}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    className={`${errors?.password ? `error-border` : ``}`}
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    defaultValue={logInData.password}
                    {...register('password')}
                  />
                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Select onChange={handleCountryChange} name="country">
                    {countries &&
                      countries?.length > 0 &&
                      countries.map((el) => (
                        <option value={el?.name} name="country">
                          {el?.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <PhoneInput
                    country={'de'}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: 'phone',
                    }}
                  />

                  {errors?.phone ? (
                    <span className="error-msg">{errors.phone.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                {/* <div className="one-time">
                  <Link to="/phoneverify">Login with a one-time password</Link>
                </div> */}

                {/* <div className="forget-section">
                  <div className="remember-me">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Remember me"
                      name="rememberMe"
                      onChange={handleChange}
                      {...register("rememberMe", { required: false })}
                    />
                  </div>
                  <Link to="/forgot">Forgot Password</Link>
                </div> */}

                <Button type="submit">Sign Up</Button>
                <div className="one-time">
                  Already have an account? <Link to="/login"> &nbsp;Login</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Register
