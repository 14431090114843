import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import "./legal.css";


const TermConditions = () => {
    const { t } = useTranslation();

    return (

        <Layout>


            <div className="legal-page">


                <div className="container">

                    <a
                        className="breadcrum"
                        href="/"
                    >
                        {t('visas.businessHeading2')}
                    </a>
                    <div className="legal-content terms">

                        <h2>{t("terms.Heading")}</h2>

                        <div>{parse(t("terms.content"))}</div>

                    </div>

                    {t("terms.content2")}

                </div>
            </div>

        </Layout>

    );
}

export default TermConditions;