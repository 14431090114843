import Paginate from 'react-bootstrap/Pagination';

const Pagination=({
    pageCount,
    handlePageClick,
    setSkip,
    // paymentCount,
    // skip
})=> {
    const handleFirstPage=()=>{
            setSkip(1);
    }
    const handleLastPage=()=>{
        setSkip((pageCount-1)*5)
    }

    let item=[]
    for(let i=0;i<pageCount;i++){
        item.push(i)
    }
  return (
    <Paginate>
      <Paginate.First onClick={handleFirstPage}/>
      {item.map((i)=>(
      <Paginate.Item onClick={()=>handlePageClick(i)}>{i===0?1:i+1}</Paginate.Item>
      ))}
      <Paginate.Last onClick={handleLastPage}/>
    </Paginate>
  );
}

export default Pagination;