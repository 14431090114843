/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput from 'react-phone-input-2'
import {phone} from 'phone'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import checkicon from '../../assets/images/header/success.svg'
import { Form, Button, Spinner } from 'react-bootstrap'
import './modal.css'
import { ConsultForm } from '../../utils/validationSchema'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

const ConsultationPopup = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [succ, setSucc] = useState(false)
  const [loading,setLoading]=useState(false)
  const { userGeoData } = useContext(UserContext)
  const [phoneCountryCode,setPhoneCountryCode]=useState('')
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    setError
  } = useForm({ resolver: yupResolver(ConsultForm) })

  const handleChange = (e,country) => {
    setPhoneCountryCode(country?.countryCode)
      setValue('phoneNumber', '+' + e)
      setError('phoneNumber',{message:""})
  }
  const termsCheck = watch('terms')
  const handleSubmitForm = async (values) => {
    try {
      const validate=phone(values.phoneNumber, {country: phoneCountryCode});
      if(!validate?.isValid){
        setError('phoneNumber',{message:"Your phone number is Invalid"})
        return
      } 
      setLoading(true)
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData?.ip,
        countryCode: userGeoData?.country_code,
      })
      setLoading(false)
      const {
        data: { msg, redirect ,leadRedirect,status},
      } = response
      if (status ===200) {
        setSucc(true)
        toast.success(msg|| 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        if (redirect) return navigate(redirect)
        if (leadRedirect) {
         return window.location.href = leadRedirect
        }
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className="consultation-popup-grid">
      {succ ? (
        <div className="consultation-popup-content text-left">
          <div className="thank-you-grid">
            <div className="thank-you-content">
              <h2>{t('consultationPopup.thankyouheading')}</h2>
              <p>{t('consultationPopup.thankyoutext')}</p>
            </div>

            <div className="thank-you-social">
              <p>{t('consultationPopup.connecttext')}</p>
              <ul>
                <li>
                  <a
                    className="facebook-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uisAustraliaoffical"
                    width="15"
                    height="15"
                  ></a>
                </li>
                <li>
                  <a
                    className="instagram-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/uis_australia/"
                    width="15"
                    height="15"
                  ></a>
                </li>
                <li>
                  <a
                    className="linkedin-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                    width="15"
                    height="15"
                  ></a>
                </li>
                <li>
                  <a
                    className="twitter-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_australia"
                    width="15"
                    height="15"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="consultation-popup-content">
          <h4>{t('consultationPopup.Heading1')}</h4>
          <p>{t('consultationPopup.Paragraph1')}</p>

          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Form.Group>
              <Form.Control
                name="firstName"
                type="text"
                placeholder={t('visaContact.firstname')}
                {...register('firstName')}
              />
              {errors?.firstName ? (
                <span className="error-msg">{errors.firstName.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="lastName"
                type="text"
                placeholder={t('visaContact.lastname')}
                {...register('lastName')}
              />
              {errors?.lastName ? (
                <span className="error-msg">{errors.lastName.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                name="email"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                {...register('email')}
              />
              {errors?.email ? (
                <span className="error-msg">{errors.email.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <PhoneInput
                country={
                  userGeoData?.country_code
                    ? userGeoData?.country_code?.toLowerCase()
                    : 'de'
                }
                onChange={(e,country)=>handleChange(e,country)}
                inputProps={{
                  name: 'phoneNumber',
                }}
              />

              {errors?.phoneNumber ? (
                <span className="error-msg">{errors.phoneNumber.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            {/* <Form.Group class="checkbox">
              <Form.Check.Input
                type="checkbox"
                defaultChecked
                name="terms"
                {...register('terms')}
                disabled={termsCheck ? false : true}
              />
              <Form.Check.Label>
                {parse(t('homeEligibility.checkbox'))}
              </Form.Check.Label>
            </Form.Group> */}
            <Form.Group controlId="formBasicCheckbox" className="checkbox">
              <Form.Check
                type="checkbox"
                name="terms"
                defaultChecked={true}
                {...register("terms")}
              />
              <Form.Check.Label>
                {parse(t('homeEligibility.checkbox'))}
              </Form.Check.Label>
            </Form.Group>
            
            <Button
              variant="primary"
              type="submit"
              className={`${succ ? `success-btn` : ``}`}
              disabled={(termsCheck ===undefined && !loading)|| (termsCheck && !loading)?false:loading?loading:true}
            >
              {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;
              {succ
                ? t('homeNewsletter.successButton')
                : t('consultationPopup.submit')}
              {succ ? <img src={checkicon} alt="" /> : ''}
            </Button>
          </Form>
        </div>
      )}

      <div className="consultation-popup-cover"></div>
    </div>
  )
}

export default ConsultationPopup
