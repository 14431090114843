/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import checkicon from '../../assets/images/header/success.svg'

import './user.css'
import { userSchema } from '../../utils/validationSchema'

import UserContext from '../../context/UserContext'

const updateUser = ({ activeId, setAllUSers, setEditPage }) => {
  const initialValue = {
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    email2: '',
    address: '',
    city: '',
    country: '',
    phone: '',
  }
  const { t } = useTranslation()
  const [succ, setSucc] = useState(false)
  const { countries } = useContext(UserContext)
  const [values, setValues] = useState(initialValue)
  const [errors, setErrors] = useState('')
  const [setNumber] = useState('')

  const getUserDetailById = async () => {
    const response = await axios.get(
      `${serverPath}/users/getUserDetailById/${activeId}`,
      { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } },
    )
    const { user } = response?.data || {}
    if (user) {
      setValues({
        ...values,
        firstName: user?.firstName,
        lastName: user?.lastName,
        name: user?.name,
        email: user?.email,
        email2: user?.email2,
        address: user?.address,
        country: user?.country,
        city: user?.city,
        phone: user?.phoneNumber,
      })
      setNumber(user?.phoneNumber)
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const handleCountryChange = (e) => {
    const { name, value } = e.target
    const newData = { ...values, [name]: value }
    const data = countries.filter((el) => el.name === value)
    setValues({ ...newData, countryCode: data[0]?.countryCode })
  }

  useEffect(() => {
    if (activeId) {
      getUserDetailById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId])
  const handlePhoneChange = (e) => {
    setNumber(+e)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    try {
      const validate = await userSchema.validate(values, {
        abortEarly: false,
      })
      if (validate) {
        const response = await axios.put(
          `${serverPath}/users/updateUser/${activeId}`,
          values,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        const { status } = response
        if (status === 200) {
          const response = await axios.get(`${serverPath}/users/getAllUsers`, {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
          const { data, status } = response || []
          if (status === 200) {
            setAllUSers(data?.users)
            setEditPage(false)
            setSucc(true)
            toast.success('Update successfully', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            })
          }
        }
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
      let er = {}
      error.inner.forEach((el) => {
        er[el.path] = el.message
      })
      setErrors({ ...er })
    }
  }
  return (
    <div className="consultation-popup-grid">
      {succ ? (
        <div className="consultation-popup-content">
          <div className="thank-you-grid">
            <div className="thank-you-content">
              {/* <img src={icon1} alt="" /> */}
              <h2>{t('consultationPopup.thankyouheading')}</h2>
              <p>{t('consultationPopup.thankyoutext')}</p>
            </div>

            <div className="thank-you-social">
              <p>{t('consultationPopup.connecttext')}</p>
              <ul>
                <li>
                  <a
                    className="facebook-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uisAustraliaoffical"
                    width="35" height='35'
                  >

                  </a>
                </li>
                <li>
                  <a
                    className="instagram-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/uis_australia/"
                    width="35" height='35'
                  >
                  </a>
                </li>
                <li>
                  <a
                    className="linkedin-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                    width="35" height='35'
                  >
                  </a>
                </li>
                <li>
                  <a
                    className="twitter-icon"
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_australia"
                    width="35" height='35'
                  >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="consultation-popup-content">
          <h2>Edit User</h2>
          <p>Edit your details to update your profile</p>
          <Form>
            <Form.Group>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="First Name"
                value={values?.firstName}
                onChange={handleChange}
              />
              {errors?.name ? (
                <span className="error-msg">{errors.name.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={values?.lastName}
                onChange={handleChange}
              />
              {errors?.lastName ? (
                <span className="error-msg">{errors.lastName.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="name"
                type="text"
                placeholder="Full Name"
                value={values?.name}
                onChange={handleChange}
              />
              {errors?.name ? (
                <span className="error-msg">{errors.name.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="email"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                value={values?.email}
                onChange={handleChange}
              />
              {errors?.email ? (
                <span className="error-msg">{errors.email.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="email2"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                value={values?.email2}
                onChange={handleChange}
              />
              {errors?.email2 ? (
                <span className="error-msg">{errors.email2.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                type="text"
                name="address"
                placeholder="Address"
                value={values?.address}
                onChange={handleChange}
              />
              {errors?.address ? (
                <span className="error-msg">{errors.address.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="city"
                placeholder="city"
                value={values?.city}
                onChange={handleChange}
              />
              {errors?.city ? (
                <span className="error-msg">{errors.city.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Select onChange={handleCountryChange} name="country">
                <option
                  value={values?.country ? values?.country : ''}
                  name="country"
                  selected
                >
                  {values?.country ? values?.country : 'Select Country'}
                </option>
                {countries &&
                  countries?.length > 0 &&
                  countries.map((el) => (
                    <option value={el?.name} name="country">
                      {el?.name}
                    </option>
                  ))}
              </Form.Select>
              {errors?.country ? (
                <span className="error-msg">{errors.country.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <PhoneInput
                country={'de'}
                onChange={handlePhoneChange}
                value={values.phone}
                inputProps={{
                  name: 'phoneNumber',
                }}
              />

              {errors?.phone ? (
                <span className="error-msg">{errors.phone.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className={`${succ ? `success-btn` : ``}`}
              onClick={handleSubmitForm}
            >
              {succ
                ? t('homeNewsletter.successButton')
                : t('consultationPopup.submit')}
              {succ ? <img src={checkicon} alt="" /> : ''}
            </Button>
  
          </Form>
        </div>
      )}
    </div>
  )
}

export default updateUser
