/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ApplicationLayout from "../layout";
import { Table } from "react-bootstrap";
import axios from "axios";
import { serverPath } from "../../../config/key";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import PaymentSuccess from "../../thankyou/paymentSuccessModel";
import PaymentFailed from "../../thankyou/paymentFailed";
let options = {
  // weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const Invoices = () => {
  const search = useLocation().search;
  const [userInvoice, setUserInvoice] = useState([]);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [openFailedPage, setOpenFailedPage] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const getUserInvoice = async () => {
    try {
      const res = await axios.get(`${serverPath}/users/getUserInvoiceById`, {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const { invoiceDetail } = res?.data || [];
      if (invoiceDetail?.length > 0) {
        setUserInvoice(invoiceDetail);
      }
    } catch (err) {
      toast.error(err.message, { toastId: "err0r" });
    }
  };
  useEffect(() => {
    getUserInvoice();
  }, []);
  useEffect(() => {
    if (new URLSearchParams(search).get("payment") === "success") {
      setOpenThankYou(true);
    }
    if (new URLSearchParams(search).get("payment") === "failed") {
      setOpenFailedPage(true);
      setPaymentError(new URLSearchParams(search).get("err"));
    }
  }, []);

  const handleShowPdf = (url) => {
    window.open(url, "_blank");
  };
  const handleClose = () => {
    setOpenThankYou(false);
    setOpenFailedPage(false);
  };
  return (
    <ApplicationLayout>
      <div className={`invoices-page`}>
        <div className="container">
          <h3>Invoices</h3>
          <div className={`invoices-page-content scroll-content`}>
            <Table responsive>
              <tbody>
                {userInvoice &&
                  userInvoice.length > 0 &&
                  userInvoice.map((inv) => (
                    <tr>
                      <td className="invoice-colm-name col">
                        {inv.product[0].name}
                      </td>
                      <td className="col col-lg-2">
                        <div className="invoice-date">
                          {new Date(inv?.date)?.toLocaleDateString(
                            "en-US",
                            options
                          )}
                          <span>#MS-415646</span>
                        </div>
                      </td>
                      <td className="col col-lg-2" align="center">
                        <span className="invoice-price">
                          ${inv.product[0].price}
                        </span>
                      </td>
                      <td className="col col-lg-2 text-right" align="right">
                        <span
                          className="invoice-pdf"
                          onClick={() =>
                            handleShowPdf(inv?.invoicePdf?.fileUrl)
                          }
                        >
                          Download PDF
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <PaymentSuccess openThankYou={openThankYou} handleClose={handleClose} />
      <PaymentFailed
        openFailed={openFailedPage}
        handleClose={handleClose}
        paymentError={paymentError}
      />
    </ApplicationLayout>
  );
};

export default Invoices;
