import React, { useState, useEffect } from 'react'
import {
  useLocation,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import axios from 'axios'
import { serverPath } from './config/key'
import { toast, ToastContainer } from 'react-toastify'
import UserContext from './context/UserContext'
import Home from './components/home/home'
import Login from './components/login/login'
import Forgot from './components/login/forgot'
import PhoneVerify from './components/login/phoneverify'
import About from './components/about/about'
import Immigration from './components/immigration/immigration'
import Contact from './components/contact/contact'
import TermConditions from './components/legal/term'
import PrivacyPolicy from './components/legal/privacy'
// import Express from './components/visa/express'
import WorkerVisa from './components/visa/worker'
import BusinessVisa from './components/visa/business'
//import TouristVisa from './components/visa/tourist'
import FamilyVisa from './components/visa/family'
import HolidayVisa from './components/visa/holiday'
// import TemporaryVisa from './components/visa/temporary'
import StudentVisa from './components/visa/student'
import './assets/css/line-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-input-2/lib/bootstrap.css'
import OtpVerify from './components/leadVerify'
import VisaEvaluation from './components/visaEvaluation/visaEvaluation'
// import ThankYouScorePage from './components/thankYouPage/thankYouScorePage'
import Register from './components/login/register'
import RegisterOtpVerify from './components/registerOtpVerify/otpVerify'
import Welcome from './components/myapplication/welcome'
import Guidelines from './components/myapplication/guidelines'
import Settings from './components/myapplication/settings'
import Notification from './components/myapplication/notification'
import Invoices from './components/myapplication/invoices'
import Users from './components/users/users'
import ManualInvoice from './components/manualinvoice/index'
import './assets/css/line-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-input-2/lib/bootstrap.css'
import AllInvoices from './components/manualinvoice/invoices'
import InvoiceTemplate from './components/manualinvoice/template1'
import Products from './components/myapplication/products/index'
import ViewPayments from './components/Payments'
import ThankYouPage from './components/thankyou/thankyou'
import GetUserDetailForPayment from './components/Payments/getUserDetail'
import WorldCardForm from './components/worldCardForm'

import InvoiceReport from './components/manualinvoice/invoiceReport'
import VisaForm from './components/visaForm'
import InFrenchPrivacyPolicy from './components/legal/inFrenchPrivacy'
import InFrenchTermConditions from './components/legal/inFrenchTerms'
import {data} from './utils/countries'
const ProtectedRoute = ({ needRoles, user, children }) => {
  if (Object.keys(user)?.length > 0) {
    if (needRoles?.some((el) => user?.role?.includes(el))) {
      return children
    } else {
      return <Navigate to="/login" />
    }
  }
  return <Navigate to="/" />
}

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [user, setUser] = useState(
    localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : {},
  )
  const [users, setUsers] = useState([])
  const [countries, setCountries] = useState([])
  const [application, setApplication] = useState({})
  const [userGeoData, setUserGeoData] = useState({})
  const [savePercentage, setSavePercentage] = useState(0)
  const [selectedVisa, setSelectedVisa] = useState({
    visaId: '',
    invoiceId: '',
  })
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axios.get(`${serverPath}/users/getProfile`, {
          headers: { authorization: `Bearer ${token}` },
        })

        const { user } = res?.data
        if (user) {
          setUser(user)
        }
      } catch (error) {
        localStorage.removeItem('token')
        toast.error(error.response.data, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        navigate('/login')
      }
    }
    if (token && !user) {
      getProfile()
    }
  }, [navigate, token, user])
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])
  const getCountries = async () => {
    let countryDetail = [];
    try {
      if (data?.length > 0) {
        data?.forEach((el) => {
          countryDetail.push({ countryCode: el.alpha2Code, name: el.name });
          setCountries(countryDetail);
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    if (window.location.pathname === '/') {
      let ele = document.getElementById(window.location.hash.substring(1))
      if (ele) {
        ele.scrollIntoView({ top: 0, behavior: 'smooth' })
      }
    }
  }, [])
  const originData = async () => {
    const res = await axios.get('https://ipapi.co/json')
    setUserGeoData(res.data)
  }

  useEffect(() => {
    originData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App">
      <UserContext.Provider
        value={{
          token,
          user,
          users,
          countries,
          setToken,
          setUser,
          setUsers,
          setCountries,
          setUserGeoData,
          userGeoData,
          setApplication,
          selectedVisa,
          setSelectedVisa,
          savePercentage,
          setSavePercentage,
          application,
        }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/verifyUser" element={<RegisterOtpVerify />} />
          <Route exact path="/phoneverify" element={<PhoneVerify />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/immigration" element={<Immigration />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/reviews" element={<Home />} />
          <Route exact path="/termconditions" element={<TermConditions />} />
          <Route exact path="/fr/terms" element={<InFrenchTermConditions />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/fr/privacyPolicy" element={<InFrenchPrivacyPolicy />} />
          {/* <Route exact path="/visa/express" element={<Express />} /> */}
          <Route exact path="/visa/worker" element={<WorkerVisa />} />
          <Route exact path="/visa/business" element={<BusinessVisa />} />
          {/* <Route exact path="/visa/tourist" element={<TouristVisa />} /> */}
          <Route exact path="/visa/family" element={<FamilyVisa />} />
          <Route exact path="/visa/holiday" element={<HolidayVisa />} />
          {/* <Route exact path="/visa/temporary" element={<TemporaryVisa />} /> */}
          <Route exact path="/visa/student" element={<StudentVisa />} />
          <Route exact path="/verifyLead/:leadId" element={<OtpVerify />} />
          <Route exact path="/thankyou" element={<ThankYouPage />} />
          <Route exact path="/invoiceReport" element={<InvoiceReport />} />
          <Route
            exact
            path="/visaEvaluation/:caseId"
            element={<VisaEvaluation />}
          />
          {/* <Route exact path="/thankyous" element={<ThankYouScorePage />} /> */}
          <Route
            exact
            path="/worldcard/:checkoutId"
            element={<WorldCardForm />}
          />
          <Route
            exact
            path="/userDetail"
            element={<GetUserDetailForPayment />}
          />
          <Route
            exact
            path="/users"
            element={
              <ProtectedRoute user={user} needRoles={['management', 'admin']}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/application/:formType"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <VisaForm />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/welcome"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Welcome />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/guideline"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Guidelines />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Notification />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/invoices"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Invoices />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/products"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/manualinvoice"
            element={
              <ProtectedRoute user={user} needRoles={['admin']}>
                <ManualInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/allinvoices"
            element={
              <ProtectedRoute user={user} needRoles={['admin']}>
                <AllInvoices />
              </ProtectedRoute>
            }
          />
          <Route exact path="/viewInvoice" element={<InvoiceTemplate />} />
          <Route
            exact
            path="/viewPayments"
            element={
              <ProtectedRoute user={user} needRoles={['admin']}>
                <ViewPayments />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/getDetail"
            element={<GetUserDetailForPayment />}
          />
        </Routes>
        <ToastContainer />
      </UserContext.Provider>
    </div>
  )
}

export default App
