import Layout from '../layout/layout'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Table, Dropdown, Button } from 'react-bootstrap'
import dot from '../../assets/images/header/dot.svg'
import { serverPath } from '../../config/key'
import { useEffect, useState } from 'react'
import UpdateUser from './updateUser'
import { Modal } from 'react-bootstrap'
import './user.css'
import { toast } from 'react-toastify'

const Users = () => {
  //const navigate=useNavigate()
  const [allUsers, setAllUSers] = useState([])
  const [activeId, setActiveId] = useState('')
  const [editPage, setEditPage] = useState(false)
  const [block, setBlock] = useState(false)
  const [blockStatus, setBockStatus] = useState()
  const getUsers = async () => {
    const response = await axios.get(`${serverPath}/users/getAllUsers`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const { data, status } = response || []
    if (status === 200) {
      setAllUSers(data?.users)
      setActiveId('')
    }
  }
  useEffect(() => {
    if (allUsers?.length === 0) {
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleEdit = (id) => {
    setActiveId(id)
    setEditPage(true)
    // navigate(`/users/create-user?id=${id}`);
  }
  const handleClose = () => {
    setEditPage(false)
  }

  const handleBlockShow = (id, status) => {
    setActiveId(id)
    setBlock(true)
    setBockStatus(status)
  }

  const handleBlockClose = () => {
    setBlock(false)
  }
  const handleBlockUser = async () => {
    try {
      const response = await axios.put(
        `${serverPath}/users/blockUsers/${activeId}`,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      const { data } = response
      const { msg, status } = data.upd
      if (status === 200) {
        const response = await axios.get(`${serverPath}/users/getAllUsers`, {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        })
        const { data, status } = response || []
        if (status === 200) {
          setAllUSers(data?.users)
          setBlock(false)
          setActiveId('')
          toast.success(`user ${msg} successfully`)
        }
      }
    } catch (err) {
      console.log('error is here-->', err)
    }
  }
  return (
    <Layout>
      <div className="users-list">
        <div className="container">
          <h3>Users</h3>
          <div className="users-page-content">
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers?.length > 0 &&
                  allUsers.map((el, i) => (
                    <tr>
                      <td>{el.name}</td>
                      <td>{el.email}</td>
                      <td>{el.phoneNumber}</td>
                      <td>
                        {el.isBlock ? (
                          <span className="text-danger">Block</span>
                        ) : (
                          <span className="text-success">Active</span>
                        )}
                      </td>
                      <td>
                        <div className="user_btn_dot">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <img src={dot} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleEdit(el._id)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleBlockShow(el._id, el.isBlock)
                                }
                              >
                                Block
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={editPage}
        onHide={handleClose}
        centered
        className="consultation-popup user-update-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateUser
            activeId={activeId}
            setEditPage={setEditPage}
            setAllUSers={setAllUSers}
          />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={block}
        onHide={handleBlockClose}
        centered
        className="user-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="user-block">
            <h3>
              Are you sure to {blockStatus ? 'active' : 'block'} this user?
            </h3>
            <div className="user-block-btn">
              <Button onClick={handleBlockClose}>No</Button>
              <Button onClick={handleBlockUser}>Yes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default Users
