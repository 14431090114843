import React from 'react'
import Layout from '../layout/layout'
//import { useTranslation } from "react-i18next";
// import parse from 'html-react-parser';
import './legal.css'

const InFrenchTermConditions = () => {
  //const { t } = useTranslation();

  return (
    <Layout>
      <div className="legal-page">
        <div className="container">
          <a className="breadcrum" href="/">
            {"< retour à la page d'accueil"}
          </a>
          <div className="legal-content terms">
            <h2>Conditions D’utilisation</h2>

            <div>
              <p>
                Les présentes Conditions d’utilisation (y compris tout autre
                document auquel il est fait référence ici et qui est réputé en
                faire partie) régissent l’utilisation des sites Web et des
                produits proposés par UIS Australia, ainsi que toute relation
                entre UIS Australia et l’utilisateur (pour une meilleure
                lisibilité, parfois dénommé « vous »). Si vous n’acceptez pas
                tout ou partie des Conditions d’utilisation, vous ne pourrez pas
                utiliser le site Web ou l’un des produits ou services d’UIS
                Australia. L’utilisation de ces Conditions est réputée être une
                renonciation à tous droits ou exigences en vertu de toute loi (y
                compris les règlementations ou la jurisprudence) dans toute
                juridiction qui contredit ces Conditions d’utilisation ou toute
                loi exigeant une signature originale (non électronique) ou la
                livraison ou la conservation de documents non électroniques,
                dans la mesure permise par la loi applicable. Si une limitation
                en vertu d’une loi applicable empêche ou limite votre capacité à
                être lié par les Conditions d’utilisation ou toute partie de
                celles-ci, vous ne pourrez utiliser aucun des sites Web d’UIS
                Australia ou des produits ou services proposés et, en les
                utilisant, vous déclarez et garantissez explicitement par la
                présente que vous vous conformez pleinement à toutes les lois
                applicables (y compris la limitation appliquée à votre capacité
                à conclure des contrats ou à les utiliser en raison de l’âge ou
                de toute autre limitation légale).UIS Australia est une société
                privée et n’est pas, et ne prétend pas être, une agence ou toute
                autre composante du gouvernement Australien ou être autrement
                liée à celui-ci, ni ne prétend être un conseiller juridique ou
                le titulaire d’une licence afin de fournir un conseil légal ou
                tout service similaire lié à l’immigration au Australie ou
                autre.Nous soulignons qu’UIS Australia fournit des données sur
                l’état actuel de la législation Australienne au fur et à mesure
                que nous en avons connaissance et sans en donner aucune
                représentation formelle ou autre. Le gouvernement Australien
                peut à tout moment modifier ou annuler sa législation, son
                interprétation ou ses politiques, y compris en ce qui concerne
                les programmes d’immigration mentionnés sur le site Web ou les
                services ou produits d’UIS Australia.
              </p>

              <h5>1 - Définitions des Conditions d’utilisation</h5>

              <p>
                Dans les Conditions d'utilisation, les définitions en majuscules
                suivantes sont utilisées.
              </p>
              <p>
                1. UIS Australia.com et/ou UIS Australia désignent UIS Australia
                et/ou l'un de ses propriétaires, filiales, divisions affiliées,
                administrateurs, employés, agents, représentants ou concédants
                de licence. Le site Web UIS Australia.com est détenu et exploité
                par UIS Ltd. 5 Secretary’s Lane Gibraltar GX11 1AA Gibraltar.
              </p>

              <p>
                2. Les sites Web de UIS Australia désignent tous les éléments,
                libellés, photos et contenus du site Web disponibles sous le
                domaine : www.UISAustralia.com.
              </p>

              <p>
                3. Les produits proposés ou les produits proposés par UIS
                Australia désignent l'utilisation du logiciel, le cas échéant,
                et l'un des produits et services connexes d'UIS Australia
                disponibles via l'un des sites Web d'UIS Australia, qu'ils
                soient définis comme des services payants, tels que définis dans
                les présentes, ou tout autre produit fourni. par UIS Australia.
              </p>

              <p>
                4. Le logiciel ou le logiciel de UIS Australia désigne le
                logiciel Web propriétaire de UIS Australia.
              </p>

              <p>
                5. La propriété intellectuelle désigne toutes les marques,
                brevets, inventions (brevetables ou non), droits d'auteur,
                droits moraux, conceptions de produits, droits de conception,
                noms commerciaux, noms commerciaux, marques de service, logos,
                noms de service, secrets commerciaux, noms de domaine, droits de
                base de données, et tout autre droit de propriété intellectuelle
                ou de propriété (qu'il soit enregistré ou non), y compris les
                droits sur les logiciels informatiques et tous les
                enregistrements et demandes d'enregistrement de l'un des
                éléments susmentionnés.
              </p>

              <p>
                6. Les conditions d'utilisation ou le contrat d'utilisation
                signifient que ce document peut être renouvelé, modifié et/ou
                amendé de temps à autre.
              </p>

              <h5>2 - Accès et licence</h5>

              <p>
                Tout produit proposé ou site Web d’UIS Australia et toute partie
                de ceux-ci, y compris, sans s’y limiter, les graphismes avec du
                texte, logos, boutons, textes, images, icônes, sons, vidéos,
                noms, code source et toute propriété intellectuelle incluse, y
                compris les brevets, les droits d’auteur, les marques de
                commerce, les titres et toute autre information connexe, sont
                considérés comme des informations exclusives et des secrets
                commerciaux et de la Propriété intellectuelle d’UIS Australia et
                ils sont protégés en tant que tels par les lois applicables
                régissant les présentes Conditions d’utilisation. Vous ne devez
                utiliser aucune information exclusive d’UIS Australia et rien
                dans les présentes Conditions d’utilisation ne doit être
                interprété comme vous permettant de le faire.
              </p>

              <h5>3 - Informations exclusives</h5>
              <p>
                Tout produit proposé ou site Web d’UIS Australia et toute partie
                de ceux-ci, y compris, sans s’y limiter, les graphismes avec du
                texte, logos, boutons, textes, images, icônes, sons, vidéos,
                noms, code source et toute propriété intellectuelle incluse, y
                compris les brevets, les droits d’auteur, les marques de
                commerce, les titres et toute autre information connexe, sont
                considérés comme des informations exclusives et des secrets
                commerciaux et de la Propriété intellectuelle d’UIS Australia et
                ils sont protégés en tant que tels par les lois applicables
                régissant les présentes Conditions d’utilisation. Vous ne devez
                utiliser aucune information exclusive d’UIS Australia et rien
                dans les présentes Conditions d’utilisation ne doit être
                interprété comme vous permettant de le faire.
              </p>

              <h5>4 - Non responsabilité du contenu</h5>
              <p>
                1. Les sites Web et les produits proposés par UIS Australia et
                toutes les informations, contenus, produits et services inclus
                dans ou autrement mis à votre disposition par l’intermédiaire de
                l’un des sites Web ou des produits proposés par UIS Australia
                sont fournis « tels quels ». UIS Australia et tout ou partie de
                ses propriétaires, filiales, divisions, sociétés affiliées,
                dirigeants, administrateurs, employés, agents, représentants,
                fournisseurs ou concédants de licence ne représentent ou
                n’offrent aucune garantie d’aucune sorte, explicite ou
                implicite, quant au fonctionnement de l’un des sites Web d’UIS
                Australia ou des produits proposés ou du Logiciel ou des
                informations, du contenu, des produits ou des services inclus ou
                mis à votre disposition par l’intermédiaire de ces sites Web ou
                produits proposés.
              </p>

              <p>
                2.À cet égard, vous devez savoir que si vous divulguez
                volontairement des informations personnelles (par exemple, nom
                d’utilisateur, adresse de courriel) sur un babillard ou forum
                Internet, dans un forum de discussion ou sur toute autre page
                générée par un utilisateur ou un membre, ces informations
                peuvent être collectées et utilisées par d’autres et peuvent
                entraîner des messages non sollicités provenant d’autres
                parties.
              </p>

              <p>
                3. Les sites Web et les produits proposés peuvent être composés
                de forums de discussion, de babillard ou forum Internet et
                d’autres pages créées par les utilisateurs et les membres qui
                vous permettent, ainsi qu’aux autres utilisateurs et membres, de
                publier des informations, de fournir des commentaires à UIS
                Australia et d’interagir en temps réel.
              </p>

              <p>
                4. Vous reconnaissez qu’UIS Australia et ses affiliés ne
                contrôlent pas les informations disponibles sur les babillards
                ou forums Internet, les forums de discussion et autres pages
                générées par les utilisateurs et les membres et que les
                opinions, conseils, déclarations, services, offres ou autres
                informations ou contenus présentés ou diffusés sur tout
                babillard ou forum Internet, forum de discussion ou sur toute
                autre page générée par un utilisateur ou un membre sont ceux de
                leurs auteurs respectifs, qui sont seuls responsables de leur
                contenu.
              </p>

              {/* <p>
                5. Vous reconnaissez que UIS Australia et ses affiliés ne
                contrôlent pas les informations disponibles sur les tableaux
                d'affichage, les forums de discussion et les autres pages
                générées par les utilisateurs et les membres et que les
                opinions, conseils, déclarations, services, offres ou autres
                informations ou contenus présentés ou diffusés sur tout tableau
                d'affichage, salon de discussion ou toute autre page générée par
                un utilisateur ou un membre sont ceux de leurs auteurs
                respectifs qui sont seuls responsables de leur contenu. UIS
                Australia se réserve le droit, à sa seule discrétion, de
                modifier, de refuser de publier ou de supprimer tout matériel
                soumis ou publié sur les forums de discussion, les babillards
                électroniques ou sur toute autre page générée par les
                utilisateurs ou les membres.
              </p> */}

              <h5>5 - Déclaration de confidentialité</h5>
              <p>
                UIS Australia s’efforce de garantir en tout temps la
                confidentialité et la sécurité de ses sites Web et de ses
                clients.
              </p>

              <p>
                1. Lors de votre inscription, vous donnez votre accord pour
                partager certaines informations privées que nous utilisons dans
                le but de confirmer votre identité et de garantir la sécurité de
                vos dépôts. Ces informations sont collectées conformément à nos
                procédures de contrôle rigoureuses, utilisées pour décourager
                les opérations de blanchiment d’argent et garantir la légalité.
              </p>

              <p>
                2. Vous vous engagez à nous fournir des informations vraies,
                mises à jour et exactes sur votre identité.
              </p>

              <p>
                3. Nos procédures de collecte de données comprennent la collecte
                d’informations librement divulguées par le client et nous
                pouvons effectuer des recherches indépendantes et ajouter des
                cookies afin de collecter des données sur la manière dont vous
                interagissez avec nous sur notre site Web. La collecte
                d’informations peut également être utilisée pour vous proposer
                des suggestions de produits supplémentaires.
              </p>

              <p>
                4. Nous ne divulguerons jamais d'informations privées ou
                autrement confidentielles vous concernant à des tiers sans votre
                consentement exprès (et l'achat de services supplémentaires sera
                considéré comme un consentement au transfert des données
                requises à tout tiers vous fournissant ces services), sauf
                lorsque la divulgation est une exigence en vertu de loi ou est
                autrement nécessaire afin d'effectuer une analyse de
                vérification de votre identité.
              </p>

              {/* <p>
                5. Vous reconnaissez et acceptez que tous les Utilisateurs
                liront et comprendront Notre Politique de Confidentialité avant
                d'accéder ou d'utiliser les Services. En utilisant les Services,
                vous reconnaissez que Vos Données seront traitées conformément à
                Notre Politique de confidentialité et au présent Contrat et
                pourront être traitées dans un pays où elles ont été collectées
                ou dans tout autre pays où la société opère. En utilisant les
                Services ou en soumettant Vos Données via les Services, vous
                consentez expressément à ces processus. Dans la mesure où vous
                fournissez des informations personnelles sur une personne ou une
                entité nommée qui n'est pas un utilisateur actuel de nos
                services, vous déclarez que vous avez le consentement de cette
                personne ou entité pour le faire.
              </p> */}

              <h5>6 - Lawful purposes</h5>
              <p>
                1. Vous acceptez et reconnaissez que vous utilisez l’un des
                sites Web d’UIS Australia ou des produits proposés ou du
                Logiciel uniquement à des fins légales. À cet égard, il ne vous
                est pas permis de, sans s’y limiter (a) intercepter ou
                surveiller, endommager ou modifier toute communication qui ne
                vous est pas destinée, (b) utiliser tout type de logiciel
                espion, virus, ver, cheval de Troie, bombe logique ou tout autre
                code ou instruction conçu pour déformer, supprimer, endommager
                ou démanteler l’un des sites Web ou produits proposés ou du
                Logiciel ou la communication d’UIS Australia, (c) envoyer toute
                communication commerciale non sollicitée et non autorisée par la
                loi applicable, ou (d) exposer tout autre utilisateur à du
                contenu offensant, nuisible aux mineurs, indécent, illégal,
                menaçant, abusif, harcelant, diffamatoire, vulgaire, obscène,
                profane, haineux, tout contenu de toute nature à caractère
                racialement, ethniquement ou autrement répréhensible, y compris,
                mais sans s’y limiter, tout contenu qui encourage une conduite
                qui constituerait une infraction pénale, violerait les droits
                d’autrui, ou autrement violerait toute loi locale, provinciale,
                étatique, nationale ou internationale applicable ou autrement
                répréhensible.
              </p>

              <p>
                2. En outre, vous déclarez que la source des fonds auxquels vous
                avez recours pour utiliser les produits proposés et / ou
                utilisés via les sites Web n’est pas illégale et que vous
                n’utiliserez pas les produits proposés et / ou le Logiciel et /
                ou les sites Web de quelque manière que ce soit comme un système
                de transfert d’argent. Vous n’utiliserez pas les produits
                proposés et / ou le Logiciel et / ou les sites Web pour toute
                activité illégale ou frauduleuse ou transaction interdite (y
                compris le blanchiment d’argent) en vertu de toute loi.
              </p>

              <p>
                3. Vous serez tenu responsable, indemniserez et dégagerez UIS
                Australia de toute responsabilité contre toute plainte découlant
                de tout contenu que vous publiez ou transmettez.
              </p>

              <h5>7 - Modifications et mises à jour</h5>
              <p>
                1. UIS Australia a le droit à tout moment et pour quelque raison
                que ce soit de modifier ou d’interrompre tout aspect ou
                fonctionnalité de l’un des sites Web, des produits proposés et /
                ou du Logiciel par UIS Australia, y compris, mais sans s’y
                limiter, son contenu, ses fonctionnalités, sa disponibilité ou
                ses prix.
              </p>

              <p>
                2. Sans porter atteinte à ce qui précède, UIS Australia, à sa
                seule discrétion, se réserve le droit d’ajouter des
                fonctionnalités ou des fonctions supplémentaires, ou de
                soustraire ou de modifier celles-ci, ou de fournir des
                correctifs de programmation, des mises à jour et des mises à
                niveau, à l’un des sites Web, produits proposés et / ou du
                Logiciel par UIS Australia. Vous reconnaissez et acceptez qu’UIS
                Australia n’a aucune responsabilité de mettre à votre
                disposition les versions ultérieures ou antérieures de l’un des
                sites Web, des produits proposés et / ou du Logiciel par UIS
                Australia.
              </p>

              <h5>8 - Services payants</h5>
              <p>
                Vous reconnaissez et acceptez qu’UIS Australia est une
                entreprise privée et que tous les frais que vous payez sont dus
                pour la gestion, le stockage et le traitement de vos
                informations et documents, et sont conçus pour couvrir les coûts
                et le temps consacré par UIS Australia. Le paiement de tous les
                frais et charges à UIS Australia, s’il y a lieu, doit être
                effectué au moyen d’une carte de crédit ou de paiement valide et
                / ou d’autres modes de paiement acceptés tels que désignés sur
                les sites Web (ci-après les « Services payants »). Ces Services
                payants sont spécifiés sur les sites Web d’UIS Australia. Vous
                reconnaissez qu’UIS Australia peut, à sa seule discrétion et
                sans préavis, conditionner l’utilisation (de tous les
                utilisateurs ou d’un groupe d’utilisateurs ou de votre
                utilisation en particulier) de tout ou partie de ses sites Web
                ou produits proposés, pour inclure les paiements, pour exclure
                les paiements, changer ou modifier ces Services payants,
                transformer les Services ou sites Web non payants (ou une partie
                de ceux-ci)
              </p>
              <br />
              <br />

              <p>
                en Services payants, et vice versa. Toutes les conditions des
                Conditions d’utilisation, y compris, sans s’y limiter, les
                limitations ou exclusions de responsabilité ou de garantie,
                s’appliquent aux Services payants, sauf indication contraire
                explicite dans les Conditions d’utilisation ou dans des
                conditions supplémentaires. et les conditions portées à votre
                attention avant de tels paiements. Sous réserve des modalités et
                conditions des Conditions d’utilisation incluant, sans s’y
                limiter, le paiement des frais, un utilisateur peut utiliser le
                Logiciel pour vérifier son éligibilité aux différentes options
                d’immigration proposées au Australie. Les produits proposés et /
                ou du Logiciel ne peuvent être utilisés que pour l’usage auquel
                ils sont destinés et ne peuvent pas être utilisés pour préparer
                des formulaires d’immigration, des pièces justificatives ou des
                lettres sur une base professionnelle.
              </p>

              <h5>9 - Transactions, paiement frauduleux et recouvrement</h5>
              <p>
                1. En cas de problème quel que soit le sujet, y compris le
                remboursement financier, n’hésitez pas à contacter le service à
                la clientèle d’UIS Australia. En cas de résolution unilatérale
                du problème par le client par l’annulation de la transaction par
                la banque, UIS Australia se réserve le droit de contacter la
                banque et de soumettre une lettre de deuxième présentation afin
                de réclamer le remboursement. UIS Australia peut, à sa seule
                discrétion, cesser de fournir les produits proposés et / ou le
                Logiciel à certains utilisateurs ou aux utilisateurs payant avec
                certaines cartes de crédit.
              </p>

              <p>
                2. UIS Australia se réserve le droit d’effectuer des contrôles
                de crédit sur tous les utilisateurs auprès d’agences de crédit
                tierces, sur la base des informations qui nous ont été fournies
                lors de la demande ou par vous de toute autre manière.
              </p>

              <p>
                3. UIS Australia se réserve le droit d’utiliser des prestataires
                de paiement électronique tiers et / ou des institutions
                financières pour traiter les paiements que vous effectuez dans
                le cadre de votre utilisation des produits proposés et / ou du
                Logiciel.
              </p>

              <p>
                4. Dans tous les cas de paiement suspect ou frauduleux, y
                compris l’utilisation de cartes de crédit volées, ou toute autre
                activité frauduleuse (y compris toute rétrofacturation ou autre
                annulation d’un paiement), UIS Australia se réserve le droit de
                bloquer le compte d’un utilisateur et d’annuler tout
                remboursement effectué, le cas échéant. UIS Australia a le droit
                d’informer toutes les autorités ou entités pertinentes (y
                compris les agences d’évaluation du crédit) de toute fraude au
                paiement ou activité illégale, et peut recourir à des services
                de recouvrement pour recouvrer les paiements.
              </p>

              <p>
                5. Toute communication ou tout contenu que vous affichez ou
                transmettez par ou sur l’un des sites Web d’UIS Australia ou par
                l’utilisation des produits proposés et / ou du Logiciel sera
                traité comme non confidentiel et non exclusif. Vous assumez
                l’entière responsabilité de tout ce que vous publiez ou
                transmettez. Si vous vous opposez à ce que vos informations
                soient transférées ou utilisées de cette manière, veuillez ne
                pas utiliser les sites Web ou les produits proposés par UIS
                Australia.
              </p>

              <h5>10 - Absence de garantie</h5>
              <p>
                1. UIS Australia ne peut garantir que vous serez toujours en
                mesure de communiquer en utilisant l’un des sites Web ou les
                produits proposés ou du Logiciel par UIS Australia. UIS
                Australia ne peut non plus vous garantir que vous pourrez
                communiquer sans interruptions, retards ou défauts de
                communication ou que toutes vos communications seront toujours
                livrées. UIS Australia ne sera pas responsable de telles
                interruptions, retards ou autres omissions dans toute
                communication rencontrée lors de l’utilisation du Logiciel, des
                sites Web et des produits proposés, même si cela a été encouru
                par ou dû à UIS Australia.
              </p>

              <p>
                2. UIS Australia ne sera pas tenu responsable des perturbations
                ou des dysfonctionnements que l’un des sites Web ou les produits
                proposés ou le Logiciel par UIS Australia pourraient causer à
                tout autre logiciel ou application, ou à tout système matériel
                informatique ou à toute partie de celui-ci, y compris toute
                information ou les données qui s’y trouvent.
              </p>

              <p>
                3. Les produits et sites Web proposés par UIS Australia peuvent
                inclure une version préliminaire « bêta » de ces sites Web et
                des produits proposés qui peuvent être incomplets et peuvent
                contenir des inexactitudes ou des erreurs susceptibles de
                provoquer des pannes ou des pertes de données.
              </p>

              <p>
                4.UIS Australia peut suspendre tout site Web d’UIS Australia ou
                le Logiciel ou tout produit proposé, y compris les services
                payants, ou toute partie de ceux-ci, pour des opérations de
                maintenance, mises à jour ou pour toute autre raison, ou
                clôturer l’un de ces sites Web ou supprimer le Logiciel ou les
                produits proposés, à sa seule discrétion et sans préavis. UIS
                Australia ne sera pas responsable en ce qui concerne ou en
                relation avec une telle suspension ou arrêt d’activité.
              </p>

              <h5>11 - Absence de garantie de l’acceptation du visa</h5>
              <p>
                1. Vous comprenez que les programmes de visa Australien
                constituent une procédure d’évaluation régie par l’autorité
                Australienne et que, par conséquent, il ne peut y avoir aucune
                garantie qu’un visa sera accordé.
              </p>

              <p>
                2. UIS Australia n’est impliqué d’aucune façon dans la
                délivrance de visas d’immigration.
              </p>

              <p>
                3. Les exigences minimales d’admissibilité ont été détaillées et
                répertoriées sur les sites Web d’UIS Australia. Il vous
                appartient de consulter les exigences et de vous assurer que
                vous vous qualifiez conformément aux directives. Si vous avez
                des questions, veuillez consulter le site Web :
                uisAustralia.com.
              </p>

              <p>4. Représentants autorisés</p>
              <p>
                4.1. Vous reconnaissez qu’UIS Australia, à sa seule discrétion,
                peut accorder un accès limité à ses sites Web et / ou serveurs,
                à des consultants en immigration Australiens tiers ou à des
                avocats qui sont des représentants autorisés en vertu de la loi
                Australienne pour fournir des services en lien avec
                l’immigration Australienne moyennant des frais. L’octroi d’un
                tel accès aux sites Web et / ou aux serveurs d’UIS Australia
                n’établit pas de relation consultant-client ou avocat-client
                entre vous et les représentants autorisés.
              </p>

              <p>
                4.2. Vous reconnaissez et autorisez l’accès des représentants
                autorisés et des personnes qu’ils ont désignées aux
                renseignements personnels et aux documents téléchargés par
                l’utilisateur sur les sites Web et / ou serveurs de l’UIS
                Australia. Les représentants autorisés, en accord avec UIS
                Australia, peuvent évaluer les qualifications de l’utilisateur
                pour un visa d’immigration Australien. Ces évaluations sont
                effectuées sur le serveur et elles sont faites sur une base «
                tel quel ». Ces évaluations préliminaires de l’admissibilité ne
                constituent pas un conseil personne sur l’immigration et ne
                garantissent pas la délivrance d’un visa d’immigration ou d’un
                autre document.
              </p>

              <p>
                4.3. Les utilisateurs / clients qualifiés à la recherche de
                services qui entrent dans le champ d’application de l’article
                A91 de la Loi sur l’immigration et la protection des réfugiés du
                Australie devront signer un mandat de représentation personnel
                avec un représentant autorisé.
              </p>

              <h5>12 - Frais et politique de remboursement</h5>
              <p>
                1. Nous sommes une entreprise privée. Les frais que vous payez
                seront facturés afin de couvrir nos coûts et le temps requis. Si
                vous choisissez de payer nos frais par carte de crédit, votre
                carte sera débitée immédiatement lors de l’achat, même si nous
                ne commençons pas l’étude de votre dossier immédiatement.
              </p>

              <p>
                2. Nous ne garantissons aucun résultat et aucun remboursement ne
                sera effectué si le résultat escompté n’est pas atteint. Nous
                traitons tous les dossiers de nos clients avec le plus grand
                sérieux et nous ferons de notre mieux pour garantir le succès de
                la demande. Il faut comprendre que nos clients nous paient pour
                notre expertise, notre travail et le temps que nous consacrons à
                un dossier et, bien que nous nous efforcerions toujours
                d’obtenir un résultat positif, nous ne garantissons pas le
                succès de tout dossier pour lequel nous sommes sollicités, et
                nous n’effectuons pas de remboursement en cas d’échec d’une
                demande.
              </p>

              <p>
                3. Les remboursements seront accordés à la seule discrétion
                d’UIS Australia et, en tout état de cause, ne dépasseront pas le
                montant que vous avez réellement payé pour le crédit restant dû
                en lien avec l’un des Produits payants concernés. Sans déroger à
                aucune disposition des Conditions d’utilisation, les frais payés
                ne sont pas récupérables, si UIS Australia soupçonne, à sa seule
                discrétion, que vous enfreignez les Conditions d’utilisation, y
                compris, sans s’y limiter, la création de problèmes avec l’un
                des sites Web ou des produits proposés par UIS Australia ou
                l’utilisation de ceux-ci, d’éventuelles responsabilités légales,
                toute infraction avec les droits de propriété intellectuelle
                d’autrui, en se livrant à des activités frauduleuses, immorales
                ou illégales, ou pour d’autres raisons similaires, ou si la
                soumission de la demande est rendue impossible par des
                événements indépendants de la volonté d’UIS Australia (par
                exemple, y compris, mais sans s’y limiter, les actes de guerre,
                les catastrophes naturelles, les modifications dans les
                instructions du programme de visa telles que l’admissibilité,
                les informations requises ou d’autres modifications, les
                limitations technologiques, les ressources économiques, les
                modifications législatives ou procédurales et / ou l’action de
                l’État). En visitant ou en utilisant l’un des sites Web ou des
                produits proposés ou le Logiciel par UIS Australia, vous
                comprenez et acceptez la Politique de remboursement spécifiée
                dans la présente.
              </p>

              <p>
                4. Le remboursement peut être demandé en nous contactant par
                téléphone ou par courriel. Notre direction examinera votre
                demande et prendra ensuite sa décision. Nous nous efforçons de
                répondre à toutes les demandes dans un délai de 3 à 4 jours
                ouvrables. Les frais d’inscription ne sont pas remboursables si
                vous avez déjà soumis, ou commencé à compléter ou soumettre, au
                moins une demande, y compris dans le cas où une demande n’a pas
                été soumise parce qu’il manquait des informations. Dans
                l’éventualité où un remboursement est envisagé, UIS Australia
                peut, à sa seule discrétion, accepter de rembourser les frais
                initiaux payés sous réserve d’une déduction pour le temps
                consacré au dossier au taux horaire de 400 USD.
              </p>

              <p>
                5. Tout remboursement doit être effectué en utilisant uniquement
                et exclusivement le même mode de paiement avec lequel vous avez
                effectué vos paiements à UIS Australia.
              </p>

              <h5>13 - Identifiant d’utilisateur et mot de passe</h5>
              <p>
                Vous êtes la seule personne autorisée à utiliser votre
                identifiant d’utilisateur et votre mot de passe, et il ne vous
                est pas autorisé de permettre ou d’autoriser d’autres personnes
                à y avoir accès ou de les utiliser. Vous êtes responsable de
                l’utilisation de tout site Web d’UIS Australia ou des produits
                proposés sous votre numéro d’identifiant d’utilisateur et du
                maintien de la confidentialité de votre identifiant
                d’utilisateur et de votre mot de passe. Pour des raisons de
                sécurité, sauf indication contraire, UIS Australia ne divulguera
                pas votre identifiant d’utilisateur ou votre mot de passe si
                vous les perdez ou les oubliez autrement qu’en les envoyant à
                votre adresse de courriel fournie lors de l’inscription.
              </p>

              <h5>14 - Informations confidentielles</h5>
              <p>
                À l’exception des numéros de carte de crédit et d’autres
                informations personnelles que vous devez saisir dans les
                formulaires dans le cadre de l’utilisation des sites Web et / ou
                des produits proposés, UIS Australia ne souhaite pas recevoir
                d’informations confidentielles ou exclusives de votre part via
                le Site. Toute information ou tout contenu non personnel envoyé
                à UIS Australia sera considéré comme NON confidentiel. En
                envoyant à UIS Australia toute information ou tout contenu, vous
                accordez à UIS Australia une licence illimitée et irrévocable
                pour utiliser, reproduire, afficher, exécuter, modifier,
                transmettre et distribuer ces contenus ou informations, et vous
                acceptez également qu’UIS Australia soit libre d’utiliser toutes
                les idées, concepts, savoir-faire ou techniques que vous nous
                transmettez à quelque fin que ce soit.
              </p>

              <h5>15 - Utilisation des informations</h5>
              <p>
                Nonobstant la section Informations confidentielles ci-dessus,
                lors de votre inscription, vous autorisez explicitement UIS
                Australia à transmettre par Internet, téléphone, télécopieur,
                courriel ou tout autre moyen de communication, du contenu
                commercial, promotionnel et des publicités de tout type à sa
                seule discrétion, que ce soit ceux d’UIS Australia ou ceux d’un
                tiers. Si vous souhaitez cesser de recevoir ce contenu et cette
                publicité, vous pouvez le faire en notifiant UIS Australia.
              </p>

              <h5>16 - Sécurité</h5>
              <p>
                UIS Australia et le Site prennent toutes les précautions
                nécessaires afin de protéger les informations des utilisateurs.
                Lorsque les utilisateurs soumettent des informations sensibles
                via le Site, les informations sont protégées à la fois en ligne
                et hors ligne. Lorsque les utilisateurs sont invités à saisir
                des informations sensibles (telles qu’un numéro de carte de
                crédit), ces informations sont chiffrées et protégées par le
                logiciel de cryptage SSL. Sur une page sécurisée, telle que le
                formulaire de paiement, l’icône de cadenas ouvert dans la barre
                des navigateurs Web tels que Microsoft Internet Explorer se
                ferme. Bien que nous ayons pris des mesures conséquentes pour
                garantir la sécurité des informations soumises lors de
                l’utilisation des produits proposés et du Logiciel, UIS
                Australia et le Site ne peuvent garantir la sécurité des
                informations collectées lors de votre utilisation des produits
                proposés et du Logiciel et ils ne seront en aucun cas
                responsables de la compromission de vos données.
              </p>
              <br />
              <br />

              <p>
                Si vous vous opposez à ce que vos informations soient
                transférées ou traitées de cette manière, veuillez ne pas vous
                inscrire.
              </p>

              <h5>17 - Annulation et résiliation</h5>
              <p>
                1. UIS Australia s’efforcera de fournir le meilleur service
                possible en temps opportun et comme indiqué. Cependant, notre
                capacité à mener à bien ces tâches dépend de votre capacité à
                fournir les contenus requis en temps opportun, de tiers et de
                circonstances indépendantes de notre volonté. Bien que la durée
                de la consommation ne soit mesurée qu’à partir du moment où les
                contenus requis sont présents chez UIS Australia, vous pouvez à
                tout moment et à votre seule discrétion annuler la relation
                entre vous et UIS Australia.
              </p>

              <p>
                2. Tous les sites Web d’UIS Australia ou services proposés ou le
                Logiciel sont fournis « tels quels », sans aucune garantie. UIS
                Australia et aucun de ses propriétaires, filiales, divisions,
                sociétés affiliées, dirigeants, administrateurs, employés,
                agents, représentants, prestataires ou concédants de licence ne
                font, ni de manière expresse, implicite ou statutaire, aucune
                garantie, revendication ou représentation à l’égard de l’un des
                sites Web d’UIS Australia ou des services proposés ou du
                Logiciel, y compris, sans s’y limiter, les garanties de qualité,
                de performance, de non-contrefaçon, de qualité marchande,
                d’adéquation à l’utilisation ou à un usage particulier. UIS
                Australia et aucun de ses propriétaires, filiales, divisions,
                sociétés affiliées, dirigeants, administrateurs, employés,
                agents, représentants, prestataires ou concédants de licence ne
                déclarent ni ne garantissent en outre que les sites Web d’UIS
                Australia ou les services proposés ou le Logiciel seront
                toujours disponibles, accessibles, ininterrompus, opportuns,
                sécurisés, précis, complets et sans erreur ou fonctionneront
                sans perte de paquets, ni qu’UIS Australia et aucun de ses
                propriétaires, filiales, divisions, sociétés affiliées,
                dirigeants, administrateurs, employés, agents, représentants,
                prestataires ou concédants de licence ne garantissent le succès
                d’une procédure de demande de visa ou son acceptation via un des
                sites Web ou des services proposés ou du Logiciel d’UIS
                Australia.
              </p>

              <p>
                3. Les sites Web d’UIS Australia peuvent vous permettre
                d’accéder à des sites Web de tiers. Votre accès et votre
                utilisation de tout site Web tiers, y compris les biens,
                services ou informations mis à disposition à partir de ces sites
                Web, sont régis par les Conditions d’utilisation figurant sur
                chaque site Web tiers, le cas échéant.
              </p>

              <p>
                4. UIS Australia ne sera pas responsable de tout dommage causé
                par ou découlant de ou en relation avec l’annulation, la
                résiliation, la suspension ou la restriction telles qu’indiquées
                ci-dessus, quelle qu’en soit la raison.
              </p>

              <h5>18 - Violation</h5>
              <p>
                Sans déroger ou limiter nos autres recours, UIS Australia aura
                le droit de résilier immédiatement votre accès ou votre
                utilisation des produits proposés et / ou du Logiciel en cas
                d’activités qui enfreignent les Conditions d’utilisation ou la
                conduite qui, selon le jugement d’UIS Australia, interfère avec
                le fonctionnement ou l’utilisation des produits proposés et / ou
                du Logiciel.
              </p>

              <h5>19 - Exclusion de guaranties</h5>
              <p>
                1.Les garanties incluses dans les Conditions d’utilisation
                constitueront les seules garanties d’UIS Australia et de ses
                propriétaires, filiales, divisions, sociétés affiliées,
                dirigeants, administrateurs, employés, agents, représentants,
                prestataires ou concédants de licence. Toute garantie, expresse
                ou implicite, y compris, sans s’y limiter, toute garantie de
                qualité marchande ou d’adéquation à un usage particulier ou la
                légalité de l’un des sites Web d’UIS Australia ou des produits
                proposés ou du Logiciel, non expressément accordée par UIS
                Australia, est rejetée par la présente.
              </p>

              <p>
                2. Sans limitation à d’autres recours, UIS Australia peut
                limiter, suspendre ou mettre fin à votre utilisation des sites
                Web d’UIS Australia ou du Logiciel ou des produits proposés (y
                compris les Services payants) et supprimer votre compte
                d’utilisateur et / ou identifiant d’utilisateur, ainsi que
                l’utilisation de tout contenu, inclus ou non, à tout moment,
                avec ou sans cause et sans aucun recours, avec effet immédiat et
                sans préavis.
              </p>

              <p>
                3. UIS Australia ne sera pas responsable de tout dommage causé
                par ou découlant de ou en relation avec l’annulation, la
                résiliation, la suspension ou la restriction telles qu’indiquées
                ci-dessus, quelle qu’en soit la raison.
              </p>

              <h5>20 - Restrictions légales</h5>
              <p>
                UIS Australia n’assume aucune responsabilité quant à la légalité
                de toute utilisation du site Web ou de toute autre activité que
                vous choisissez de faire, en vertu de toute loi qui vous est
                applicable. La capacité d’accès à notre site Web ne signifie pas
                nécessairement que nos services ou vos activités sont légaux en
                vertu des lois, règlements ou directives qui vous sont
                applicables. Il est de votre responsabilité de vous en assurer.
              </p>

              <h5>21 - Limitation de responsabilité</h5>
              <p>
                1. Vous acceptez et reconnaissez qu’UIS Australia, ses
                propriétaires, filiales, divisions, sociétés affiliées,
                dirigeants, administrateurs, employés, agents, représentants,
                prestataires ou concédants de licence n’auront aucune
                responsabilité en rapport avec ou découlant de votre utilisation
                ou de votre visite de l’un des sites Web d’UIS Australia ou des
                services proposés ou du Logiciel.
              </p>

              <p>
                2. En aucun cas, UIS Australia, ses propriétaires, filiales,
                divisions, sociétés affiliées, dirigeants, administrateurs,
                employés, agents, représentants, prestataires ou concédants de
                licence ne pourront être tenus responsables, que ce soit par
                contrat, garantie, délit (y compris la négligence),
                responsabilité du produit ou toute autre forme de
                responsabilité, pour toutes blessures, dommages indirects,
                accessoires, spéciaux ou consécutifs (y compris, sans s’y
                limiter, toute perte de données, interruption, panne
                d’ordinateur ou perte pécuniaire) découlant de la visite ou de
                l’utilisation de l’un des sites Web d’UIS Australia ou des
                services proposés ou du Logiciel ou de l’incapacité de
                l’utiliser, même si UIS Australia, ses propriétaires, filiales,
                divisions, sociétés affiliées, dirigeants, administrateurs,
                employés, agents, représentants, prestataires ou concédants de
                licence ont été informés de la possibilité de tels dommages.
              </p>

              <p>
                3. Votre seul droit ou recours en ce qui concerne tout dommage,
                problème ou insatisfaction en relation avec l’un des sites Web
                ou les services proposés ou le Logiciel d’UIS Australia ou
                l’utilisation ou la visite de ceux-ci est de cesser d’utiliser
                ces sites Web ou les services proposés ou le Logiciel.
              </p>

              <p>
                4. Si, nonobstant les autres dispositions des présentes
                Conditions d’utilisation, UIS Australia est jugée responsable
                envers vous de tout dommage ou perte découlant de ou lié de
                quelque manière que ce soit à l’utilisation de ce Site ou des
                produits proposés ou du Logiciel, la responsabilité d’UIS
                Australia ne peut en aucun cas dépasser le plus faible des
                montants suivants : (a) le montant que vous avez payé pour le
                Service payant ou (b) 50,00 $ (dollar Australien). Étant donné
                que certaines juridictions n’autorisent pas certaines des
                exclusions ou limitations énoncées ci-dessus, certaines de ces
                exclusions ou limitations peuvent ne pas s’appliquer à vous.
                Dans un tel cas, la responsabilité sera limitée dans la mesure
                du possible légalement en vertu de la législation applicable.
              </p>

              <p>
                5. Dans tous les cas, toutes les garanties données ou faites par
                UIS Australia à l’égard du produit offert (1) sont à l’avantage
                uniquement des utilisateurs enregistrés du produit proposé qui
                se conforment aux dispositions des Conditions d’utilisation et
                ne sont pas transférables, et (2) seront nulles et non avenues
                si vous enfreignez les dispositions des Conditions
                d’utilisation.
              </p>

              <h5>22 - Indemnisation</h5>
              <p>
                1.Vous acceptez d’indemniser, de défendre et de dégager de toute
                responsabilité UIS Australia, ainsi que ses entités juridiques
                affiliées, dirigeants, employés, administrateurs, propriétaires,
                sociétés affiliées, divisions, filiales, agents, prestataires,
                représentants ou concédants de licence pour toutes demandes,
                dépenses, dommages, pertes ou coûts, y compris les honoraires
                d’avocat raisonnables, qui peuvent être formulés par un tiers
                contre UIS Australia, en raison de, découlant de ou en relation
                avec vos visites ou l’utilisation des sites Web, des produits
                proposés ou du Logiciel d’UIS Australia.
              </p>

              <p>
                2. Vous acceptez de défendre, de dégager de toute responsabilité
                et d’indemniser UIS Australia contre toutes les responsabilités,
                dommages, actions, coûts, dépenses, y compris, sans s’y limiter,
                les honoraires d’avocat, résultant de ou découlant de toutes les
                réclamations de tiers, y compris, sans s’y limiter, les vôtres.
              </p>

              <p>
                3. Si des juridictions n’autorisent pas certaines des exclusions
                ou limitations énoncées ci-dessus, certaines de ces exclusions
                ou limitations peuvent ne pas s’appliquer à vous. Dans de tels
                cas, il y aura une limitation de responsabilité dans une mesure
                telle que légalement possible avec la législation applicable.
              </p>

              <h5>23 - Règlements et lois sur l’immigration</h5>
              <p>
                Les lois et réglementations en matière d’immigration changent
                fréquemment et leur application peut varier considérablement en
                fonction des faits et des circonstances spécifiques impliqués.
                Vous êtes responsable de consulter vos propres conseillers
                professionnels en immigration concernant votre situation
                particulière. UIS Australia décline toute responsabilité quant à
                l’exactitude ou la pertinence de toute position que vous
                pourriez prendre dans vos demandes.
              </p>

              <h5>24 - Loi applicable</h5>
              <p>
                Résolution des litiges. Vous acceptez que toutes les questions
                relatives à votre accès ou à votre utilisation des sites Web,
                des produits proposés et / ou du Logiciel, y compris tous les
                litiges, seront régies par les lois de la Bulgarie sans égard
                aux dispositions sur le conflit des lois. Vous acceptez que le
                lieu de la juridiction personnelle soit située en Bulgarie et
                vous renoncez à toute objection à cette juridiction ou lieu.
                Toute plainte en vertu du Contrat d’utilisation doit être
                déposée dans les six (6) mois suivant l’apparition de la cause
                de l’action, sinon cette plainte ou cause de l’action sera
                exclue. En cas de controverse ou de différend entre UIS
                Australia et vous découlant de ou au sujet de votre utilisation
                du Site, des produits proposés et / ou du Logiciel, les parties
                tenteront, rapidement et de bonne foi, de résoudre ce différend.
                Si les parties ne sont pas en mesure de résoudre ce différend
                dans un délai raisonnable (ne dépassant pas trente (30) jours),
                l’une ou l’autre des parties peut soumettre ce différend ou ce
                désaccord à la médiation. Si le différend ne peut être résolu
                par la médiation, les parties sont libres d’exercer tout droit
                ou recours dont elles disposent en vertu de la loi applicable.
              </p>

              <h5>25 - Divers</h5>
              <p>
                1. Les Conditions d’utilisation énoncent l’entière
                responsabilité d’UIS Australia et votre recours exclusif en ce
                qui concerne toute utilisation ou tout accès à l’un des sites
                Web d’UIS Australia ou des produits proposés ou du Logiciel, et
                constituent un énoncé complet de l’accord entre vous et UIS
                Australia.
              </p>

              <p>
                2. Les titres sont donnés à titre indicatif uniquement et ne
                définissent, ne limitent, n’interprètent ou ne décrivent en
                aucun cas la portée ou l’étendue d’une telle section. Notre
                incapacité à agir à l’égard d’une violation par vous ou par
                d’autres ne nous exempte pas de notre droit d’agir en ce qui
                concerne des violations ultérieures ou similaires.
              </p>

              <p>
                3. Les Conditions d’utilisation ne limitent aucun des droits
                qu’UIS Australia peut avoir en vertu des lois sur les secrets
                commerciaux, les droits d’auteur, les brevets ou d’autres lois.
                Les employés d’UIS Australia ne sont pas autorisés à apporter
                des modifications aux Conditions d’utilisation ou à s’engager
                dans des déclarations, engagements ou garanties supplémentaires
                liant UIS Australia, à l’exception de ceux faits par écrit et
                signés par un agent autorisé d’UIS Australia. Si une disposition
                des Conditions d’utilisation est invalide ou inapplicable en
                vertu de la loi applicable, elle sera, dans la mesure du
                possible, réputée omise et les dispositions restantes resteront
                pleinement en vigueur.
              </p>

              <p>
                4. UIS Australia peut, à sa seule discrétion, modifier les
                Conditions d’utilisation à tout moment en affichant les
                Conditions d’utilisation modifiées sur les sites Web concernés
                d’UIS Australia. Toutes les Conditions d’utilisation modifiées
                régiront les inscriptions des nouveaux utilisateurs à compter de
                la date à laquelle elles sont publiées sur ces sites Web. Les
                utilisateurs existants seront liés par les Conditions
                d’utilisation modifiées après l’expiration des 14 jours suivant
                la date de la notification et votre utilisation continue du
                Logiciel après cette date signifie que vous acceptez les
                modifications. Aucune autre modification des Conditions
                d’utilisation ne sera effective.
              </p>

              <p>
                5. Les Conditions d’utilisation et tous les droits et licences
                accordés en vertu des présentes ne peuvent être transférés ou
                cédés par vous, mais peuvent être cédés par UIS Australia sans
                restriction et sans préavis.
              </p>
            </div>
          </div>
          CES CONDITIONS D’UTILISATION ONT ÉTÉ ÉLABORÉES À L’ORIGINE EN ANGLAIS.
          EN CAS DE DIFFÉRENCES ENTRE LA VERSION ANGLAISE ET UNE VERSION
          TRADUITE, LA VERSION ANGLAISE PRÉVAUT.
          <br />
          <br />
          VOUS RECONNAISSEZ EXPRESSÉMENT QUE VOUS AVEZ LU LES CONDITIONS
          D’UTILISATION ET QUE VOUS COMPRENEZ LES DROITS, OBLIGATIONS, TERMES ET
          CONDITIONS DÉFINIS DANS LES PRÉSENTES. EN UTILISANT LES SITES WEB OU
          LES SERVICES PROPOSÉS OU LE LOGICIEL D’UIS Australia, VOUS CONSENTEZ
          EXPRESSÉMENT À ÊTRE LIÉ PAR SES CONDITIONS ET À ACCORDER À UIS
          Australia LES DROITS ÉNONCÉS DANS LES CONDITIONS D’UTILISATION.
        </div>
      </div>
    </Layout>
  )
}

export default InFrenchTermConditions
