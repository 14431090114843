/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import DocumentPopup from './ducumentPopup'
import GeneralInfo from './generalInfo'
import LetterClearanceInfo from './letterClearance'
import { Button } from 'react-bootstrap'
import EmployeementFinancialDoc from './employementFinancialDoc'
import SponsorshipDocument from './sponsorshipDocument'
import { toast } from 'react-toastify'
import GeneralSpouseInfo from './generalSpouseDoc'
import DependentSpouseInfo from './dependentInfo'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
// import SponsoredGeneralInfo from './sponsoredGeneralInfo'
// import SponsoredLetterClearanceInfo from './sponsoredLetterClearance'
// import SponsoredEmpFinancialDocument from './sponsoredEmpFinancialDocument'
// import SponsoredSponsorshipDocument from './sponsoredSponsorshipDoc'
// import SponsoringGeneralInfo from './sponsoringGeneralInfo'
// import SponsoringLetterClearanceInfo from './sponsoringLetterClearnace'
// import SponsoringEmpFinancialDocument from './sponsoringEmpFinancialDocument'
// import SponsoringSponsorshipDocument from './sponsoringSponsorshipDoc'

const Documents = ({ documentInfo, setDocumentInfo }) => {
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [documentPopup, setDocumentPopup] = useState(true)
  const [documentDis, setDocumentDis] = useState(false)
  useEffect(() => {
    if (documentInfo?.isLocked) {
      setDocumentDis(true)
    }
  }, [documentInfo])
  const updateDocumentStatus = async () => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/updateDocumentStatus`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      if (res?.data?.updatedDoc) {
        const data = res?.data?.updatedDoc
        setDocumentInfo(data)
        setSelectedVisa({
          invoiceId: data?.invoiceId,
          visaId: data?.visaTypeId,
        })
        toast.success('Document Save successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className="personal-info document-info">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Main Applicant</Accordion.Header>

          <Accordion.Body>
            <Accordion className="inner-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>

                <Accordion.Body>
                  <GeneralInfo
                    documentDis={documentDis}
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion className="inner-accordion" defaultActiveKey="1">
              <Accordion.Item eventKey="">
                <Accordion.Header>Agreements</Accordion.Header>

                <Accordion.Body>
                  <GeneralInfo />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            <Accordion className="inner-accordion" defaultActiveKey="2">
              <Accordion.Item eventKey="">
                <Accordion.Header>Letters & Clearance</Accordion.Header>

                <Accordion.Body>
                  <LetterClearanceInfo
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="3">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Employment & Financial Documents
                </Accordion.Header>

                <Accordion.Body>
                  <EmployeementFinancialDoc
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="4">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Sponsorship Documents (if applicable)
                </Accordion.Header>

                <Accordion.Body>
                  <SponsorshipDocument
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="disclaimer-text">
              <p>
                <span>Disclaimer:</span> This checklist contains the basic,
                mandatory documentation based on the type of visa you're
                applying for.Nevertheless, it's common for the Canadian
                immigration authorities to request supplementary information /
                documentation during advanced stages of the application process.
                You will be informed about these requirements if needed, as soon
                as we are notified by the immigration authorities.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Add Spouse Documents</Accordion.Header>

          <Accordion.Body>
            <Accordion className="inner-accordion" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>

                <Accordion.Body>
                  <GeneralSpouseInfo
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="1">
              <Accordion.Item eventKey="">
                <Accordion.Header>Dependents</Accordion.Header>
                <Accordion.Body>
                  <DependentSpouseInfo
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <Accordion className="inner-accordion" defaultActiveKey="2">
              <Accordion.Item eventKey="">
                <Accordion.Header>Letters & Clearences</Accordion.Header>

                <Accordion.Body>
                  <GeneralInfo />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="3">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Employment & Financial Documents
                </Accordion.Header>

                <Accordion.Body>
                  <GeneralInfo />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="4">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Sponsorship Documents (if applicable)
                </Accordion.Header>

                <Accordion.Body>
                  <GeneralInfo />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>  */}
            <div className="disclaimer-text">
              <p>
                <span>Disclaimer:</span> This checklist contains the basic,
                mandatory documentation based on the type of visa you're
                applying for.Nevertheless, it's common for the Canadian
                immigration authorities to request supplementary information /
                documentation during advanced stages of the application process.
                You will be informed about these requirements if needed, as soon
                as we are notified by the immigration authorities.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="2">
          <Accordion.Header>Sponsored Applicant</Accordion.Header>

          <Accordion.Body>
            <Accordion className="inner-accordion" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>

                <Accordion.Body>
                  <SponsoredGeneralInfo
                    documentDis={documentDis}
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="2">
              <Accordion.Item eventKey="">
                <Accordion.Header>Letters & Clearance</Accordion.Header>

                <Accordion.Body>
                  <SponsoredLetterClearanceInfo
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="3">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Employment & Financial Documents
                </Accordion.Header>

                <Accordion.Body>
                  <SponsoredEmpFinancialDocument
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="4">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Sponsorship Documents (if applicable)
                </Accordion.Header>

                <Accordion.Body>
                  <SponsoredSponsorshipDocument
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="disclaimer-text">
              <p>
                <span>Disclaimer:</span> This checklist contains the basic,
                mandatory documentation based on the type of visa you're
                applying for.Nevertheless, it's common for the Canadian
                immigration authorities to request supplementary information /
                documentation during advanced stages of the application process.
                You will be informed about these requirements if needed, as soon
                as we are notified by the immigration authorities.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Add Sponsoring Applicant Document</Accordion.Header>

          <Accordion.Body>
            <Accordion className="inner-accordion" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>General</Accordion.Header>

                <Accordion.Body>
                  <SponsoringGeneralInfo
                    documentDis={documentDis}
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="2">
              <Accordion.Item eventKey="">
                <Accordion.Header>Letters & Clearance</Accordion.Header>

                <Accordion.Body>
                  <SponsoringLetterClearanceInfo
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="3">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Employment & Financial Documents
                </Accordion.Header>

                <Accordion.Body>
                  <SponsoringEmpFinancialDocument
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="inner-accordion" defaultActiveKey="4">
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  Sponsorship Documents (if applicable)
                </Accordion.Header>

                <Accordion.Body>
                  <SponsoringSponsorshipDocument
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    documentDis={documentDis}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="disclaimer-text">
              <p>
                <span>Disclaimer:</span> This checklist contains the basic,
                mandatory documentation based on the type of visa you're
                applying for.Nevertheless, it's common for the Canadian
                immigration authorities to request supplementary information /
                documentation during advanced stages of the application process.
                You will be informed about these requirements if needed, as soon
                as we are notified by the immigration authorities.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
      {documentPopup && (
        <>
          <div className="bg-shadow" />
          <DocumentPopup setDocumentPopup={setDocumentPopup} />
        </>
      )}
      <div className="visa-btn">
        <Button
          disabled={documentDis ? documentDis : false}
          onClick={updateDocumentStatus}
        >
          Save
        </Button>
        <div className="visa-desc-text">
          <p>
            Once your form is completed, you will be able to make new changes by
            contacting our Support team.
          </p>
          <p>When you're ready, click "Submit"!</p>
        </div>
      </div>
    </div>
  )
}
export default Documents
