import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import worker from "../../assets/images/visa/worker.webp";
import passport from "../../assets/images/visa/passport.webp";
import workerfoot from "../../assets/images/visa/workerfoot.webp";
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import parse from 'html-react-parser';
import "./visa.css";

const WorkerVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='visa-page worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <a
                className="breadcrum"
                href="/#visa-type"
              >
                {t('visas.businessHeading1')}
              </a>
              <div className='worker-visa-banner-heading-title'>
                <h2>{t("workerVisa.workerHeading2")}</h2>
              </div>
            </div>

            <p>{t("workerVisa.workerParagraph1")}</p>
            <p>{t("workerVisa.workerParagraph2")}</p>

            <div className='worker-grid'>
              <div className='worker-content'>
                <h3>{t("workerGrid.workerHeading1")}</h3>
                <p>{t("workerGrid.workerParagraph1")}</p>

                <p>{t("workerGrid.workerParagraph2")}</p>

              </div>

              <div className='worker-cover rightimg'>
                <img src={worker} alt='' />
              </div>
            </div>
            <div className='worker-grid'>

              <div className='worker-cover d-none d-lg-block'>
                <img src={passport} alt='' />
              </div>
              <div className='worker-content righttext'>
                <h3>{t("workerGrid.workerHeading2")}</h3>
                <p>{t("workerGrid.workerParagraph3")}</p>

                <p>{parse(t("workerGrid.workerParagraph4"))}</p>

              </div>
              <div className='worker-cover d-lg-none'>
                <img src={passport} alt='' />
              </div>
            </div>

            <div className='worker-visa-Types'>
              <h2>{t("workerGrid2.workerHeading1")}</h2>

              <p>{t("workerGrid2.workerParagraph1")}</p>
              <p>{t("workerGrid2.workerParagraph2")}</p>

              <h4>{t("workerGrid2.workerHeading2")}</h4>
              <p>{t("workerGrid2.workerParagraph3")}</p>



              <h5>{t("workerGrid2.workerHeading3")}</h5>
              <p>{t("workerGrid2.workerParagraph4")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist1")}</li>
                <li>{t("workerGrid2.workerlist2")}</li>
                <li>{t("workerGrid2.workerlist3")}</li>
                <li>{t("workerGrid2.workerlist4")}</li>
                <li>{t("workerGrid2.workerlist5")}</li>
              </ul>
              <p>{t("workerGrid2.workerParagraph5")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist6")}</li>
                <li>{t("workerGrid2.workerlist7")}</li>
                <li>{t("workerGrid2.workerlist8")}</li>
                <li>{t("workerGrid2.workerlist5")}</li>
              </ul>



              <h5>{t("workerGrid2.workerHeading4")}</h5>
              <p>{t("workerGrid2.workerParagraph6")}</p>
              <p>{t("workerGrid2.workerParagraph7")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist1")}</li>
                <li>{t("workerGrid2.workerlist10")}</li>
                <li>{t("workerGrid2.workerlist3")}</li>
                <li>{t("workerGrid2.workerlist2")}</li>
                <li>{t("workerGrid2.workerlist4")}</li>
                <li>{t("workerGrid2.workerlist5")}</li>
              </ul>



              <h5>{t("workerGrid2.workerHeading5")}</h5>
              <p>{t("workerGrid2.workerParagraph8")}</p>
              <p>{t("workerGrid2.workerParagraph9")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist1")}</li>
                <li>{t("workerGrid2.workerlist11")}</li>
                <li>{t("workerGrid2.workerlist3")}</li>
                <li>{t("workerGrid2.workerlist2")}</li>
                <li>{t("workerGrid2.workerlist4")}</li>
                <li>{t("workerGrid2.workerlist5")}</li>
                <li>{t("workerGrid2.workerlist9")}</li>
              </ul>
              <p>{t("workerGrid2.workerParagraph10")}</p>



              <h5>{t("workerGrid2.workerHeading6")}</h5>
              <p>{t("workerGrid2.workerParagraph11")}</p>
              <p>{t("workerGrid2.workerParagraph12")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist12")}</li>
                <li>{t("workerGrid2.workerlist13")}</li>
              </ul>
              <p>{t("workerGrid2.workerParagraph13")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist14")}</li>
                <li>{t("workerGrid2.workerlist15")}</li>
                <li>{t("workerGrid2.workerlist16")}</li>
                <li>{t("workerGrid2.workerlist4")}</li>
                <li>{t("workerGrid2.workerlist5")}</li>
              </ul>


              <h4>{t("workerGrid2.workerHeading7")}</h4>
              <p>{t("workerGrid2.workerParagraph14")}</p>


              <h4>{t("workerGrid2.workerHeading8")}</h4>
              <p>{t("workerGrid2.workerParagraph15")}</p>
              <ul>
                <li>{t("workerGrid2.workerlist17")}</li>
                <li>{t("workerGrid2.workerlist18")}</li>
                <li>{t("workerGrid2.workerlist19")}</li>
                <li>{t("workerGrid2.workerlist20")}</li>
                <li>{t("workerGrid2.workerlist21")}</li>
                <li>{t("workerGrid2.workerlist22")}</li>
              </ul>
              <p>{t("workerGrid2.workerParagraph16")}</p>

            </div>

          </div>
        </div>

        <div className="visa-contact text-center">
          <h4>{t("visaContactFoot.Heading1")}</h4>
          <p>{t("visaContactFoot.Paragraph1")}</p>
          <p className="text-center">
            <Link to="/contact">
              <Button
                className="blue-button"
                onClick={() => (window.location.href = '/contact')}
              >
                {t('visaContactFoot.ContactBtn')}
              </Button>
            </Link>
          </p>
        </div>
        <div className="visa-foot"><img src={workerfoot} alt='' /></div>

      </div>
    </Layout>
  );
};

export default WorkerVisa;
