import React, { useState } from "react";
import { Link } from "react-router-dom";
import footerlogo from "../../assets/images/footer/logo.svg";
import locationicon from "../../assets/images/footer/location.svg";
import emailicon from "../../assets/images/footer/email.svg";
import callicon from "../../assets/images/footer/phone.svg";
import topicon from "../../assets/images/footer/up-grey.svg";
import visalogo from "../../assets/images/footer/visalogo.png";
import isralogo from "../../assets/images/footer/isralogo.png";
import { useTranslation } from "react-i18next";
import "./footer.css";
/* eslint-disable */
const Footer = ({  scrollToElement }) => {
  const [visible, setVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const contactData = {
    "address": {
        "line1": "91 King William Street, Adelaide",
        "line2": "SA 5000, Australia"
    },
    "address2": {
        "line1": "5 Secretary’s Lane Gibraltar",
        "line2": "GX11 1AA"
    },
    "phone": {
        "phone1": "+61-8-715-01857"
    },
    "email": "Support@uisaustralia.com",
    "uisCode": "All rights reserved to UIS Ltd. Copyright © 2022"
  };

  return (
    <div className="footer">
      <div className="footer1">
        
        <div className="container">
          <div className="top-footer">      
            <div className="footer-logo">
              <Link to="/">
                <img src={footerlogo} alt="" />
              </Link>
            </div>
            <div className="bottom-footer">
              <div className="footer-grid">
                <p>
                  <img src={locationicon} alt="" />
                  {contactData?.address?.line1}<br />
                  {contactData?.address?.line2}
                </p>               
                <p hidden={!contactData.address2}>
                  <img src={locationicon} alt="" />
                  {contactData?.address2?.line1}<br />
                  {contactData?.address2?.line2}
                </p>
                <p>
                  <img src={emailicon} alt="" />
                  {contactData?.email}
                </p>
                <p>
                  <img src={callicon} alt="" />
                  {contactData?.phone?.phone1}
                </p>
                <br />
                <p hidden={!contactData.phone?.phone2}>
                  <img src={callicon} alt="" />
                  <span> {contactData?.phone?.phone2} {contactData?.phone?.tollFree}</span>
                </p>
              </div>
              <div className="footer-grid">
                <ul>
                  <li>
                    <Link to="/visa/holiday" target="_blank" rel="noreferrer"> {t("footer.whv")}</Link>
                  </li>
                  <li>
                    <Link to="/visa/business" target="_blank" rel="noreferrer"> {t("footer.business")}</Link>
                  </li>
                  <li>
                    <Link to="/visa/worker" target="_blank" rel="noreferrer"> {t("footer.worker")}</Link>
                  </li>
                  <li>
                    <Link to="/visa/student" target="_blank" rel="noreferrer">{t("footer.student")}</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-grid">
                <ul>
                  <li>
                    <Link to="/contact" target="_blank" rel="noreferrer">{t("footer.contact")}</Link>
                  </li>
                  <li>
                    <span role="presentation" onClick={scrollToElement} target="_blank" rel="noreferrer">
                      {t("footer.fag")}
                    </span>
                  </li>
                  <li>
                    <Link to="/privacypolicy" target="_blank" rel="noreferrer">{t("footerGrid.privacyPolicy")}</Link>
                  </li>
                  <li>
                    <Link to="/termconditions" target="_blank" rel="noreferrer">
                      {t("footerGrid.termConditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="classmarge">
              <div className="footer-social">
                <ul>
                  <li>
                    <a
                      className="facebook-icon"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/uisAustraliaoffical"
                      width="35" height='35'
                    >

                    </a>
                  </li>
                  <li>
                    <a
                    className="instagram-icon"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/uis_australia/"
                      width="35" height='35'
                    >                    
                    </a>
                  </li>
                  <li>
                    <a
                    className="linkedin-icon"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                      width="35" height='35'
                    >
                    </a>
                  </li>
                  <li>
                    <a
                    className="twitter-icon"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/uis_australia"
                      width="35" height='35'
                    >
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <div className="footer-payment">
                  <img className="isra" src={isralogo} alt="" />
                  <img className="visa" src={visalogo} alt="" />
                </div>
                {visible ? (
                  <div className="footer-goto" onClick={scrollToTop}>
                    <img src={topicon} alt="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div></div>

        </div>
      </div>
      <div className="footer2">
        <p>{contactData?.uisCode}</p>
      </div>
    </div>
  );
};

export default Footer;
