import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'
import logo from '../../assets/images/header/logo-white.svg'
import facebook from '../../assets/images/thankyou/face-blue.svg'
import linkedin from '../../assets/images/thankyou/linkedin-blue.svg'
import twitter from '../../assets/images/thankyou/twitter-blue.svg'
import instagram from '../../assets/images/thankyou/instagram-blue.svg'
import { useTranslation } from 'react-i18next'
import './thankyou.css'

const FailedPage = ({ paymentError }) => {
  const { state } = useLocation()
  const { t } = useTranslation()

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="thankyou-page">

        <div className="thankyou-grid">
          <div className="thankyou-content">
            <div className='thankyou-container'>
              <Link to="/">
                <img src={logo} alt="" className="logo-thankyou" />
              </Link>

            </div>
           
          </div>

            <div className="thankyou-form">
            
            <a className="breadcrum" href="/">
            {t('login.Heading')}
            </a>
            <div className="thankyou-popup-content">              
            <div className='thankyou-text'>
            <img src="" alt="" />
            <h2>Your payment was declined by the credit card company.</h2>
            <h3>Please check your credit card details and try again.</h3>
            <p>{state?.description}</p>
            <p>Error Message : {paymentError}</p>
              <div className="thankyou-social">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/uisAustraliaoffical"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/uis_australia/"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/uis_australia"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>


        </div>
      </div>
    </Layout>
  )
}

export default FailedPage
