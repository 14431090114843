import React from 'react'
import Layout from '../layout/layout'
//import { useTranslation } from 'react-i18next'
import './legal.css'

const InFrenchPrivacyPolicy = () => {
  return (
    <Layout>
      <div className="legal-page">
        <div className="container">
          <a className="breadcrum" href="/">
            {"< retour à la page d'accueil"}
          </a>
          <div className="legal-content privacy">
            <h2>politique de confidentialité</h2>

            <div>
              <p>
                Nous nous engageons à protéger vos informations personnelles et
                votre droit à la vie privée. Si vous avez des questions ou des
                préoccupations concernant notre politique ou nos pratiques
                concernant vos informations personnelles, veuillez nous
                contacter à support-fr@uisaustralia.com.
                <br />
                <br />
                Lorsque vous visitez notre site Web et utilisez nos services,
                vous confiez-nous vos informations personnelles. Nous prenons
                votre vie privée très au sérieux. Dans cet avis de
                confidentialité, nous décrivons notre politique de
                confidentialité. Nous cherchons à vous expliquer de la manière
                la plus claire possible quelles informations nous collectons,
                comment nous les utilisons et quels droits vous avez par rapport
                à celles-ci.
                <br />
                <br />
                Cette politique de confidentialité s'applique à toutes les
                informations collectées via notre site Web et /ou tout service
                connexe.
                <br />
                <br />
                Veuillez noter que toutes les informations personnelles
                collectées via notre site Web sont soumises, traitées et
                contrôlées par l'ISU et ses sous-traitants soigneusement
                sélectionnés, qui sont tous tenus par la même politique de
                confidentialité ici.
              </p>

              <h5>1 - QUELLES INFORMATIONS COLLECTONS-NOUS?</h5>
              <p>1.1 – Informations personnelles que vous nous divulguez :</p>
              <p>
                1.1.1 – Nom et coordonnées : nom complet, adresse e-mail, numéro
                de téléphone.
              </p>

              <p>
                1.1.2 – Données d'évaluation: (a) Informations personnelles :
                nom complet, sexe, adresse e-mail, numéro de téléphone, adresse
                postale complète, date de naissance, état civil, nombre de
                personnes à charge, informations sur les casiers judiciaires ou
                les problèmes de santé graves; (b) Éducation et formation :
                niveau d'information le plus élevé, y compris la durée du
                programme, le domaine et le pays d'études; (c) Expérience de
                travail : votre expérience de travail au cours des 10 dernières
                années, y compris la description du poste, les dates de début et
                de fin, le nombre d'heures par semaine. Si vous êtes
                propriétaire d'une entreprise : description des activités, date
                de début, nombre d'employés; (d) Informations financières :
                valeur nette estimée, flux de trésorerie estimés; (e)
                Compétences linguistiques : niveau d'anglais, niveau de
                français; (f) Informations sur l'adaptabilité : si vous avez un
                membre de la famille vivant en Australie ou non.
              </p>

              <p>
                1.1.3 – Données de paiement: Nous collectons les données
                nécessaires pour traiter votre paiement si vous effectuez des
                achats, telles que votre numéro d'instrument de paiement (tel
                qu'un numéro de carte de crédit) et le code de sécurité associé
                à votre instrument de paiement. Les détails complets de votre
                moyen de paiement ne sont pas conservés dans nos systèmes, à
                l'exception du : nom imprimé sur la carte, 6 premiers chiffres,
                4 derniers chiffres et date d'expiration. Toutes les
                transactions sont effectuées à l'aide du système de cryptage
                ultime SSL® - Secure Sockets Layer.
              </p>

              <p>
                1.1.4 – Pièces justificatives : toute pièce justificative
                envoyée volontairement par vous.
              </p>
              <p>
                Toutes les informations personnelles que vous nous fournissez
                doivent être véridiques, complètes et exactes, et vous devez
                nous informer de tout changement apporté à ces informations
                personnelles..
              </p>
              <p>1.2 – Informations collectées automatiquement:</p>

              <p>
                Nous recueillons automatiquement certaines informations lorsque
                vous visitez, utilisez ou naviguez sur notre site Web. Ces
                informations ne révèlent pas votre identité spécifique (comme
                votre nom ou vos coordonnées), mais peuvent inclure des
                informations sur l'appareil et l'utilisation, telles que votre
                adresse IP, les caractéristiques du navigateur et de l'appareil,
                le système d'exploitation, les préférences linguistiques, les
                URL de référence, le nom de l'appareil, le pays, l'emplacement.
                , des informations sur comment et quand vous utilisez notre site
                Web. Ces informations sont principalement nécessaires pour
                maintenir la sécurité et le fonctionnement de notre site Web,
                ainsi que pour nos analyses internes et nos rapports.
              </p>

              <h5>2 - COMMENT UTILISONS-NOUS VOS INFORMATIONS?</h5>
              <p>
                2.1 – Nous traitons vos informations à des fins fondées sur des
                motifs juridiques impérieux qui, dans le but de vous fournir les
                services demandés, et en pleine conformité avec toutes les
                obligations légales uniquement pour les utilisations limitées
                détaillées dans les services ci-après, prévaudront sur l'intérêt
                et les droits de le client à la vie privée et à la
                confidentialité.
              </p>

              <p>
                2.2 – Nous utilisons les informations que nous collectons ou
                recevons uniquement aux fins suivantes: (a) Pour exécuter et
                gérer vos commandes et fournir les services que vous nous avez
                confiés; (b) Communication marketing et promotionnelle; (c) Pour
                vous envoyer des informations administratives; (d)Pour poster
                des témoignages avec votre consentement préalable. Avant de
                publier un témoignage, nous obtiendrons votre consentement pour
                utiliser votre nom et votre témoignage; (e) Demander des
                commentaires; (f) Pour appliquer nos termes, conditions et
                politiques; (g) Pour répondre aux demandes légales et prévenir
                les dommages.
              </p>

              <h5>3 - COOKIE POLICY</h5>
              <p>
                Notre site Web inclut des technologies de suivi telles que les
                cookies et les pixels. Ces technologies de traçage sont
                utilisées dans le but de collecter certaines informations
                automatiquement, par nous-mêmes ou par des prestataires de
                services en notre nom.
                <br />
                <br />
                Les « cookies » sont de petits fichiers texte qui sont placés
                sur votre disque dur par un serveur Web. lorsque vous accédez à
                notre site Web ou utilisez les Services. Les cookies sont
                utilisés à de nombreuses fins, et par exemple, pour rendre
                l'interaction entre vous et les Services plus rapide et plus
                facile, permettre l'activation automatique de certaines
                fonctionnalités, une navigation efficace, fournir le site Web
                dans une langue applicable en fonction de votre pays, etc.,
                ainsi qu'à des fins de remarketing (comme détaillé ci-dessous).
                <br />
                <br />
                Vous pouvez trouver des informations complètes sur les cookies
                ici.
                <br />
                <br />
                La plupart des navigateurs vous permettent d'effacer les cookies
                du disque dur de votre ordinateur , bloquer l'acceptation des
                cookies ou recevoir un avertissement avant qu'un cookie ne soit
                stocké. Vous pouvez supprimer les cookies en suivant les
                instructions dans les préférences de votre appareil. Cependant,
                veuillez noter que si vous choisissez de désactiver les cookies,
                certaines fonctionnalités de notre site Web peuvent ne pas
                fonctionner correctement et votre expérience en ligne peut être
                limitée.
                <br />
                <br />
                En plus de nos cookies utilisés pour faire fonctionner le site
                Web et suivre les performances de nos campagnes, nous pouvons
                autoriser des tiers à utiliser des cookies ou d'autres
                technologies de suivi, comme suit:
              </p>

              <h5 className="secondary">
                Cookies d'analyse et de performance:
              </h5>
              <p class="text-uppercase">Source:</p>

              <p>
                Google.com
                <br />
                <br />
                Google Analytics.
                <br />
                <br />
                Ce cookie est placé afin de suivre et d'analyser l'utilisation
                de nos Services, à des fins internes, et peut inclure la
                collecte de votre IP.
              </p>
              <p class="text-uppercase">
                Politique de confidentialité / Désactivation:
              </p>

              <p>
                <a
                  href="https://policies.google.com/technologies/partner-sites"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.google.com/policies/privacy/partners
                </a>
                <br />
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </p>
            </div>

            <p>
              Pour plus d'informations sur notre utilisation des produits
              Google,{' '}
              <a
                href="https://policies.google.com/technologies/partner-sites"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Cliquez ici.
              </a>
            </p>
            <h5 className="secondary">
              Essentiel, Fonctionnalité &{' '}
              <a
                href="https://policies.google.com/technologies/partner-sites"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Opération:
              </a>
            </h5>
            <p class="text-uppercase">Source:</p>

            <p>
              Gestionnaire de balises Google
              <br />
              <br />
              Ce cookie est strictement nécessaire et est utilisé pour charger
              des scripts dans les pages de notre site Web.
            </p>
            <p class="text-uppercase">
              Politique de confidentialité / Désactivation:
            </p>

            <a
              href="https://policies.google.com/privacy "
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy{' '}
            </a>
            <br />
            <a
              href="https://policies.google.com/technologies/managing?hl=en"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/technologies/managing?hl=en
            </a>

            <p>
              {' '}
              <h5 className="secondary">Ciblage et publicité:</h5>
            </p>

            <p class="text-uppercase">Source:</p>
            <p>
              Remarketing Google AdWords
              <br />
              <br />
              Remarketing Facebook
              <br />
              <br />
              Taboula
              <br />
              <br />
              Outbrain
            </p>
            <p class="text-uppercase">
              Politique de confidentialité / Désactivation:
            </p>

            <a
              href="https://policies.google.com/privacy"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            <br />

            <a
              href="https://policies.google.com/technologies/managing?hl=en"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/technologies/managing?hl=en
            </a>
            <br />

            <a
              href="https://www.facebook.com/policies/cookies/"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/policies/cookies/
            </a>
            <br />

            <a
              href="https://www.taboola.com/privacy-policy#users"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://www.taboola.com/privacy-policy#users
            </a>
            <br />

            <a
              href="https://www.taboola.com/cookie-policy"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://www.taboola.com/cookie-policy
            </a>
            <br />

            <a
              href="https://www.outbrain.com/legal/"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              https://www.outbrain.com/legal/
            </a>
            <br />
            <br />

            <p>
              Vous pouvez contrôler la façon dont votre navigateur répond aux
              cookies en ajustant les paramètres de confidentialité et de
              sécurité de votre navigateur Web.
              <br />
              <br />
              Veuillez vous reporter à la page d'assistance du navigateur que
              vous utilisez.
              <br />
              <br /> De plus, vous pouvez désactiver les cookies de certains
              annonceurs et la publicité basée sur les intérêts activée par le
              navigateur sur le site Web de la Network Advertising Initiative
              ("NAI"). –
              <a
                href="https://optout.networkadvertising.org/?c=1"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Refus des consommateurs NAI
              </a>{' '}
              et le site Web de la Digital Advertising Alliance (« DAA ») –{' '}
              <a
                href="https://youradchoices.com/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Page de désinscription DAA
              </a>
              .
            </p>

            <h5>
              4 - VOS INFORMATIONS SERONT-ELLES PARTAGÉES AVEC QUICONQUE ?
            </h5>
            <p>
              4.1 – Nous ne partageons et ne divulguons vos informations que
              dans les situations suivantes: (a) si nécessaire pour l'exécution
              des services que vous nous avez chargés d'exécuter en votre nom. Y
              compris, mais sans s'y limiter, le partage des informations avec
              nos sous-traitants, agents d'immigration, autorités et
              prestataires de services (b) Conformité aux lois : nous pouvons
              divulguer vos informations lorsque nous sommes légalement tenus de
              le faire afin de nous conformer à la loi applicable, aux demandes
              gouvernementales, à une procédure judiciaire, à une ordonnance
              d'un tribunal ou à une procédure judiciaire, par exemple en
              réponse à une ordonnance d'un tribunal ou à une assignation à
              comparaître. (y compris en réponse aux autorités publiques pour
              répondre aux exigences de sécurité nationale ou d'application de
              la loi); (c) Intérêts vitaux et droits légaux : nous pouvons
              divulguer vos informations lorsque nous pensons qu'il est
              nécessaire d'enquêter, de prévenir ou de prendre des mesures
              concernant des violations potentielles de nos politiques, des
              soupçons de fraude, des situations impliquant des menaces
              potentielles pour la sécurité de toute personne et des activités
              illégales, ou comme preuve dans les litiges dans lesquels nous
              sommes impliqués; (d) Transferts d'entreprise : nous pouvons
              partager ou transférer vos informations dans le cadre de, ou
              pendant les négociations de, toute fusion, vente d'actifs de
              l'entreprise, financement ou acquisition de tout ou partie de
              notre entreprise à une autre société.
            </p>

            <p>
              4.2 – Nous pouvons également partager vos informations dans ces
              situations spécifiques : prestataires de services tiers,
              sous-traitants ou agents qui fournissent des services pour nous ou
              en notre nom et qui ont besoin d'accéder à ces informations pour
              effectuer ce travail. Les exemples incluent : le traitement des
              paiements, l'analyse des données, la livraison des e-mails, les
              services d'hébergement, le service client et les efforts de
              marketing. Nous ne partageons, vendons, louons ou échangeons
              aucune de vos informations avec des tiers à des fins
              promotionnelles.
            </p>

            <h5>5 - COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</h5>
            <p>
              Nous ne conserverons vos informations personnelles que le temps
              nécessaire aux fins énoncées dans la présente politique de
              confidentialité, à moins qu'une période de conservation plus
              longue ne soit requise ou autorisée par la loi (telle que des
              exigences fiscales, comptables ou autres). Lorsque nous n'avons
              aucun besoin commercial légitime de traiter vos informations
              personnelles, nous les supprimerons ou les anonymiserons.
            </p>

            <h5>6 - COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ ?</h5>
            <p>
              Nous avons mis en place des mesures de sécurité techniques et
              organisationnelles appropriées conçues pour protéger la sécurité
              de toute information personnelle que nous traitons.
            </p>

            <h5>7 - QUELS SONT VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ ?</h5>
            <p>
              Notre société souhaite s'assurer que vous êtes pleinement
              conscient de tous vos droits en matière de protection des données.
              Chaque utilisateur a droit à ce qui suit:
              <br />
              <br />
              Le droit d'accès – Vous avez le droit de demander à Notre Société
              des copies de vos données personnelles. Nous pouvons vous facturer
              une somme modique pour ce service.
              <br />
              <br />
              Le droit de rectification – Vous avez le droit de demander à notre
              société de corriger toute information que vous jugez inexacte.
              Vous avez également le droit de demander à Notre Société de
              compléter les informations que vous jugez incomplètes.
              <br />
              <br />
              Le droit à l'effacement – ​​Vous avez le droit de demander à Notre
              Société d'effacer vos données personnelles, sous certaines
              conditions.
              <br />
              <br />
              Le droit de restreindre le traitement – ​​Vous avez le droit de
              demander à Notre Société de restreindre le traitement de vos
              données personnelles, sous certaines conditions.
              <br />
              <br />
              Le droit de vous opposer au traitement – ​​Vous avez le droit de
              vous opposer au traitement de vos données personnelles par Notre
              Société, sous certaines conditions.
              <br />
              <br />
              Le droit à la portabilité des données - Vous avez le droit de
              demander à Notre Société de transférer les données que nous avons
              collectées à une autre organisation, ou directement à vous, sous
              certaines conditions.
              <br />
              <br />
              Si vous faites une demande, nous vous avez un mois pour vous
              répondre. Si vous souhaitez exercer l'un de ces droits, veuillez
              nous contacter à notre adresse e-mail:
              <br />
              <br />
              Appelez-nous au: +61-2-720-20222
              <br />
              <br />
              Ou écrivez-nous:{' '}
              <a
                href="mailto:support-fr@uisaustralia.com"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                support-fr@uisaustralia.com
              </a>
            </p>

            <h5>8 - MODIFICATIONS DE L'AVIS DE POLITIQUE DE CONFIDENTIALITÉ</h5>
            <p>
              Lors de votre inscription à notre service, il vous sera demandé
              d'accepter les termes de cette politique de confidentialité. Si
              vous n'êtes pas d'accord avec les termes des présentes, veuillez
              ne pas utiliser ou engager nos services. Nous pouvons mettre à
              jour cette politique de confidentialité de temps à autre, auquel
              cas nous vous informerons de ces modifications en publiant la
              politique de confidentialité mise à jour sur cette page. Veuillez
              revenir sur cette page de temps en temps pour vous assurer que
              vous êtes familiarisé avec la dernière version.
            </p>

            <h5>
              9 - COMMENT POUVEZ-VOUS NOUS CONTACTER AU SUJET DE CETTE POLITIQUE
              ?
            </h5>
            <p>
              Si vous avez des questions ou des commentaires sur cette
              politique, envoyez-nous un e-mail à support-fr@uisaustralia.com
            </p>
            <br />
            <br />
            <p>Dernière mise à jour: 04/2021</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InFrenchPrivacyPolicy
