import { Modal } from 'react-bootstrap'
import FailedPage from './failedPage'
const PaymentFailed = ({ openFailed, handleClose, paymentError }) => {
  return (
    <>
      <Modal
        centered
        show={openFailed}
        onHide={handleClose}
        className="payment-success"
      >
        <Modal.Body>
          <FailedPage paymentError={paymentError} />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default PaymentFailed
