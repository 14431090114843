import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { serverPath } from '../../config/key';
const WorldCardForm = () => {
  const { checkoutId } = useParams();
  useEffect(() => {
    const appendSdkScript = () => {
      const script = document.createElement("script");
      script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      document.body.append(script);
    };

    appendSdkScript();
  }, [checkoutId]);

  return (
    <div>
      <form
        action={serverPath + "/payment-provider/worldcardCallback"}
        className="paymentWidgets"
        data-brands="VISA"
      ></form>
    </div>
  );
};

export default WorldCardForm;
