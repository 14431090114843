/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import UserContext from '../../context/UserContext'
import { userDetailSchema } from '../../utils/validationSchema'
import logo from '../../assets/images/header/logo.svg'

const GetUserDetailForPayment = ({ open, handleClose }) => {
  const {search} = useLocation()
  const { countries } = useContext(UserContext)
  const [paymentDetail, setPaymentDetail] = useState({})
  const [productDetail, setProductDetail] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(userDetailSchema) })
  const handleCountryChange = (e) => {
    const data = countries.filter((el) => el.name === e.target.value)
    setValue('country', e.target.value)
    setValue('countryCode', data[0]?.countryCode)
  }
  const getProductDetail = async (id) => {
    const res = await axios.get(`${serverPath}/product/getProductById/${id}`)
    const { products } = res.data
    if (products) {
      setProductDetail(products)
    }
  }
  useEffect(() => {
    const userId = new URLSearchParams(search).get('userId')
    const productId = new URLSearchParams(search).get('productId')
    const providerId = new URLSearchParams(search).get('paymentProviderId')
    const sfPaymentId = new URLSearchParams(search).get('sfPaymentId')

    getProductDetail(productId)
    setPaymentDetail({
      ...paymentDetail,
      userId: userId,
      productId: productId,
      providerId: providerId,
      sfPaymentId: sfPaymentId
    })
  }, [])
  const updateUserDetail = async (values) => {
    try {
		setIsLoading(true)
      const response = await axios.get(
        `${serverPath}/payment-provider/pay/${paymentDetail?.sfPaymentId}/${paymentDetail?.providerId}`,
        { params: { ...values, hasInvoiceDetails: true } },
      )
      const { status, data } = response
	  setIsLoading(false)
      if (status === 200) {
        window.location.href = data?.url
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className='payment-user-detail'>
          <div className="login-form">
            <div className="logo-payment">
              <img src={logo} alt="" width={'150px;'} />
            </div>
            <div className="login-popup-content">
              <h1>{productDetail?.name}</h1>
              <h6>Price: ${productDetail?.price} USD</h6>
              <h6>Enter your billing details below:</h6>
              <p>These details will appear on your invoice</p>
              <Form onSubmit={handleSubmit(updateUserDetail)}>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.firstName ? `error-border` : ``}`}
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    {...register('firstName')}
                  />
                  {errors?.firstName ? (
                    <span className="error-msg">
                      {errors?.firstName?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.lastName ? `error-border` : ``}`}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    {...register('lastName')}
                  />
                  {errors?.lastName ? (
                    <span className="error-msg">
                      {errors?.lastName?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.email2 ? `error-border` : ``}`}
                    type="email"
                    name="email2"
                    placeholder="Email"
                    {...register('email2')}
                  />
                  {errors?.email2 ? (
                    <span className="error-msg">{errors?.email2?.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.address ? `error-border` : ``}`}
                    type="text"
                    name="address"
                    placeholder="Address"
                    {...register('address')}
                  />
                  {errors?.address ? (
                    <span className="error-msg">
                      {errors?.address?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.city ? `error-border` : ``}`}
                    type="text"
                    name="city"
                    placeholder="City"
                    {...register('city')}
                  />
                  {errors?.city ? (
                    <span className="error-msg">{errors?.city?.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Select onChange={handleCountryChange} name="country">
                    {countries &&
                      countries?.length > 0 &&
                      countries.map((el) => (
                        <option value={el?.name} name="country">
                          {el?.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    className={`${errors?.phoneNumber ? `error-border` : ``}`}
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone number"
                    {...register('phoneNumber')}
                  />
                  {errors?.phoneNumber && (
                    <span className="error-msg">
                      {errors?.phoneNumber?.message}
                    </span>
                  )}
                </Form.Group>
                <Button type="submit" disabled={isLoading}>{isLoading ? <Spinner animation='border' /> : 'Update'}</Button>
              </Form>
            </div>
          </div>
    </div>
  )
}
export default GetUserDetailForPayment
