/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
//import { useLocation } from 'react-router-dom'
import envelope from '../../assets/images/header/envelope.svg'
import envelopewhite from '../../assets/images/header/envelope-white.svg'
import envelopewhitedark from '../../assets/images/header/envelope-white-dark.svg'
import call from '../../assets/images/header/call.svg'
import callwhite from '../../assets/images/header/call-white.svg'
import callwhitedark from '../../assets/images/header/call-white-dark.svg'
import searchicon from '../../assets/images/header/search.svg'
import searchiconlight from '../../assets/images/header/search-light.svg'
import loginicon from '../../assets/images/header/login.svg'
import loginiconwhite from '../../assets/images/header/login-white.svg'
import logo from '../../assets/images/header/logo.svg'
import logowhite from '../../assets/images/header/logo-white.svg'
import menuicon from '../../assets/images/header/menu.svg'
import menuiconwhite from '../../assets/images/header/menu-white.svg'
import searchmbl from '../../assets/images/header/search-mbl.svg'
import searchmblwhite from '../../assets/images/header/search-mbl-white.svg'
import loginmbl from '../../assets/images/header/login-mbl.svg'
import loginmblwhite from '../../assets/images/header/login-mbl-white.svg'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import './header.css'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import profile from '../../assets/images/visa/avatar.svg'


const Header = ({
  head,
  scrollToElement,
  handleSearch,
  
}) => {
  //const search = useLocation().search
  // const navigate = useNavigate()
  const { token, setUserGeoData } = useContext(UserContext)
  // const [open, setOpen] = useState(false)
  const { t ,i18n} = useTranslation()
  // const changeLanguage = (event) => {
  //   i18n.changeLanguage(event)
  // }

  const [menuopen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuopen)
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  const isSticky = (e) => {
    const header = document.querySelector('.header')
    const scrollTop = window.scrollY
    scrollTop >= 150
      ? header.classList.add('is-sticky')
      : header.classList.remove('is-sticky')
  }

  const originData = async () => {
    const res = await axios.get(
      'https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0',
    )
    setUserGeoData(res.data)
  }

  useEffect(() => {
    originData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // useEffect(() => {
  //   const getDetail = new URLSearchParams(search).get('user')
  //   if (getDetail === 'get') {
  //     setOpen(true)
  //   }
  // }, [])
  // const handleLogout = () => {
  //   localStorage.removeItem('token')
  //   setToken('')
  //   navigate('/login')
  // }
useEffect(()=>{
  if(window.location.pathname ==='/fr/terms' || window.location.pathname ==='/fr/privacyPolicy'){
    i18n.changeLanguage('fr');
  }else{
    i18n.changeLanguage('en');
  }
  
},[window.location.pathname])
  const hrefStyle = {
    color: 'inherit',
  }
  return (
    <div className={`header ${head ? `white-header` : ``}`}>
      <div className="top-header">
        <div className="container">
          {/* <GetUserDetailForPayment open={open} /> */}
          <div className="top-header-grid">
            <div className="contact-info">
              <span>
                {head ? (
                  <img src={envelopewhite} alt="" />
                ) : (
                  <img src={envelope} alt="" />
                )}             
                <a href={'mailto:' + t('contactEmail')} style={hrefStyle}>
                  {t('contactEmail')}
                </a>
              </span>
              <span>|</span>
              <span>
                {head ? (
                  <img src={callwhite} alt="" />
                ) : (
                  <img src={call} alt="" />
                )}
                {t('contactPhone')}
              </span>
              <span>|</span>
            </div>

            <div className="auth-info">
              <div
                className="search-icon"
                onClick={handleSearch}
                role="presentation"
              >
                {head ? (
                  <img src={searchiconlight} alt="" />
                ) : (
                  <img src={searchicon} alt="" />
                )}
              </div>
        
              {
                <div className="auth-login">
                  {token ? (
                    <div className="language-dropdown my-app">
                      
                      <Link to="/application/0">
                        My App <img src={profile} alt="profile" />
                      </Link>
                    </div>
                  ) : (
                    <a href={t('loginUrl')}>
                      
                      {head ? (
                        <img src={loginiconwhite} alt="" />
                      ) : (
                        <img src={loginicon} alt="" />
                      )}
                     {' '} <span>{t('topHeader.signin')}</span>
                    </a>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className={`main-navigation ${menuopen ? `open-menu` : ``}`}>
        <div className="container">
          <div className="main-navigation-grid">
            <div className="logo">
              <Link to="/">
                {head ? (
                  <img src={logowhite} alt="" />
                ) : (
                  <img src={logo} alt="" />
                )}
              </Link>
            </div>

            <div className="mobile-icons">
              <span onClick={handleSearch} role="presentation">
                {head ? (
                  <img src={searchmblwhite} alt="" />
                ) : (
                  <img src={searchmbl} alt="" />
                )}
              </span>
              <span>
                {token ? (
                  <Link to="welcome">My Application</Link>
                ) : (
                  <a href={t('loginUrl')}>
                    {head ? (
                      <img src={loginmblwhite} alt="" />
                    ) : (
                      <img src={loginmbl} alt="" />
                    )}
                  </a>
                )}
              </span>
            </div>

            <div className="menu-icon" onClick={toggleMenu}>
              {/* <span>{t('header.menu')}</span> */}
              {head ? (
                <img src={menuiconwhite} alt="" />
              ) : (
                <img src={menuicon} alt="" />
              )}
            </div>
            
            <div className="menu-grid">          
              <Nav>
              <Nav.Item>
              <Link to="/">             
                  <img className="logo-open-menu d-lg-none" src={logowhite} alt="" />           
              </Link>
              </Nav.Item> 
                <Nav.Item>
                  <Link to="/immigration">{t('header.immigration')}</Link>              
                </Nav.Item> 
                <Nav.Item>
                  <Link to="/about">{t('header.about')}</Link>
                </Nav.Item>            
                <Nav.Item>
                  <span onClick={scrollToElement}>{t('header.faq')}</span>
                </Nav.Item>
                  <Nav.Item>
                    <a href="https://www.uisaustralia.com/blog/">Blog</a>
                  </Nav.Item>
                <Nav.Item>
                  <Link to="/contact">{t('header.contact')}</Link>
                </Nav.Item>
              </Nav>

              <div className="language-mobile">
                <div className="contact-info">
                  <span>
                    <img src={envelopewhitedark} alt="" />
                    {t('contactEmail')}
                  </span>
                  <span>
                    <img src={callwhitedark} alt="" />
                    {t('contactPhone')}
                  </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
