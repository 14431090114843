import { useState, useRef, useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Accordion, Button, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import {
  sponsoredMainApplicant,
  PersonalMainApplicantInfoSchema,
  sponsoredChildValidation,
} from '../../utils/validationSchema'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import { childValidation } from '../../utils/validationSchema'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// import { findFlagUrlByNationality, countries } from 'country-flags-svg'
const PersonalInfo = ({ personalInformation, setPersonalInfo }) => {
  const { formType: type } = useParams()
  let intialState = {
    childFirstName: '',
    childMiddleName: '',
    childLastName: '',
    childGender: '',
    childBirthCity: '',
    childBirthCountry: '',
    childCitizenship: '',
    childSecondCitizenship: '',
    childDateOfBirth: '',
  }
  const [sponsoredShow, setSponsoredShow] = useState(false)
  useEffect(() => {
    if (type === 'sponsored') setSponsoredShow(true)
    else setSponsoredShow(false)
  }, [type])
  let schema = sponsoredShow
    ? sponsoredMainApplicant
    : PersonalMainApplicantInfoSchema
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const inputRef = useRef()
  const [error, setErrors] = useState()
  const [dateOfBirth, setDateOfBirth] = useState(new Date())
  const [sDateOfBirth, setSDateOfBirth] = useState(new Date())
  const [sponsoredSDateOfBirth, setSponsoredSDateOfBirth] = useState(new Date())
  //const [sponsorDateOfBirth, setSponsorDateOfBirth] = useState(new Date())
  const { selectedVisa, countries, setSelectedVisa } = useContext(UserContext)
  const [childs, setChilds] = useState([{ ...intialState }])
  const [sponsorChilds, setSponsorChilds] = useState([{ ...intialState }])
  const [mainApplicanDis, setMainApplicaDis] = useState(false)
  const [sponsorDis, setSponsorDis] = useState(false)
  const [spouseDis, setSpouseDis] = useState(false)
  const [childrenInfoDis, setChildrenInfo] = useState(false)
  const [sponsoredDateBirth, setSponsoredDateOfBirth] = useState()
  const [spouseFormState, setSpouseformstate] = useState('plus')
  const [childrenFormState, setChildrenFormState] = useState('plus')
  const [
    sponsoredApplicantFormState,
    setSponsoredApplicantFormState,
  ] = useState('plus')
  const [sponsorSpouseFormState, setSponsorSpouseFormState] = useState('plus')
  const [sponsorChildrenFormState, setSponsorChildrenFormState] = useState(
    'plus',
  )
  //for show the field with conditionaly
  const watchShowHealthDetail = watch('healthCondition')
  const watchShowChildrenDetail = watch('maritalStatus')
  const criminalOffenses = watch('criminalOffense')
  const sposeCriminalOffense = watch('spouseInformation.sCriminalOffense')
  const sponsorCriminalOffense = watch('sponsorCriminalOffense')
  const showHelthCondetionDetail = watch('sponsorHealthCondition')
  const spouseHealthCondition = watch('spouseInformation.sHealthCondition')
  const spouseShowChildrenDetail = watch('spouseInformation.sMaritalStatus')
  const sponsorSpouseHealthDetail = watch(
    'sponsorSpouseInformation.sHealthCondition',
  )
  const sponsorSpouseOffenceTypeDetail = watch(
    'sponsorSpouseInformation.sCriminalOffense',
  )

  useEffect(() => {
    if (
      personalInformation?.sponsoredInformation &&
      Object.keys(personalInformation?.sponsoredInformation)?.length !== 0
    ) {
      setSponsorDis(true)
      setSponsorChilds(personalInformation?.sponsorChildrenInfo)
    }
    if (
      personalInformation?.sponsoringInformation &&
      Object.keys(personalInformation?.sponsoringInformation)?.length !== 0
    ) {
      setMainApplicaDis(true)
      setChilds(personalInformation?.sponsoringChildrenInfo)
      setChildrenInfo(true)
    }

    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0
    ) {
      setMainApplicaDis(true)
    }

    if (
      personalInformation?.spouseInfo &&
      Object.keys(personalInformation?.spouseInfo)?.length !== 0
    ) {
      setSpouseDis(true)
    }
    if (
      personalInformation &&
      personalInformation?.childInformation?.length !== 0
    ) {
      setChildrenInfo(true)
      setChilds(personalInformation?.childInformation)
      //setChilds([...personalInformation?.childInformation])
    }
  }, [personalInformation])

  const changeAccordionselect = (key) => {
    if (key === '1') {
      setSpouseformstate('open')
      setValue('isSpouseOpen', true)
    } else if (key === '2') {
      setChildrenFormState('open')
    } else if (key === '3') {
      setSponsoredApplicantFormState('open')
    } else if (key === '4') {
      setSponsorSpouseFormState('open')
      setValue('isSponsorSpouseOpen', true)
    } else if (key === '5') {
      setSponsorChildrenFormState('open')
    }
  }
  const handleAddChild = () => {
    setChilds([...childs, { ...intialState }])
  }
  const handleAddSponsorChild = () => {
    setSponsorChilds([...sponsorChilds, { ...intialState }])
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...childs]
    prevData[index][name] = value
    setChilds([...prevData])
  }
  const removeChild = (index) => {
    const oldChild = [...childs]
    oldChild.splice(index, 1)
    setChilds(oldChild)
  }

  const handleSponsoredChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...sponsorChilds]
    prevData[index][name] = value
    setSponsorChilds([...prevData])
  }
  const removeSponsoredChild = (index) => {
    const oldChild = [...sponsorChilds]
    oldChild.splice(index, 1)
    setSponsorChilds(oldChild)
  }
  const handleChildDatePicker = (date, index) => {
    let prevData = [...childs]
    prevData[index].childDateOfBirth = date
    setChilds([...prevData])
  }
  const handleSponsorChildDatePicker = (date, index) => {
    let prevData = [...sponsorChilds]
    prevData[index].childDateOfBirth = date
    setSponsorChilds([...prevData])
  }
  const changeDatePicker = (date, type, ageType) => {
    if (type === 'dateOfBirth') {
      setDateOfBirth(date)
      setValue('dateOfBirth', date)
    }
    if (type === 'sponsorDateOfBirth') {
      setSponsoredDateOfBirth(date)
      setValue('sponsorDateOfBirth', date)
    }
    if (type === 'sponsorSDateOfBirth') {
      setSponsoredSDateOfBirth(date)
      setValue('spouseInformation.sDateOfBirth')
    }
    if (type === 'sDateOfBirth') {
      setSDateOfBirth(date)
      setValue('spouseInformation.sDateOfBirth', date)
    }
    // if (type === 'sponsorDateOfBirth') {
    //   setSponsorDateOfBirth(date)
    // }

    setValue(type, date)
    var dob = new Date(date)
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime()
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff)
    //extract year from date
    var year = age_dt.getUTCFullYear()
    //now calculate the age of the user
    var age = Math.abs(year - 1970)
    if (ageType === 'age') {
      setValue(ageType, age)
    }
    if (ageType === 'sAge') {
      setValue('spouseInformation.sAge', age)
    }
    if (ageType === 'sponsorAge') {
      setValue('spouseInformation.sAge', age)
    }
    if (ageType === 'sponsoredAge') {
      setValue('sponsorAge', age)
    }
  }
  const submitPersonalInfo = async (values) => {
    try {
      let validate
      let valid
      if (childrenFormState === 'open') {
        validate = await childValidation.validate(childs, {
          abortEarly: false,
        })
        valid = childrenFormState === 'open' ? validate : true
      }
      if (sponsorChildrenFormState === 'open') {
        validate = await sponsoredChildValidation.validate(sponsorChilds, {
          abortEarly: false,
        })
        valid = sponsorChildrenFormState === 'open' ? validate : true
      }
      const valids = valid ? valid : true
      if (valids) {
        const res = await axios.post(
          `${serverPath}/visa/visaPersonalInfo`,
          {
            ...values,
            childData:
              childs[0]?.childFirstName && childs[0]?.childGender
                ? childs
                : sponsorChilds,
            visaTypeId: selectedVisa.visaId,
            invoiceId: selectedVisa.invoiceId,
            formType: type,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        const { code, create } = res.data

        if (code === 200) {
          setErrors('')
          reset()
          setPersonalInfo(create)
          setSelectedVisa({
            invoiceId: create?.invoiceId,
            visaId: create?.visaTypeId,
          })
          toast.success('Your information was saved successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
      let er = {}
      err?.inner?.forEach((el) => {
        er[el.path] = el.message
      })
      setErrors({ ...er })
    }
  }
  const handleDelete = (value) => {
    if (value === 'sponsoredApplicantFormState') {
      setSponsoredApplicantFormState('plus')
    } else if (value === 'childrenFormState') {
      setChildrenFormState('plus')
      setErrors({})
    } else if (value === 'spouseFormState') {
      setSpouseformstate('plus')
      setValue('isSpouseOpen', false)
    } else if (value === 'sponsorSpouseFormState') {
      setSponsorSpouseFormState('plus')
      setValue('isSponsorSpouseOpen', false)
    } else if (value === 'sponsorChildrenFormState') {
      setSponsorChildrenFormState('plus')
      setErrors({})
    }
  }
  console.log('Error detail is here=>', errors)
  return (
    <div className="personal-info">
      <Form onSubmit={handleSubmit(submitPersonalInfo)}>
        {!sponsoredShow ? (
          <>
            <Accordion
              defaultActiveKey="0"
              onSelect={(key) => changeAccordionselect(key)}
            >
              <div className={`accordion-list ${'change-accordion-icon'}`}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Main Applicant</Accordion.Header>

                  <Accordion.Body>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.firstName?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          defaultValue={
                            personalInformation?.mainApplicant?.firstName
                              ? personalInformation?.mainApplicant?.firstName
                              : personalInformation?.sponsoringInformation
                                  ?.firstName
                          }
                          {...register('firstName')}
                        />

                        {errors?.firstName ? (
                          <span className="error-msg">
                            {errors?.firstName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {/* <Work control={control} /> */}
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="midName"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          defaultValue={
                            personalInformation?.mainApplicant?.middleName
                              ? personalInformation?.mainApplicant?.middleName
                              : personalInformation?.sponsoringInformation
                                  ?.middleName
                          }
                          {...register('midName')}
                        />
                        {errors?.midName ? (
                          <span className="error-msg">
                            {errors?.midName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.lName?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="lName"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          defaultValue={
                            personalInformation?.mainApplicant?.middleName
                              ? personalInformation?.mainApplicant?.middleName
                              : personalInformation?.sponsoringInformation
                                  ?.lastName
                          }
                          {...register('lName')}
                        />
                        {errors?.lName ? (
                          <span className="error-msg">
                            {errors?.lName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.gender?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Gender*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          defaultValue={
                            personalInformation?.mainApplicant?.middleName
                              ? personalInformation?.mainApplicant?.middleName
                              : personalInformation?.sponsoringInformation
                                  ?.gender
                          }
                          {...register('gender')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant
                              ? personalInformation?.mainApplicant?.gender
                              : personalInformation?.sponsoringInformation
                                  ?.gender
                              ? personalInformation?.sponsoringInformation
                                  ?.gender
                              : 'Select'}
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Select>
                        {errors?.gender ? (
                          <span className="error-msg">
                            {errors?.gender?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          errors?.dateOfBirth ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Date of Birth*</Form.Label>
                        <DatePicker
                          type="dateOfBirth"
                          name="dateOfBirth"
                          placeholderText="Select Date"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          selected={dateOfBirth}
                          onChange={(date) =>
                            changeDatePicker(date, 'dateOfBirth', 'age')
                          }
                          className="form-select"
                          dateFormat="yyyy-MM-dd"
                        />
                        <input
                          ref={inputRef}
                          value={dateOfBirth}
                          type="hidden"
                          {...register('dateOfBirth')}
                        />
                        {errors?.dateOfBirth ? (
                          <span className="error-msg">
                            {errors?.dateOfBirth?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Phone*</Form.Label>
                        {/* <Form.Control type="email" placeholder="" /> */}
                        <Form.Group>
                          <PhoneInput
                            country={'de'}
                            disabled={mainApplicanDis ? mainApplicanDis : false}
                            value={
                              personalInformation?.mainApplicant?.phoneNumber
                                ? personalInformation?.mainApplicant
                                    ?.phoneNumber
                                : personalInformation?.sponsoringInformation
                                    ?.phoneNumber
                            }
                            onChange={(e) => setValue('phoneNumber', '+' + e)}
                            inputProps={{
                              name: 'phoneNumber',
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Mobile</Form.Label>
                        <PhoneInput
                          country={'de'}
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.middleName
                              ? personalInformation?.mainApplicant?.middleName
                              : personalInformation?.sponsoringInformation
                                  ?.mobile
                          }
                          onChange={(e) => setValue('mobile', '+' + e)}
                          inputProps={{
                            name: 'mobile',
                          }}
                        />
                        {errors?.mobile ? (
                          <span className="error-msg">
                            {errors?.mobile?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Office</Form.Label>
                        <PhoneInput
                          country={'de'}
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant
                              ?.officePhoneNumber
                              ? personalInformation?.mainApplicant
                                  ?.officePhoneNumber
                              : personalInformation?.sponsoringInformation
                                  ?.officePhoneNumber
                          }
                          onChange={(e) =>
                            setValue('officePhoneNumber', '+' + e)
                          }
                          inputProps={{
                            name: 'officePhoneNumber',
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.primaryEmail ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Primary Email*</Form.Label>
                        <Form.Control
                          type="email"
                          name="primaryEmail"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.primaryEmail
                              ? personalInformation?.mainApplicant?.primaryEmail
                              : personalInformation?.sponsoringInformation
                                  ?.primaryEmail
                          }
                          {...register('primaryEmail')}
                        />

                        {errors?.primaryEmail ? (
                          <span className="error-msg">
                            <i class="las la-exclamation-triangle"></i>
                            {errors?.primaryEmail?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Secondary Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="secondaryEmail"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.secondaryEmail
                              ? personalInformation?.mainApplicant
                                  ?.secondaryEmail
                              : personalInformation?.sponsoringInformation
                                  ?.secondaryEmail
                          }
                          {...register('secondaryEmail')}
                        />
                        {errors?.secondaryEmail ? (
                          <span className="error-msg">
                            {errors?.secondaryEmail?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.birthCountry ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Birth Country*</Form.Label>
                        <Form.Select
                          name="birthCountry"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          {...register('birthCountry')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant?.birthCountry
                              ? personalInformation?.mainApplicant?.birthCountry
                              : personalInformation?.sponsoringInformation
                                  ?.birthCountry
                              ? personalInformation?.sponsoringInformation
                                  ?.birthCountry
                              : 'Select'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.birthCountry ? (
                          <span className="error-msg">
                            {errors?.birthCountry?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.countryCitizenship ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Citizenship*</Form.Label>

                        <Form.Select
                          //onChange={handleSelectChange}
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          name="countryCitizenship"
                          {...register('countryCitizenship')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant
                              ?.countryCitizenship
                              ? personalInformation?.mainApplicant
                                  ?.countryCitizenship
                              : personalInformation?.sponsoringInformation
                                  ?.countryCitizenship
                              ? personalInformation?.sponsoringInformation
                                  ?.countryCitizenship
                              : 'Select'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.countryCitizenship ? (
                          <span className="error-msg">
                            {errors?.countryCitizenship?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.countryResidence ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Residence*</Form.Label>
                        <Form.Select
                          //onChange={handleSelectChange}
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          name="countryResidence"
                          {...register('countryResidence')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant
                              ?.countryResidence
                              ? personalInformation?.mainApplicant
                                  ?.countryResidence
                              : personalInformation?.sponsoringInformation
                                  ?.countryResidence
                              ? personalInformation?.sponsoringInformation
                                  ?.countryResidence
                              : 'Select'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.countryResidence ? (
                          <span className="error-msg">
                            {errors?.countryResidence?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.city ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.city
                              ? personalInformation?.mainApplicant?.city
                              : personalInformation?.sponsoringInformation?.city
                          }
                          {...register('city')}
                        />
                        {errors?.city ? (
                          <span className="error-msg">
                            {errors?.city?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.street ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Street*</Form.Label>
                        <Form.Control
                          type="text"
                          name="street"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.street
                              ? personalInformation?.mainApplicant?.street
                              : personalInformation?.sponsoringInformation
                                  ?.street
                          }
                          {...register('street')}
                        />
                        {errors?.street ? (
                          <span className="error-msg">
                            {errors?.street?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.zipCode ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Zip Code / Postal Code*</Form.Label>
                        <Form.Control
                          type="number"
                          name="zipCode"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          value={
                            personalInformation?.mainApplicant?.zipCode
                              ? personalInformation?.mainApplicant?.zipCode
                              : personalInformation?.sponsoringInformation
                                  ?.zipCode
                          }
                          {...register('zipCode')}
                        />
                        {errors?.zipCode ? (
                          <span className="error-msg">
                            {errors?.zipCode?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.maritalStatus ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Marital Status*</Form.Label>
                        <Form.Select
                          // aria-label="Default select example"
                          // onChange={handleSelectChange}
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          name="maritalStatus"
                          {...register('maritalStatus')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant?.maritalStatus
                              ? personalInformation?.mainApplicant
                                  ?.maritalStatus
                              : personalInformation?.sponsoringInformation
                                  ?.maritalStatus
                              ? personalInformation?.sponsoringInformation
                                  ?.maritalStatus
                              : 'Select Status'}
                          </option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Married">
                            Common-Law Partnership{' '}
                            <span>
                              (you are living together for at least 1 year)
                            </span>
                          </option>
                          <option value="Legally Separated">
                            Legally Separated
                          </option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </Form.Select>
                        {errors?.maritalStatus ? (
                          <span className="error-msg">
                            {errors?.maritalStatus?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {watchShowChildrenDetail !== 'Single' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.dependentChildren ? 'error-msg' : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Number of dependent children under 22*
                          </Form.Label>
                          <Form.Select
                            // aria-label="Default select example"
                            //onChange={handleSelectChange}
                            disabled={mainApplicanDis ? mainApplicanDis : false}
                            name="dependentChildren"
                            {...register('dependentChildren')}
                          >
                            <option value="">
                              {personalInformation?.mainApplicant
                                ?.dependentChildren
                                ? personalInformation?.mainApplicant
                                    ?.dependentChildren
                                : personalInformation?.sponsoringInformation
                                    ?.dependentChildren
                                ? personalInformation?.sponsoringInformation
                                    ?.dependentChildren
                                : 'Select children'}
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </Form.Select>
                          {errors?.dependentChildren ? (
                            <span className="error-msg">
                              {errors?.dependentChildren?.message}
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.criminalOffense ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Have you had any criminal offenses lodged against
                          you?*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="criminalOffense"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          {...register('criminalOffense')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant?.criminalOffense
                              ? personalInformation?.mainApplicant
                                  ?.criminalOffense
                              : personalInformation?.sponsoringInformation
                                  ?.criminalOffense
                              ? personalInformation?.sponsoringInformation
                                  ?.criminalOffense
                              : 'Select'}
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.criminalOffense ? (
                          <span className="error-msg">
                            {errors?.criminalOffense?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {(criminalOffenses && criminalOffenses === 'Yes') ||
                      personalInformation?.mainApplicant?.criminalOffense ===
                        'Yes' ||
                      personalInformation?.sponsoringInformation
                        ?.criminalOffense === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            error?.offenseTypeDetail?.message ? 'error-msg' : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Please describe your criminal offenses from the past
                            10 years
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="offenseTypeDetail"
                            disabled={mainApplicanDis ? mainApplicanDis : false}
                            value={
                              personalInformation?.mainApplicant
                                ?.offenseTypeDetail
                                ? personalInformation?.mainApplicant
                                    ?.offenseTypeDetail
                                : personalInformation?.sponsoringInformation
                                    ?.offenseTypeDetail
                            }
                            {...register('offenseTypeDetail')}
                          />
                          {errors?.offenseTypeDetail ? (
                            <span className="error-msg">
                              {errors?.offenseTypeDetail?.message}
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.healthCondition ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Health Problems*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="healthCondition"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          //onChange={handleSelectChange}
                          {...register('healthCondition')}
                        >
                          <option value="">
                            {personalInformation?.mainApplicant?.healthCondition
                              ? personalInformation?.mainApplicant
                                  ?.healthCondition
                              : personalInformation?.sponsoringInformation
                                  ?.healthCondition
                              ? personalInformation?.sponsoringInformation
                                  ?.healthCondition
                              : 'Select'}
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.healthCondition ? (
                          <span className="error-msg">
                            {errors?.healthCondition?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {watchShowHealthDetail === 'Yes' ||
                      personalInformation?.mainApplicant?.healthCondition ===
                        'Yes' ||
                      personalInformation?.sponsoringInformation
                        ?.healthCondition === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.healthConditionDetail ? 'error-msg' : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            please describe your health condition/s
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="healthConditionDetail"
                            disabled={mainApplicanDis ? mainApplicanDis : false}
                            defaultValue={
                              personalInformation?.mainApplicant
                                ?.healthConditionDetail
                                ? personalInformation?.mainApplicant
                                    ?.healthConditionDetail
                                : personalInformation?.sponsoringInformation
                                    ?.healthConditionDetail
                            }
                            {...register('healthConditionDetail')}
                          />
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <div
                className={`accordion-list ${
                  spouseFormState === 'open' ? 'change-accordion-icon' : ''
                }`}
              >
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Add Spouse Information
                    {spouseFormState === 'open' && (
                      <div className="delete-btn">
                        <Button onClick={() => handleDelete('spouseFormState')}>
                          Delete <i class="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sFirstName
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sFirstName"
                          {...register('spouseInformation.sFirstName')}
                          disabled={spouseDis ? spouseDis : false}
                          value={
                            personalInformation?.spouseInfo?.firstName
                              ? personalInformation?.spouseInfo?.firstName
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.firstName
                          }
                        />
                        {errors?.spouseInformation?.sFirstName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sFirstName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="sMidName"
                          disabled={spouseDis ? spouseDis : false}
                          value={
                            personalInformation?.spouseInfo?.middleName
                              ? personalInformation?.spouseInfo?.middleName
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.middleName
                          }
                          {...register('spouseInformation.sMidName')}
                        />
                        {errors?.spouseInformation?.sMidName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sMidName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sLastName
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sLastName"
                          disabled={spouseDis ? spouseDis : false}
                          value={
                            personalInformation?.spouseInfo?.lastName
                              ? personalInformation?.spouseInfo?.lastName
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.lastName
                          }
                          {...register('spouseInformation.sLastName')}
                        />
                        {errors?.spouseInformation?.sLastName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sLastName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sGender ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Gender*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sGender"
                          disabled={spouseDis ? spouseDis : false}
                          // onChange={handleSpouseSelect}
                          {...register('spouseInformation.sGender')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo?.gender
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.gender
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.gender
                              : 'select'}
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sGender ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sGender?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          error?.sDateOfBirth?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Date of Birth*</Form.Label>
                        <DatePicker
                          type="sDateOfBirth"
                          name="sDateOfBirth"
                          placeholderText="Select Date"
                          selected={sDateOfBirth}
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(date) =>
                            changeDatePicker(date, 'sDateOfBirth', 'sAge')
                          }
                          className="form-select"
                        />
                        <input
                          ref={inputRef}
                          value={sDateOfBirth}
                          type="hidden"
                          {...register('spouseInformation.sDateOfBirth')}
                        />
                        {errors?.spouseInformation?.sDateOfBirth ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sDateOfBirth?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Phone*</Form.Label>
                        {/* <Form.Control type="email" placeholder="" /> */}
                        <Form.Group>
                          <PhoneInput
                            country={'de'}
                            value={
                              personalInformation?.spouseInfo?.phoneNumber
                                ? personalInformation?.spouseInfo?.phoneNumber
                                : personalInformation?.sponsoringSpouseInfo
                                    ?.phoneNumber
                            }
                            disabled={spouseDis ? spouseDis : false}
                            onChange={(e) =>
                              setValue(
                                'spouseInformation.sPhoneNumber',
                                '+' + e,
                              )
                            }
                            inputProps={{
                              name: 'sPhoneNumber',
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Mobile</Form.Label>
                        <PhoneInput
                          country={'de'}
                          value={
                            personalInformation?.spouseInfo?.mobile
                              ? personalInformation?.spouseInfo?.mobile
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.mobile
                          }
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(e) =>
                            setValue('spouseInformation.sMobile', '+' + e)
                          }
                          inputProps={{
                            name: 'sMobile',
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Office</Form.Label>
                        <PhoneInput
                          country={'de'}
                          value={
                            personalInformation?.spouseInfo?.officePhoneNumber
                              ? personalInformation?.spouseInfo
                                  ?.officePhoneNumber
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.officePhoneNumber
                          }
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(e) =>
                            setValue(
                              'spouseInformation.sOfficePhoneNumber',
                              '+' + e,
                            )
                          }
                          inputProps={{
                            name: 'sOfficePhoneNumber',
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sPrimaryEmail?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Primary Email*</Form.Label>
                        <Form.Control
                          type="email"
                          name="sPrimaryEmail"
                          disabled={spouseDis ? spouseDis : false}
                          value={
                            personalInformation?.spouseInfo?.primaryEmail
                              ? personalInformation?.spouseInfo?.primaryEmail
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.primaryEmail
                          }
                          {...register('spouseInformation.sPrimaryEmail')}
                        />
                        {errors?.spouseInformation?.sPrimaryEmail ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sPrimaryEmail?.message}
                          </span>
                        ) : (
                          ''
                        )}
                        {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                      </Form.Group>

                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Secondary Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="sSecondaryEmail"
                          disabled={spouseDis ? spouseDis : false}
                          value={
                            personalInformation?.spouseInfo?.secondaryEmail
                              ? personalInformation?.spouseInfo?.secondaryEmail
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.secondaryEmail
                          }
                          {...register('spouseInformation.sSecondaryEmail')}
                        />
                        {errors?.spouseInformation?.sSecondaryEmail ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sSecondaryEmail
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sBirthCountry?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Birth Country*</Form.Label>
                        <Form.Select
                          //onChange={handleSelectChange}
                          disabled={spouseDis ? spouseDis : false}
                          name="sBirthCountry"
                          {...register('spouseInformation.sBirthCountry')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo?.birthCountry
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.birthCounty
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.birthCountry
                              : 'Select country'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sBirthCountry ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sBirthCountry?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCountryCitizenship
                            ?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Citizenship*</Form.Label>
                        <Form.Select
                          //onChange={handleSelectChange}
                          disabled={spouseDis ? spouseDis : false}
                          name="sCountryCitizenship"
                          {...register('spouseInformation.sCountryCitizenship')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryCitizenship
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.countryCitizenship
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.countryCitizenship
                              : 'Select country'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sCountryCitizenship ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sCountryCitizenship
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCountryResidence?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Residence*</Form.Label>
                        <Form.Select
                          //onChange={handleSelectChange}
                          name="sCountryResidence"
                          disabled={spouseDis ? spouseDis : false}
                          {...register('spouseInformation.sCountryResidence')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryResidence
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.countryResidence
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.countryResidence
                              : 'Select country'}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sCountryResidence ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation.sCountryResidence
                                .message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCity?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sCity"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.city
                              ? personalInformation?.spouseInfo?.city
                              : personalInformation?.sponsoringSpouseInfo?.city
                          }
                          {...register('spouseInformation.sCity')}
                        />
                        {errors?.spouseInformation?.sCity ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sCity?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sStreet?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Street*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sStreet"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.street
                              ? personalInformation?.spouseInfo?.street
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.street
                          }
                          {...register('spouseInformation.sStreet')}
                        />
                        {errors?.spouseInformation?.sStreet ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sStreet.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sZipCode?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Zip Code / Postal Code*</Form.Label>
                        <Form.Control
                          type="number"
                          name="sZipCode"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.zipCode
                              ? personalInformation?.spouseInfo?.zipCode
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.zipCode
                          }
                          {...register('spouseInformation.sZipCode')}
                        />
                        {errors?.spouseInformation?.sZipCode ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sZipCode?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sMaritalStatus?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Marital Status*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sMaritalStatus"
                          disabled={spouseDis ? spouseDis : false}
                          // onChange={handleSpouseSelect}
                          {...register('spouseInformation.sMaritalStatus')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo?.maritalStatus
                              ? personalInformation?.spouseInfo?.maritalStatus
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.maritalStatus
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.maritalStatus
                              : 'Select'}
                          </option>
                          <option value="Single">Single</option>
                          <option value="2">Married</option>
                          <option value="Married">
                            Common-Law Partnership{' '}
                            <span>
                              (you are living together for at least 1 year)
                            </span>
                          </option>
                          <option value="Legally Separated">
                            Legally Separated
                          </option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sMaritalStatus ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sMaritalStatus.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {spouseShowChildrenDetail !== 'Single' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.spouseInformation?.sDependentChildren
                              ?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Number of dependent children under 22*
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="sDependentChildren"
                            disabled={spouseDis ? spouseDis : false}
                            //onChange={handleSpouseSelect}

                            {...register(
                              'spouseInformation.sDependentChildren',
                            )}
                          >
                            <option value="">
                              {personalInformation?.spouseInfo
                                ?.dependentChildren
                                ? personalInformation?.spouseInfo
                                    ?.dependentChildren
                                : personalInformation?.sponsoringSpouseInfo
                                    ?.dependentChildren
                                ? personalInformation?.sponsoringSpouseInfo
                                    ?.dependentChildren
                                : 'Select'}
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </Form.Select>
                          {errors?.spouseInformation?.sDependentChildren ? (
                            <span className="error-msg">
                              {
                                errors?.spouseInformation?.sDependentChildren
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCriminalOffense?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Have you had any criminal offenses lodged against
                          you?*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sCriminalOffense"
                          disabled={spouseDis ? spouseDis : false}
                          // onChange={handleSpouseSelect}
                          {...register('spouseInformation.sCriminalOffense')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo?.criminalOffense
                              ? personalInformation?.spouseInfo?.criminalOffense
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.criminalOffense
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.criminalOffense
                              : 'Select'}
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sCriminalOffense ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sCriminalOffense.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {sposeCriminalOffense === 'Yes' ||
                      personalInformation?.spouseInfo?.criminalOffense ===
                        'Yes' ||
                      personalInformation?.sponsoringSpouseInfo
                        ?.criminalOffense === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            error?.sOffenseTypeDetail?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Please describe your criminal offenses from the past
                            10 years
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="sOffenseTypeDetail"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={
                              personalInformation?.spouseInfo?.offenseTypeDetail
                                ? personalInformation?.spouseInfo
                                    ?.offenseTypeDetail
                                : personalInformation?.sponsoringSpouseInfo
                                    ?.offenseTypeDetail
                            }
                            {...register(
                              'spouseInformation.sOffenseTypeDetail',
                            )}
                          />
                          {errors?.spouseInformation?.sOffenseTypeDetail ? (
                            <span className="error-msg">
                              {
                                errors?.spouseInformation.sOffenseTypeDetail
                                  .message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sHealthCondition?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Health Problems*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sHealthCondition"
                          disabled={spouseDis ? spouseDis : false}
                          //onChange={handleSpouseSelect}
                          {...register('spouseInformation.sHealthCondition')}
                        >
                          <option value="">
                            {personalInformation?.spouseInfo?.healthCondition
                              ? personalInformation?.spouseInfo?.healthCondition
                              : personalInformation?.sponsoringSpouseInfo
                                  ?.healthCondition
                              ? personalInformation?.sponsoringSpouseInfo
                                  ?.healthCondition
                              : 'Select'}
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sHealthCondition ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sHealthCondition.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {(spouseHealthCondition &&
                        spouseHealthCondition === 'Yes') ||
                      personalInformation?.sponsoringSpouseInfo
                        ?.healthCondition === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.spouseInformation?.sHealthConditionDetail
                              ?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            If yes, please describe your health condition/s
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="sHealthConditionDetail"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={
                              personalInformation?.spouseInfo
                                ?.healthConditionDetail
                                ? personalInformation?.spouseInfo
                                    ?.healthConditionDetail
                                : personalInformation?.sponsoringSpouseInfo
                                    ?.healthConditionDetail
                            }
                            {...register(
                              'spouseInformation.sHealthConditionDetail',
                            )}
                          />
                          {errors?.spouseInformation?.sHealthConditionDetail ? (
                            <span className="error-msg">
                              {
                                errors?.spouseInformation.sHealthConditionDetail
                                  .message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <div
                className={`accordion-list ${
                  childrenFormState === 'open' ? 'change-accordion-icon' : ''
                }`}
              >
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Add Children Information
                    {childrenFormState === 'open' && (
                      <div className="delete-btn">
                        <Button
                          onClick={() => handleDelete('childrenFormState')}
                        >
                          Delete <i class="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </Accordion.Header>
                  {childs &&
                    childs?.length > 0 &&
                    childs?.map((child, i) => (
                      <Accordion.Body key={i}>
                        <h6 className="child-heading">Child #{i + 1}</h6>
                        <div className="child-info">
                          <div className="box-grid-three">
                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}]?.childFirstName`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>First Name*</Form.Label>
                              <Form.Control
                                type="text"
                                name="childFirstName"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                defaultValue={child?.childFirstName}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {error && error[`[${i}].childFirstName`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childFirstName`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Middle Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="childMiddleName"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                // {...register('childMiddleName', {
                                //   required: 'Middle name is required',
                                // })}
                                defaultValue={child?.childMiddleName}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {error && error[`[${i}].childMiddleName`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childMiddleName`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="childLastName"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                defaultValue={child?.childLastName}
                                onChange={(e) => handleChange(e, i)}
                                // {...register('childLastName', {
                                //   required: 'Last name is required',
                                // })}
                                // defaultValue={child.childLastName}
                              />
                              {error && error[`[${i}].childLastName`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childLastName`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}]?.childGender`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Gender*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="childGender"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                defaultValue={child?.childGender}
                                onChange={(e) => handleChange(e, i)}
                                // defaultValue={child.childGender}
                                // {...register('childGender', {
                                //   required: 'Gender is required',
                                // })}
                              >
                                <option value="">
                                  {childrenInfoDis
                                    ? child?.childGender
                                    : 'Select Gender'}
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Form.Select>
                              {error && error[`[${i}].childGender`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childGender`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}]?.childDateOfBirth`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Date of Birth*</Form.Label>
                              <DatePicker
                                type="childDateOfBirth"
                                name="childDateOfBirth"
                                placeholderText="Select Date"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                selected={
                                  child?.childDateOfBirth
                                    ? new Date(child?.childDateOfBirth)
                                    : new Date()
                                }
                                onChange={(date) =>
                                  handleChildDatePicker(date, i)
                                }
                                className="form-select"
                              />
                              {/* <input
                        ref={inputRef}
                        defaultValue={childValues.dateOfBirth}
                        type="hidden"
                        {...register('dateOfBirth')}
                      /> */}
                              {error && error[`[${i}]?.childDateOfBirth`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childDateOfBirth`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}]?.childBirthCountry`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Birth Country*</Form.Label>
                              <Form.Select
                                //onChange={handleSelectChange}
                                name="childBirthCountry"
                                //value={values.childGender}
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                onChange={(e) => handleChange(e, i)}

                                // {...register('childBirthCountry', {
                                //   required: 'Birth country is required',
                                // })}
                              >
                                <option value="">
                                  {childrenInfoDis
                                    ? child?.childBirthCountry
                                    : 'Select country'}
                                </option>
                                {countries &&
                                  countries?.length > 0 &&
                                  countries.map((el) => (
                                    <option value={el?.name}>{el?.name}</option>
                                  ))}
                              </Form.Select>
                              {error && error[`[${i}].childBirthCountry`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childBirthCountry`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}]?.childBirthCity`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Birth City*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="childBirthCity"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                onChange={(e) => handleChange(e, i)}
                                defaultValue={child?.childBirthCity}
                              />
                              {error && error[`[${i}]?.childBirthCity`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childBirthCity`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}].childCitizenship`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Citizenship*</Form.Label>
                              <Form.Select
                                name="childCitizenship"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                onChange={(e) => handleChange(e, i)}
                              >
                                <option value="">
                                  {childrenInfoDis
                                    ? child?.childCitizenship
                                    : 'Select Country'}
                                </option>
                                {countries &&
                                  countries?.length > 0 &&
                                  countries.map((el) => (
                                    <option value={el?.name}>{el?.name}</option>
                                  ))}
                              </Form.Select>
                              {error && error[`[${i}].childCitizenship`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childCitizenship`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group ${
                                error && error[`[${i}].childBirthCountry`]
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Second Citizenship*</Form.Label>
                              <Form.Select
                                name="childSecondCitizenship"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                                onChange={(e) => handleChange(e, i)}
                              >
                                <option value="">
                                  {childrenInfoDis
                                    ? child?.childSecondCitizenship
                                    : 'Select Country'}
                                </option>
                                {countries &&
                                  countries?.length > 0 &&
                                  countries.map((el) => (
                                    <option value={el?.name}>{el?.name}</option>
                                  ))}
                              </Form.Select>
                              {error &&
                              error[`[${i}].childSecondCitizenship`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childSecondCitizenship`]}
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div
                            className="add-btn"
                            disabled={childrenInfoDis ? childrenInfoDis : false}
                          >
                            {i >= 0 &&
                              (!childrenInfoDis ? (
                                <Button
                                  className="delete-btn"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  onClick={() => removeChild(i)}
                                >
                                  {`Delete Child #${i + 1}`}{' '}
                                  <i class="las la-minus"></i>
                                </Button>
                              ) : (
                                ''
                              ))}
                            <Button
                              onClick={handleAddChild}
                              disabled={
                                childrenInfoDis ? childrenInfoDis : false
                              }
                            >
                              Add <i className="las la-plus"></i>
                            </Button>
                          </div>
                        </div>
                      </Accordion.Body>
                    ))}
                </Accordion.Item>
              </div>
            </Accordion>
          </>
        ) : (
          <Accordion
            defaultActiveKey="3"
            onSelect={(key) => changeAccordionselect(key)}
          >
            <div
              className={`accordion-list ${
                sponsoredApplicantFormState === 'open'
                  ? 'change-accordion-icon'
                  : ''
              }`}
            >
              <Accordion.Item eventKey="3">
                <Accordion.Header>Sponsored Applicant</Accordion.Header>
                {sponsoredApplicantFormState === 'open' && (
                  <div className="delete-btn">
                    <Button
                      onClick={() =>
                        handleDelete('sponsoredApplicantFormState')
                      }
                    >
                      Delete <i class="las la-minus"></i>
                    </Button>
                  </div>
                )}
                <Accordion.Body>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorFirstName?.message ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sponsorFirstName"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.firstName
                        }
                        {...register('sponsorFirstName')}
                      />

                      {errors?.sponsorFirstName ? (
                        <span className="error-msg">
                          {errors?.sponsorFirstName?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {/* <Work control={control} /> */}
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="sponsorMidName"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.middleName
                            ? personalInformation?.sponsoredInformation
                                ?.middleName
                            : ''
                        }
                        {...register('sponsorMidName')}
                      />
                      {errors?.sponsorMidName ? (
                        <span className="error-msg">
                          {errors?.sponsorMidName?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorLName?.message ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sponsorLName"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.lastName
                            ? personalInformation?.sponsoredInformation
                                ?.lastName
                            : ''
                        }
                        {...register('sponsorLName')}
                      />
                      {errors?.sponsorLName ? (
                        <span className="error-msg">
                          {errors?.sponsorLName?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorGender?.message ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sponsorGender"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.gender
                        }
                        {...register('sponsorGender')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ? personalInformation?.sponsoredInformation?.gender
                            : 'Select'}
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Select>
                      {errors?.sponsorGender ? (
                        <span className="error-msg">
                          {errors?.sponsorGender?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        errors?.dateOfBirth ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Date of Birth*</Form.Label>
                      <DatePicker
                        type="sponsorDateOfBirth"
                        name="sponsorDateOfBirth"
                        placeholderText="Select Date"
                        disabled={sponsorDis ? sponsorDis : false}
                        selected={sponsoredDateBirth}
                        onChange={(date) =>
                          changeDatePicker(
                            date,
                            'sponsorDateOfBirth',
                            'sponsoredAge',
                          )
                        }
                        className="form-select"
                        dateFormat="yyyy-MM-dd"
                      />
                      <input
                        ref={inputRef}
                        value={dateOfBirth}
                        type="hidden"
                        {...register('dateOfBirth')}
                      />
                      {errors?.dateOfBirth ? (
                        <span className="error-msg">
                          {errors?.dateOfBirth?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Phone*</Form.Label>
                      {/* <Form.Control type="email" placeholder="" /> */}
                      <Form.Group>
                        <PhoneInput
                          country={'de'}
                          disabled={sponsorDis ? sponsorDis : false}
                          value={
                            personalInformation?.sponsoredInformation
                              ?.phoneNumber
                          }
                          onChange={(e) =>
                            setValue('sponsorPhoneNumber', '+' + e)
                          }
                          inputProps={{
                            name: 'sponsorPhoneNumber',
                          }}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <PhoneInput
                        country={'de'}
                        disabled={sponsorDis ? sponsorDis : false}
                        value={
                          personalInformation?.sponsoredInformation?.mobile
                        }
                        onChange={(e) => setValue('sponsorMobile', '+' + e)}
                        inputProps={{
                          name: 'sponsorMobile',
                        }}
                      />
                      {errors?.sponsorMobile ? (
                        <span className="error-msg">
                          {errors?.sponsorMobile?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Office</Form.Label>
                      <PhoneInput
                        country={'de'}
                        disabled={sponsorDis ? sponsorDis : false}
                        value={
                          personalInformation?.sponsoredInformation
                            ?.officePhoneNumber
                        }
                        onChange={(e) =>
                          setValue('sponsorOfficePhoneNumber', '+' + e)
                        }
                        inputProps={{
                          name: 'sponsorOfficePhoneNumber',
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorPrimaryEmail ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Primary Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="sponsorPrimaryEmail"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation
                            ?.primaryEmail
                            ? personalInformation?.sponsoredInformation
                                ?.primaryEmail
                            : ''
                        }
                        {...register('sponsorPrimaryEmail')}
                      />
                      {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                      {errors?.sponsorPrimaryEmail ? (
                        <span className="error-msg">
                          <i class="las la-exclamation-triangle"></i>
                          {errors?.sponsorPrimaryEmail?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Secondary Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="sponsorSecondaryEmail"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation
                            ?.secondaryEmail
                        }
                        {...register('sponsorSecondaryEmail')}
                      />
                      {errors?.secondaryEmail ? (
                        <span className="error-msg">
                          {errors?.secondaryEmail?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorBirthCountry ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Birth Country*</Form.Label>
                      <Form.Select
                        name="sponsorBirthCountry"
                        disabled={sponsorDis ? sponsorDis : false}
                        {...register('sponsorBirthCountry')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.birthCountry
                            ? personalInformation?.sponsoredInformation
                                ?.birthCountry
                            : 'Select Country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorBirthCountry ? (
                        <span className="error-msg">
                          {errors?.sponsorBirthCountry?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorCountryCitizenship ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Citizenship*</Form.Label>

                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={sponsorDis ? sponsorDis : false}
                        name="sponsorCountryCitizenship"
                        {...register('sponsorCountryCitizenship')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.countryCitizenship
                            ? personalInformation?.sponsoredInformation
                                ?.countryCitizenship
                            : 'Select Country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorCountryCitizenship ? (
                        <span className="error-msg">
                          {errors?.sponsorCountryCitizenship?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorCountryResidence ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Residence*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={sponsorDis ? sponsorDis : false}
                        name="sponsorCountryResidence"
                        {...register('sponsorCountryResidence')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.countryResidence
                            ? personalInformation?.sponsoredInformation
                                ?.countryResidence
                            : 'Select Country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorCountryResidence ? (
                        <span className="error-msg">
                          {errors?.sponsorCountryResidence?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorCity ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>City*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sponsorCity"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.city
                            ? personalInformation?.sponsoredInformation?.city
                            : ''
                        }
                        {...register('sponsorCity')}
                      />
                      {errors?.sponsorCity ? (
                        <span className="error-msg">
                          {errors?.sponsorCity?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorStreet ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Street*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sponsorStreet"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.street
                            ? personalInformation?.sponsoredInformation?.street
                            : ''
                        }
                        {...register('sponsorStreet')}
                      />
                      {errors?.sponsorStreet ? (
                        <span className="error-msg">
                          {errors?.sponsorStreet?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorZipCode ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Zip Code / Postal Code*</Form.Label>
                      <Form.Control
                        type="number"
                        name="sponsorZipCode"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredInformation?.zipCode
                            ? personalInformation?.sponsoredInformation?.zipCode
                            : ''
                        }
                        {...register('sponsorZipCode')}
                      />
                      {errors?.sponsorZipCode ? (
                        <span className="error-msg">
                          {errors?.sponsorZipCode?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorMaritalStatus ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Marital Status*</Form.Label>
                      <Form.Select
                        // aria-label="Default select example"
                        // onChange={handleSelectChange}
                        disabled={sponsorDis ? sponsorDis : false}
                        name="sponsorMaritalStatus"
                        {...register('sponsorMaritalStatus')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.maritalStatus
                            ? personalInformation?.sponsoredInformation
                                ?.maritalStatus
                            : 'Select Status'}
                        </option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Married">
                          Common-Law Partnership{' '}
                          <span>
                            (you are living together for at least 1 year)
                          </span>
                        </option>
                        <option value="Legally Separated">
                          Legally Separated
                        </option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </Form.Select>
                      {errors?.sponsorMaritalStatus ? (
                        <span className="error-msg">
                          {errors?.sponsorMaritalStatus?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {watchShowChildrenDetail !== 'Single' ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.sponsorDependentChildren ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Number of dependent children under 22*
                        </Form.Label>
                        <Form.Select
                          // aria-label="Default select example"
                          //onChange={handleSelectChange}
                          disabled={sponsorDis ? sponsorDis : false}
                          name="sponsorDependentChildren"
                          {...register('sponsorDependentChildren')}
                        >
                          <option value="">
                            {personalInformation?.sponsoredInformation
                              ?.dependentChildren
                              ? personalInformation?.sponsoredInformation
                                  ?.dependentChildren
                              : 'Select dependent children'}
                          </option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select>
                        {errors?.sponsorDependentChildren ? (
                          <span className="error-msg">
                            {errors?.sponsorDependentChildren?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorCriminalOffense ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Have you had any criminal offenses lodged against you?*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sponsorCriminalOffense"
                        disabled={sponsorDis ? sponsorDis : false}
                        {...register('sponsorCriminalOffense')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.criminalOffense
                            ? personalInformation?.sponsoredInformation
                                ?.criminalOffense
                            : 'Select Criminal offense'}
                        </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.sponsorCriminalOffense ? (
                        <span className="error-msg">
                          {errors?.sponsorCriminalOffense?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {sponsorCriminalOffense === 'Yes' ||
                    personalInformation?.sponsoredInformation
                      ?.criminalOffense === 'Yes' ? (
                      <Form.Group
                        className={`form-group ${
                          error?.offenseTypeDetail?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Please describe your criminal offenses from the past
                          10 years
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sponsorOffenseTypeDetail"
                          disabled={sponsorDis ? sponsorDis : false}
                          defaultValue={
                            personalInformation?.sponsoredInformation
                              ?.offenseTypeDetail
                          }
                          {...register('sponsorOffenseTypeDetail')}
                        />
                        {errors?.sponsorOffenseTypeDetail ? (
                          <span className="error-msg">
                            {errors?.sponsorOffenseTypeDetail?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorHealthCondition ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Health Problems*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sponsorHealthCondition"
                        disabled={sponsorDis ? sponsorDis : false}
                        //onChange={handleSelectChange}
                        {...register('sponsorHealthCondition')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredInformation
                            ?.healthCondition
                            ? personalInformation?.sponsoredInformation
                                ?.healthCondition
                            : 'Select health condition'}
                        </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.sponsorHealthCondition ? (
                        <span className="error-msg">
                          {errors?.sponsorHealthCondition?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {showHelthCondetionDetail === 'Yes' ||
                    personalInformation?.sponsoredInformation
                      ?.healthCondition === 'Yes' ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.healthConditionDetail ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          please describe your health condition/s
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sponsorHealthConditionDetail"
                          disabled={sponsorDis ? sponsorDis : false}
                          defaultValue={
                            personalInformation?.sponsoredInformation
                              ?.healthConditionDetail
                          }
                          {...register('sponsorHealthConditionDetail')}
                        />
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div
              className={`accordion-list ${
                sponsorSpouseFormState === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Add Spouse Information
                  {sponsorSpouseFormState === 'open' && (
                    <div className="delete-btn">
                      <Button
                        onClick={() => handleDelete('sponsorSpouseFormState')}
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sFirstName
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>First Name*</Form.Label>

                      <Form.Control
                        type="text"
                        name="sFirstName"
                        {...register('sponsorSpouseInformation.sFirstName')}
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.firstName
                        }
                      />
                      {errors?.sponsorSpouseInformation?.sFirstName ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sFirstName
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="sMidName"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.middleName
                        }
                        {...register('sponsorSpouseInformation.sMidName')}
                      />
                      {errors?.sponsorSpouseInformation?.sMidName ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation?.sMidName?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sLastName
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sLastName"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.lastName
                        }
                        {...register('sponsorSpouseInformation.sLastName')}
                      />
                      {errors?.sponsorSpouseInformation?.sLastName ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation?.sLastName?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sGender
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sGender"
                        disabled={sponsorDis ? sponsorDis : false}
                        // onChange={handleSpouseSelect}
                        {...register('sponsorSpouseInformation.sGender')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ? personalInformation?.sponsoredSpouseInfo?.gender
                            : 'Select'}
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sGender ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation?.sGender?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sDateOfBirth?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Date of Birth*</Form.Label>
                      <DatePicker
                        type="sDateOfBirth"
                        name="sDateOfBirth"
                        placeholderText="Select Date"
                        selected={sponsoredSDateOfBirth}
                        disabled={sponsorDis ? sponsorDis : false}
                        onChange={(date) =>
                          changeDatePicker(
                            date,
                            'sponsorSDateOfBirth',
                            'sponsorSAge',
                          )
                        }
                        className="form-select"
                      />
                      <input
                        ref={inputRef}
                        value={sDateOfBirth}
                        type="hidden"
                        {...register('sponsorSpouseInformation.sDateOfBirth')}
                      />
                      {errors?.sponsorSpouseInformation?.sDateOfBirth ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sDateOfBirth
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Phone*</Form.Label>
                      {/* <Form.Control type="email" placeholder="" /> */}
                      <Form.Group>
                        <PhoneInput
                          country={'de'}
                          value={
                            personalInformation?.sponsoredSpouseInfo
                              ?.phoneNumber
                          }
                          disabled={sponsorDis ? sponsorDis : false}
                          onChange={(e) => setValue('sPhoneNumber', '+' + e)}
                          inputProps={{
                            name: 'sPhoneNumber',
                          }}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <PhoneInput
                        country={'de'}
                        value={personalInformation?.sponsoredSpouseInfo?.mobile}
                        disabled={sponsorDis ? sponsorDis : false}
                        onChange={(e) => setValue('sMobile', '+' + e)}
                        inputProps={{
                          name: 'sMobile',
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Office</Form.Label>
                      <PhoneInput
                        country={'de'}
                        value={
                          personalInformation?.sponsoredSpouseInfo
                            ?.officePhoneNumber
                        }
                        disabled={sponsorDis ? sponsorDis : false}
                        onChange={(e) =>
                          setValue('sOfficePhoneNumber', '+' + e)
                        }
                        inputProps={{
                          name: 'sOfficePhoneNumber',
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sPrimaryEmail?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Primary Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="sPrimaryEmail"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.primaryEmail
                        }
                        {...register('sponsorSpouseInformation.sPrimaryEmail')}
                      />
                      {errors?.sponsorSpouseInformation?.sPrimaryEmail ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sPrimaryEmail
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                      {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                    </Form.Group>

                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Secondary Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="sSecondaryEmail"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo
                            ?.secondaryEmail
                        }
                        {...register(
                          'sponsorSpouseInformation.sSecondaryEmail',
                        )}
                      />
                      {errors?.sponsorSpouseInformation?.sSecondaryEmail ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sSecondaryEmail
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sBirthCountry?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Birth Country*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={sponsorDis ? sponsorDis : false}
                        name="sBirthCountry"
                        {...register('sponsorSpouseInformation.sBirthCountry')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.birthCountry
                            : 'Select country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sBirthCountry ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sBirthCountry?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sCountryCitizenship
                          ?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Citizenship*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={sponsorDis ? sponsorDis : false}
                        name="sCountryCitizenship"
                        {...register(
                          'sponsorSpouseInformation.sCountryCitizenship',
                        )}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryCitizenship
                            : 'Select country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorSpouseInformation
                        ?.sponsorSCountryCitizenship ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation
                              ?.sponsorSCountryCitizenship?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sCountryResidence
                          ?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Residence*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        name="sponsorSCountryResidence"
                        disabled={sponsorDis ? sponsorDis : false}
                        {...register(
                          'sponsorSpouseInformation.sCountryResidence',
                        )}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryResidence
                            : 'Select country'}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sCountryResidence ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation.sCountryResidence
                              .message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sCity?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>City*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sSCity"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.city
                        }
                        {...register('sponsorSpouseInformation.sCity')}
                      />
                      {errors?.sponsorSpouseInformation?.sCity ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation?.sCity?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sStreet?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Street*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sStreet"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.street
                        }
                        {...register('sponsorSpouseInformation.sStreet')}
                      />
                      {errors?.sponsorSpouseInformation?.sStreet ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation.sStreet.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sZipCode?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Zip Code / Postal Code*</Form.Label>
                      <Form.Control
                        type="number"
                        name="sZipCode"
                        disabled={sponsorDis ? sponsorDis : false}
                        defaultValue={
                          personalInformation?.sponsoredSpouseInfo?.zipCode
                        }
                        {...register('sponsorSpouseInformation.sZipCode')}
                      />
                      {errors?.sponsorSpouseInformation?.sZipCode ? (
                        <span className="error-msg">
                          {errors?.sponsorSpouseInformation?.sZipCode?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sMaritalStatus
                          ?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Marital Status*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sMaritalStatus"
                        disabled={sponsorDis ? sponsorDis : false}
                        {...register('sponsorSpouseInformation.sMaritalStatus')}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ?.maritalStatus
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.maritalStatus
                            : 'Select Status'}
                        </option>
                        <option value="Single">Single</option>
                        <option value="2">Married</option>
                        <option value="Married">
                          Common-Law Partnership{' '}
                          <span>
                            (you are living together for at least 1 year)
                          </span>
                        </option>
                        <option value="Legally Separated">
                          Legally Separated
                        </option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sMaritalStatus ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation.sMaritalStatus
                              .message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {spouseShowChildrenDetail !== 'Single' ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.sponsorSpouseInformation?.sDependentChildren
                            ?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Number of dependent children under 22*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sDependentChildren"
                          disabled={sponsorDis ? sponsorDis : false}
                          //onChange={handleSpouseSelect}

                          {...register(
                            'sponsorSpouseInformation.sDependentChildren',
                          )}
                        >
                          <option value="">
                            {personalInformation?.sponsoredSpouseInfo
                              ?.dependentChildren
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.dependentChildren
                              : 'Select Children'}
                          </option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select>
                        {errors?.sponsorSpouseInformation
                          ?.sDependentChildren ? (
                          <span className="error-msg">
                            {
                              errors?.sponsorSpouseInformation
                                ?.sDependentChildren?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sCriminalOffense
                          ?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Have you had any criminal offenses lodged against you?*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sCriminalOffense"
                        disabled={sponsorDis ? sponsorDis : false}
                        // onChange={handleSpouseSelect}
                        {...register(
                          'sponsorSpouseInformation.sCriminalOffense',
                        )}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ?.criminalOffense
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.criminalOffense
                            : 'Select criminal offense'}
                        </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sCriminalOffense ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sCriminalOffense
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {sponsorSpouseOffenceTypeDetail === 'Yes' ||
                    personalInformation?.sponsoredSpouseInfo
                      ?.criminalOffense === 'Yes' ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.sponsorSpouseInformation?.sOffenseTypeDetail
                            ?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Please describe your criminal offenses from the past
                          10 years
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sOffenseTypeDetail"
                          disabled={sponsorDis ? sponsorDis : false}
                          value={
                            personalInformation?.sponsoredSpouseInfo
                              ?.offenseTypeDetail
                          }
                          {...register(
                            'sponsorSpouseInformation.sOffenseTypeDetail',
                          )}
                        />
                        {errors?.sponsorSpouseInformation
                          ?.sOffenseTypeDetail ? (
                          <span className="error-msg">
                            {
                              errors?.sponsorSpouseInformation
                                ?.sOffenseTypeDetail?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.sponsorSpouseInformation?.sHealthCondition
                          ?.message
                          ? 'error-msg'
                          : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Health Problems*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sHealthCondition"
                        disabled={sponsorDis ? sponsorDis : false}
                        //onChange={handleSpouseSelect}
                        {...register(
                          'sponsorSpouseInformation.sHealthCondition',
                        )}
                      >
                        <option value="">
                          {personalInformation?.sponsoredSpouseInfo
                            ?.healthCondition
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.healthCondition
                            : 'Select criminal offense'}
                        </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.sponsorSpouseInformation?.sHealthCondition ? (
                        <span className="error-msg">
                          {
                            errors?.sponsorSpouseInformation?.sHealthCondition
                              ?.message
                          }
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {sponsorSpouseHealthDetail === 'Yes' ||
                    personalInformation?.sponsoredSpouseInfo
                      ?.healthCondition === 'Yes' ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.sponsorSpouseInformation
                            ?.sHealthConditionDetail?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          If yes, please describe your health condition/s
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sHealthConditionDetail"
                          disabled={sponsorDis ? sponsorDis : false}
                          defaultValue={
                            personalInformation?.sponsoredSpouseInfo
                              ?.healthConditionDetail
                          }
                          {...register(
                            'sponsorSpouseInformation.sHealthConditionDetail',
                          )}
                        />
                        {errors?.sponsorSpouseInformation
                          ?.sHealthConditionDetail ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sHealthConditionDetail
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div
              className={`accordion-list ${
                sponsorChildrenFormState === 'open'
                  ? 'change-accordion-icon'
                  : ''
              }`}
            >
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Add Children Information of Sponsored
                  {sponsorChildrenFormState === 'open' && (
                    <div className="delete-btn">
                      <Button
                        onClick={() => handleDelete('sponsorChildrenFormState')}
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </Accordion.Header>
                {sponsorChilds &&
                  sponsorChilds?.length > 0 &&
                  sponsorChilds?.map((child, i) => (
                    <Accordion.Body key={i}>
                      <h6 className="child-heading">Child #{i + 1}</h6>
                      <div className="child-info">
                        <div className="box-grid-three">
                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}]?.childFirstName`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>First Name*</Form.Label>
                            <Form.Control
                              type="text"
                              name="childFirstName"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={child?.childFirstName}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            />
                            {error && error[`[${i}].childFirstName`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childFirstName`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="childMiddleName"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={child?.childMiddleName}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            />
                            {error && error[`[${i}].childMiddleName`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childMiddleName`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="childLastName"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={child?.childLastName}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            />
                            {error && error[`[${i}].childLastName`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childLastName`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}]?.childGender`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Gender*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="childGender"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={child?.childGender}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            >
                              <option value="">
                                {sponsorDis
                                  ? child?.childGender
                                  : 'Select Gender'}
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Form.Select>
                            {error && error[`[${i}].childGender`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childGender`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>

                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}]?.childDateOfBirth`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Date of Birth*</Form.Label>
                            <DatePicker
                              type="childDateOfBirth"
                              name="childDateOfBirth"
                              placeholderText="Select Date"
                              disabled={sponsorDis ? sponsorDis : false}
                              selected={
                                child?.childDateOfBirth
                                  ? new Date(child?.childDateOfBirth)
                                  : new Date()
                              }
                              onChange={(date) =>
                                handleSponsorChildDatePicker(date, i)
                              }
                              className="form-select"
                            />

                            {error && error[`[${i}]?.childDateOfBirth`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childDateOfBirth`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}]?.childBirthCountry`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Birth Country*</Form.Label>
                            <Form.Select
                              name="childBirthCountry"
                              disabled={sponsorDis ? sponsorDis : false}
                              onChange={(e) => handleSponsoredChange(e, i)}
                              defaultValue={child?.childBirthCountry}
                            >
                              <option value="">
                                {sponsorDis
                                  ? child?.childBirthCountry
                                  : 'Select country'}
                              </option>
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((el) => (
                                  <option value={el?.name}>{el?.name}</option>
                                ))}
                            </Form.Select>
                            {error && error[`[${i}].childBirthCountry`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childBirthCountry`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>

                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}]?.childBirthCity`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Birth City*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="childBirthCity"
                              disabled={sponsorDis ? sponsorDis : false}
                              onChange={(e) => handleSponsoredChange(e, i)}
                              defaultValue={child?.childBirthCity}
                            />
                            {error && error[`[${i}]?.childBirthCity`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childBirthCity`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}].childCitizenship`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Citizenship*</Form.Label>
                            <Form.Select
                              name="childCitizenship"
                              disabled={sponsorDis ? sponsorDis : false}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            >
                              <option value="">
                                {sponsorDis
                                  ? child?.childCitizenship
                                  : 'Select Country'}
                              </option>
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((el) => (
                                  <option value={el?.name}>{el?.name}</option>
                                ))}
                            </Form.Select>
                            {error && error[`[${i}].childCitizenship`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childCitizenship`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>

                          <Form.Group
                            className={`form-group ${
                              error && error[`[${i}].childBirthCountry`]
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Second Citizenship*</Form.Label>
                            <Form.Select
                              name="childSecondCitizenship"
                              disabled={sponsorDis ? sponsorDis : false}
                              onChange={(e) => handleSponsoredChange(e, i)}
                            >
                              <option value="">
                                {sponsorDis
                                  ? child?.childSecondCitizenship
                                  : 'Select Country'}
                              </option>
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((el) => (
                                  <option value={el?.name}>{el?.name}</option>
                                ))}
                            </Form.Select>
                            {error && error[`[${i}].childSecondCitizenship`] ? (
                              <span className="error-msg">
                                {error[`[${i}].childSecondCitizenship`]}
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </div>
                        <div
                          className="add-btn"
                          disabled={sponsorDis ? sponsorDis : false}
                        >
                          {i >= 0 &&
                            (!sponsorDis ? (
                              <Button
                                className="delete-btn"
                                disabled={sponsorDis ? sponsorDis : false}
                                onClick={() => removeSponsoredChild(i)}
                              >
                                {`Delete Child #${i + 1}`}{' '}
                                <i class="las la-minus"></i>
                              </Button>
                            ) : (
                              ''
                            ))}
                          <Button
                            onClick={handleAddSponsorChild}
                            disabled={sponsorDis ? sponsorDis : false}
                          >
                            Add <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </Accordion.Body>
                  ))}
              </Accordion.Item>
            </div>
          </Accordion>
        )}

        {/* </Accordion> */}
        <div className="visa-btn">
          {sponsoredShow ? (
            <Button
              type="submit"
              onClick={handleSubmit(submitPersonalInfo)}
              disabled={sponsorDis ? sponsorDis : false}
            >
              Save
            </Button>
          ) : (
            <Button
              type="submit"
              onClick={handleSubmit(submitPersonalInfo)}
              disabled={mainApplicanDis ? mainApplicanDis : false}
            >
              Save
            </Button>
          )}

          <div className="visa-desc-text">
            <p>
              Once your form is completed, you will be able to make new changes
              by contacting our Support team.
            </p>
            <p>When you're ready, click "Submit"!</p>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default PersonalInfo
