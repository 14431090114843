import React from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import Layout from '../layout/layout'
import './visaEvaluation.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { serverPath } from '../../config/key'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { visaEvaluationSchema } from '../../utils/validationSchema'

const options = [
  {
    value: '1',
    label: 'At least 1 year of work experience in Canada (full-time)',
    point: '10',
  },
  {
    value: '2',
    label: '2 or more academic years in Canada (full-time studies)',
    point: '5',
  },
  {
    value: '3',
    label:
      'Spouse - Language proficiency in English or French (CLB4 or higher)',
    point: '5',
  },
  {
    value: '4',
    label: 'Spouse - Two or more academic years in Canada (full-time studies)',
    point: '5',
  },
  {
    value: '6',
    label: 'Spouse - 1 Year or more of work experience in Canada(NOC 0/A/B)',
    point: '5',
  },
  {
    value: '7',
    label: 'Relatives in Canada (18 years or older)',
    point: '5',
  },
]

function withParams(Component) {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useNavigate()} />
  )
}
class VisaEvaluation extends React.Component {
  caseId
  navigate
  constructor(props) {
    super(props)
    this.state = {
      adaptability: null,
      age: undefined,
      workExperience: undefined,
      academic: undefined,
      langWrite: 0,
      langRead: 0,
      langSpeak: 0,
      langListen: 0,
      selectedOption: '',
      error: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let { caseId } = this.props.params
    this.caseId = caseId
  }

  handleChange(event) {
    this.setState({ [event.target.name]: parseInt(event.target.value) })
  }
  handleChangeselect = (selectedOption) => {
    let value = Array.from(selectedOption, (option) => option.point)
    const totalPoints = value.reduce(
      (partialSum, a) => partialSum + parseInt(a),
      0,
    )
    this.setState({ adaptability: totalPoints > 10 ? 10 : totalPoints })
    this.setState({ selectedOption: selectedOption })
  }

  updateAccount = async (values) => {
    try {
      const payload = {
        adaptability: this.state.adaptability ? this.state.adaptability : 0,
        age: this.state.age ? this.state.age : 0,
        workExperience: this.state.workExperience
          ? this.state.workExperience
          : 0,
        academic: this.state.academic ? this.state.academic : 0,
        langWrite: this.state.langWrite,
        langRead: this.state.langRead,
        langSpeak: this.state.langSpeak,
        langListen: this.state.langListen,
        employment: this.state.employment ? this.state.employment : 0,
        languageSecondary: this.state.languageSecondary
          ? this.state.languageSecondary
          : 0,
      }
      const totalPoints = await Object?.values(payload).reduce(
        (acc, a) => acc + a,
        0,
      )

      const response = await axios.post(`${serverPath}/forms/evaluation`, {
        ...values,
        total: totalPoints,
        caseId: this.caseId,
      })

      if (response?.data.status === 200) {
        this.props.navigate(`/thankyous/?score=${totalPoints}`)
        toast.success('Account updated successfully', {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.name, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    try {
      const validate = await visaEvaluationSchema.validate(this.state, {
        abortEarly: false,
      })
      if (validate) {
        await this.updateAccount(this.state)
      }
    } catch (err) {
      let er = {}
      err?.inner?.forEach((e) => {
        er[e?.path] = e?.message
      })
      this.setState({ error: er })
    }
  }

  render() {
    return (
      <Layout>
        <div className="visa-evaluation-calc">
          <h2 class="text-center">Visa Evaluation Calculator</h2>
          <div className="contact-page">
            <div className="container">
              <div className="contact-form-content">
                <form onSubmit={this.handleSubmit}>
                  <FormGroup className="form-group">
                    <label htmlFor="age">
                      Age <span>{this.state?.age ? this.state?.age : 0}</span>
                    </label>
                    <select
                      className="form-select"
                      onChange={this.handleChange}
                      name={'age'}
                      required
                    >
                      <option value={undefined}>Choose</option>
                      <option value={0}>0 -17</option>
                      <option value={12}>18 - 35</option>
                      <option value={11}>36</option>
                      <option value={10}>37</option>
                      <option value={9}>38</option>
                      <option value={8}>39</option>
                      <option value={7}>40</option>
                      <option value={6}>41</option>
                      <option value={5}>42</option>
                      <option value={4}>43</option>
                      <option value={3}>44</option>
                      <option value={2}>45</option>
                      <option value={1}>46</option>
                      <option value={0}>47+</option>
                    </select>
                    {this.state?.error && (
                      <span style={{ color: 'red' }}>
                        {this.state?.error?.age}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <label htmlFor="workExperience">
                      Work Experience (NOC 0/A/B)
                      <span>
                        {this.state?.workExperience
                          ? this.state?.workExperience
                          : 0}
                      </span>
                    </label>
                    <select
                      onChange={this.handleChange}
                      name={'workExperience'}
                      className="form-select"
                    >
                      <option value={undefined}>Choose</option>
                      <option value={0}>None</option>
                      <option value={9}>1 Year</option>
                      <option value={11}>2-3 years</option>
                      <option value={13}>4-5 Years</option>
                      <option value={15}>6+ Years</option>
                    </select>
                    {this.state?.error && (
                      <span style={{ color: 'red' }}>
                        {this.state?.error?.workExperience}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <label htmlFor="academic">
                      Academic Achievements{' '}
                      <span>
                        {this.state?.academic ? this.state?.academic : 0}
                      </span>
                    </label>
                    <select
                      className="form-select"
                      onChange={this.handleChange}
                      name={'academic'}
                    >
                      <option value={undefined}>Choose</option>
                      <option value={5}>High School Diploma</option>
                      <option value={15}>
                        Post-Secondary Degree - 1 Year program
                      </option>
                      <option value={19}>
                        Post-Secondary Degree - 2 Year program
                      </option>
                      <option value={21}>
                        Post-Secondary Degree or Bachelor's - 3 or more Year
                        program
                      </option>
                      <option value={22}>
                        Two or more Post-Secondary Degrees (at least one degree
                        is a 3 year program or longer)
                      </option>
                      <option value={23}>Master's</option>
                      <option value={25}>Doctoral (PHD)</option>
                    </select>
                    {this.state?.error && (
                      <span style={{ color: 'red' }}>
                        {this.state?.error?.academic}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group adaptability-multi-select">
                    <label htmlFor="adaptability">
                      Adaptability (Select multiple rows with ctrl)
                      <span>
                        {this.state?.adaptability
                          ? this.state?.adaptability
                          : 0}
                      </span>
                    </label>
                    <Select
                      value={this.state.selectedOption}
                      // name="adaptability"
                      onChange={this.handleChangeselect}
                      options={options}
                      isMulti={true}
                    />
                  </FormGroup>
                  <FormGroup className="form-group language-select">
                    <div className="language-select-group">
                      <label htmlFor="language">
                        Primary Language Proficiency (English or French)
                        <span>
                          {this.state.langListen +
                            this.state.langRead +
                            this.state.langWrite +
                            this.state.langSpeak}
                        </span>
                      </label>
                      <select
                        className="form-select"
                        onChange={this.handleChange}
                        name={'langWrite'}
                      >
                        <option value={0}>Writing</option>
                        <option value={6}>CLB 9+</option>
                        <option value={5}>CLB 8</option>
                        <option value={4}>CLB 7</option>
                      </select>
                      {this.state?.error && (
                        <span style={{ color: 'red' }}>
                          {this.state?.error?.langWrite}
                        </span>
                      )}
                    </div>
                    <div className="language-select-group">
                      <select
                        className="form-select"
                        onChange={this.handleChange}
                        name={'langRead'}
                      >
                        <option value={0}>Reading</option>
                        <option value={6}>CLB 9+</option>
                        <option value={5}>CLB 8</option>
                        <option value={4}>CLB 7</option>
                      </select>
                      {this.state?.error && (
                        <span style={{ color: 'red' }}>
                          {this.state?.error?.langRead}
                        </span>
                      )}
                    </div>
                    <div className="language-select-group">
                      <select
                        className="form-select"
                        onChange={this.handleChange}
                        name={'langSpeak'}
                      >
                        <option value={0}>Speaking</option>
                        <option value={6}>CLB 9+</option>
                        <option value={5}>CLB 8</option>
                        <option value={4}>CLB 7</option>
                      </select>
                      {this.state?.error && (
                        <span style={{ color: 'red' }}>
                          {this.state?.error?.langSpeak}
                        </span>
                      )}
                    </div>
                    <div className="language-select-group">
                      <select
                        className="form-select"
                        onChange={this.handleChange}
                        name={'langListen'}
                      >
                        <option value={0}>Listening</option>
                        <option value={6}>CLB 9+</option>
                        <option value={5}>CLB 8</option>
                        <option value={4}>CLB 7</option>
                      </select>
                      {this.state?.error && (
                        <span style={{ color: 'red' }}>
                          {this.state?.error?.langListen}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <label htmlFor="languageSecondary">
                      Secondary Language Proficiency (English or French)
                      <span>
                        {this.state?.languageSecondary
                          ? this.state?.languageSecondary
                          : 0}
                      </span>
                    </label>
                    <select
                      className="form-select"
                      onChange={this.handleChange}
                      name={'languageSecondary'}
                    >
                      <option value={0}>Choose</option>
                      <option value={4}>CLB 5+</option>
                      <option value={0}>CLB 4 or less</option>
                    </select>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <label htmlFor="employment">
                      Arranged Employment : Positive LMIA & Job offer
                      <span>
                        {this.state?.employment ? this.state?.employment : 0}
                      </span>
                    </label>
                    <select
                      className="form-select"
                      onChange={this.handleChange}
                      name={'employment'}
                    >
                      <option value={0}>No</option>
                      <option value={10}>Yes</option>
                    </select>
                  </FormGroup>
                  {/* <FormGroup id={"submitBtn"}>
                    <input
                      type="submit"
                      value="Calculate"
                      class="orange-button"
                    />
                  </FormGroup> */}
                  <div className="save-btn">
                    <Button type="submit">Calculate</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withParams(VisaEvaluation)
