import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { Form, Accordion, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
const BusinessInfo = ({ businessInfo, setBusinessInfo }) => {
  const initialState = {
    businessOwner: '',
    establishDate: '',
    endDate: '',
    businessActivity: '',
    netWorth: '',
  }

  const [addBusiness, setAddBusiness] = useState([{ initialState }])
  const [businessDis, setBusinessDis] = useState(false)
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  useEffect(() => {
    if (businessInfo) {
      setBusinessDis(true)
    }
  }, [businessInfo])
  const handleAddBusiness = () => {
    setAddBusiness([...addBusiness, { ...initialState }])
  }
  const { handleSubmit, register } = useForm()
  const handleRemoveBusiness = (index) => {
    const oldedu = [...addBusiness]
    oldedu.splice(index, 1)
    setAddBusiness(oldedu)
  }
  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addBusiness]
    prevData[index][name] = value
    setAddBusiness([...prevData])
  }
  const handleChildDatePicker = (date, index, type) => {
    if (type === 'establishDate') {
      let prevData = [...addBusiness]
      prevData[index].establishDate = date
      setAddBusiness([...prevData])
    }
    if (type === 'endDate') {
      let prevData = [...addBusiness]
      prevData[index].endDate = date
      setAddBusiness([...prevData])
    }
  }
  const submitEducationForm = async (values) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/visaBusiness`,
        {
          ...values,
          mainBusinessInfo: addBusiness,
          visaTypeId: selectedVisa.visaId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setBusinessInfo(create)
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          visaId: create?.visaTypeId,
        })
        toast.success('Your information was saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.error(err.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        toastId: 'err',
      })
    }
  }
  return (
    <div className="personal-info business-info">
      <Form onSubmit={handleSubmit(submitEducationForm)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>
            <Accordion.Body>
              {addBusiness &&
                addBusiness.length > 0 &&
                addBusiness.map((child, i) => (
                  <>
                    <div className="box-grid-three">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Business Owner</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="businessOwner"
                          disabled={businessDis ? businessDis : false}
                          onChange={(e) => handleChange(e, i)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Established Date (Month/Year)</Form.Label>
                        <DatePicker
                          type="establishDate"
                          name="establishDate"
                          placeholderText="Select Date"
                          disabled={businessDis ? businessDis : false}
                          // disabled={childrenInfoDis ? childrenInfoDis : false}
                          selected={
                            child?.establishDate
                              ? new Date(child?.establishDate)
                              : new Date()
                          }
                          onChange={(date) =>
                            handleChildDatePicker(date, i, 'establishDate')
                          }
                          className="form-select"
                        />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>End Date (Month/Year)</Form.Label>
                        <DatePicker
                          type="endDate"
                          name="endDate"
                          placeholderText="Select Date"
                          disabled={businessDis ? businessDis : false}
                          selected={
                            child?.endDate
                              ? new Date(child?.endDate)
                              : new Date()
                          }
                          onChange={(date) =>
                            handleChildDatePicker(date, i, 'endDate')
                          }
                          className="form-select"
                        />
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Describe Your Business Activities
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="businessActivity"
                          disabled={businessDis ? businessDis : false}
                          onChange={(e) => handleChange(e, i)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Your Net Worth (in USD)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="netWorth"
                          onChange={(e) => handleChange(e, i)}
                        />
                      </Form.Group>
                    </div>
                    <div className="add-btn">
                      <div className="add-btn">
                        {addBusiness && addBusiness.length === 1 ? (
                          <Button
                            onClick={handleAddBusiness}
                            // disabled={
                            //   spouseOffshoreDis ? spouseOffshoreDis : false
                            // }
                          >
                            Add Business <i className="las la-plus"></i>
                          </Button>
                        ) : (
                          ''
                        )}
                        {i >= 0 && i !== 0 && (
                          <>
                            <Button
                              className="delete-btn"
                              // disabled={
                              //   spouseOffshoreDis ? spouseOffshoreDis : false
                              // }
                              onClick={() => handleRemoveBusiness(i)}
                            >
                              Delete Job #{i + 1} <i class="las la-minus"></i>
                            </Button>
                            <Button
                              onClick={handleAddBusiness}
                              // disabled={
                              //   spouseOffshoreDis ? spouseOffshoreDis : false
                              // }
                            >
                              Add Business <i className="las la-plus"></i>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}

              <div className="add-business">
                <h6>Cultural Activities</h6>
                <div className="box-grid-two">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>
                      Are you self-employed in Cultural Activites at world-class
                      level?
                    </Form.Label>
                    <Form.Select
                      name="selfEmpCultureAct"
                      aria-label="Default select example"
                      {...register('selfEmpCultureAct')}
                    >
                      <option value="">
                        {businessInfo?.selfEmpCultureAct
                          ? businessInfo?.selfEmpCultureAct
                          : 'Select'}
                      </option>
                      <option>No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>
                      Have you participated in Cultural Activities at
                      world-class level?
                    </Form.Label>
                    <Form.Select
                      name="participatedCultureAct"
                      aria-label="Default select example"
                      {...register('participatedCultureAct')}
                    >
                      <option value="">
                        {businessInfo?.participatedCultureAct
                          ? businessInfo?.participatedCultureAct
                          : 'Select'}
                      </option>
                      <option>No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <h6>Athletics</h6>
                <div className="box-grid-two">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>
                      Are you self-employed in Athletics at world-class level?
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="worldClassSelfEmpAthletics"
                      {...register('worldClassSelfEmpAthletics')}
                    >
                      <option value="">
                        {businessInfo?.worldClassSelfEmpAthletics
                          ? businessInfo?.worldClassSelfEmpAthletics
                          : 'Select'}
                      </option>
                      <option>No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>
                      Have you participated in Athletics at world-class level?
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="worldClassParticipatedAthletics"
                      {...register('worldClassParticipatedAthletics')}
                    >
                      <option value="">
                        {businessInfo?.worldClassParticipatedAthletics
                          ? businessInfo?.worldClassParticipatedAthletics
                          : 'Select'}
                      </option>
                      <option>No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="visa-btn">
          <Button type="submit" /*  disabled={spouseDis ? spouseDis : false} */>
            Save
          </Button>
          <div className="visa-desc-text">
            <p>
              Once your form is completed, you will be able to make new changes
              by contacting our Support team.
            </p>
            <p>When you're ready, click "Submit"!</p>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default BusinessInfo
