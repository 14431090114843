import axios from "axios";
import { useContext } from "react";
import { serverPath } from "../../config/key";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import attached from "../../assets/images/application/check-circle.svg";
import trash from "../../assets/images/application/delete.svg";
import UploadIcon from "../../assets/icons/UploadIcon";
const GeneralSpouseInfo = ({ documentInfo, setDocumentInfo, documentDis }) => {
  const { selectedVisa } = useContext(UserContext);
  const handleFile = async (e) => {
    const {
      target: { files, name },
    } = e;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", name);

    const res = await axios.post(`${serverPath}/visa/visaDocuments`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
      },
    });
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc;
      setDocumentInfo({ ...documentInfo, ...data });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeDocument`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo(data);
      }
    } catch (err) {
      toast.error(err.message, { toastId: "erro" });
    }
  };

  return (
    <div className="general-info">
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Passport Copy </h6>
        </div>
        <div className="middle-info">
          <h6>(front page + stamp / visa pages if any)</h6>
        </div>
        {documentInfo?.spousePassportCopy ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("spousePassportCopy")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="spouseGeneral1"
                type="file"
                name="spousePassportCopy"
                disabled={documentDis ? documentDis : false}
                onChange={handleFile}
              />
              <label htmlFor="spouseGeneral1">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Digital Photo</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.spouseDigitalPhoto ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("spouseDigitalPhoto")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="spouseGeneral2"
                type="file"
                name="spouseDigitalPhoto"
                disabled={documentDis ? documentDis : false}
                onChange={handleFile}
              />
              <label htmlFor="spouseGeneral2">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Copy of Birth Certificate</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.spouseBirthCertificate ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("spouseBirthCertificate")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="spouseGeneral3"
                type="file"
                disabled={documentDis ? documentDis : false}
                name="spouseBirthCertificate"
                onChange={handleFile}
              />
              <label htmlFor="spouseGeneral3">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default GeneralSpouseInfo;
