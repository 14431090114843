import React, { useState, useEffect } from 'react'
import { Form, Accordion, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
const EducationInfo = ({ educationInfo, setEducationInfo }) => {
  let intialStateEducation = {
    fieldStudy: '',
    institution: '',
    countryStudy: '',
    completed: '',
    studyCompletionDetail: '',
  }
  let intialStateSpouseEducation = {
    fieldStudy: '',
    institution: '',
    countryStudy: '',
    completed: '',
    studyCompletionDetail: '',
  }
  const { countries, selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [addEducation, setAddEducation] = useState([{ intialStateEducation }])
  const [addSponsoredEducation, setAddSponsoredEducation] = useState([
    { intialStateEducation },
  ])
  const [addSponsoringEducation, setAddSponsoringEducation] = useState([
    { intialStateEducation },
  ])
  const [addSpouseEducation, setAddSpouseEducation] = useState([
    { intialStateSpouseEducation },
  ])
  const [mainDis, setMainDis] = useState(false)
  const [spouseDis, setSpouseDis] = useState(false)
  useEffect(() => {
    if (educationInfo && educationInfo?.mainApplicantEducation?.length !== 0) {
      setMainDis(true)
      setAddEducation(educationInfo?.mainApplicantEducation)
    }
    if (educationInfo && educationInfo?.spouseEducation?.length !== 0) {
      setAddSpouseEducation(educationInfo?.spouseEducation)
      setSpouseDis(true)
    }
    if (educationInfo && educationInfo?.sponsoredEducationInfo?.length !== 0) {
      setAddSponsoredEducation(educationInfo?.sponsoredEducationInfo)
      setMainDis(true)
    }
    if (educationInfo && educationInfo?.sponsoringEducationInfo?.length !== 0) {
      setAddSponsoringEducation(educationInfo?.sponsoringEducationInfo)
      setMainDis(true)
    }
  }, [educationInfo])
  const handleAddEducation = () => {
    setAddEducation([...addEducation, { ...intialStateEducation }])
  }
  //add spouse education form
  const handleAddSpouseEducation = () => {
    setAddSpouseEducation([
      ...addSpouseEducation,
      { ...intialStateSpouseEducation },
    ])
  }
  //add sponsored new form
  // const handleAddSponseredEducation = () => {
  //   setAddSponsoredEducation([
  //     ...addSponsoredEducation,
  //     { ...intialStateEducation },
  //   ])
  // }
  //add sponsoring new form
  // const handleAddSponsoringEducation = () => {
  //   setAddSponsoringEducation([
  //     ...addSponsoringEducation,
  //     { ...intialStateEducation },
  //   ])
  // }
  const { handleSubmit, register } = useForm()
  //handle input change main aplicant form
  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addEducation]
    prevData[index][name] = value
    setAddEducation([...prevData])
  }
  //handle input value of spouse information
  const handleSpouseChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addSpouseEducation]
    prevData[index][name] = value
    setAddSpouseEducation([...prevData])
  }
  //handle value of sponsored input
  // const handleAddSponseredChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...addSponsoredEducation]
  //   prevData[index][name] = value
  //   setAddSponsoredEducation([...prevData])
  // }
  //handle remove sponsored education
  // const removeSponseredEducation = (index) => {
  //   const oldedu = [...addSponsoredEducation]
  //   oldedu.splice(index, 1)
  //   setAddSponsoredEducation(oldedu)
  // }
  //handle sponsoring input value
  // const handleAddSponsoringChange = (e, index) => {
  //   const { name, value } = e.target
  //   let prevData = [...addSponsoringEducation]
  //   prevData[index][name] = value
  //   setAddSponsoringEducation([...prevData])
  // }
  //remove sponsoring education form
  // const removeSponsoringEducation = (index) => {
  //   const oldedu = [...addSponsoringEducation]
  //   oldedu.splice(index, 1)
  //   setAddSponsoringEducation(oldedu)
  // }
  //remove main education form with value
  const removeEducation = (index) => {
    const oldedu = [...addEducation]
    oldedu.splice(index, 1)
    setAddEducation(oldedu)
  }
  //remove spouse education form with value
  const removeSpouseEducation = (index) => {
    const oldedu = [...addSpouseEducation]
    oldedu.splice(index, 1)
    setAddSpouseEducation(oldedu)
  }

  const submitEducationForm = async (values) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/visaEducation`,
        {
          ...values,
          mainApplicantEducation: addEducation,
          spouseEducation: addSpouseEducation,
          addSponsoringEducation,
          addSponsoredEducation,
          visaTypeId: selectedVisa.visaId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setEducationInfo(create)
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          visaId: create?.visaTypeId,
        })
        toast.success('Your information was saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.success(err.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        toastId: 'err',
      })
    }
  }
  return (
    <div className="personal-info education-info-section">
      <Form onSubmit={handleSubmit(submitEducationForm)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>

            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Total years of education (since Grade 1)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="yearsEducation"
                      disabled={mainDis ? mainDis : false}
                      defaultValue={educationInfo?.yearsEducation}
                      {...register('yearsEducation')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Your Highest Level of Education</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="highestLevelEdu"
                      disabled={mainDis ? mainDis : false}
                      {...register('highestLevelEdu')}
                    >
                      <option value="">
                        {educationInfo?.highestLevelEdu
                          ? educationInfo?.highestLevelEdu
                          : 'Select'}
                      </option>
                      <option value="None">None</option>
                      <option value="High School">High School</option>
                      <option value="Diploma / Certificate">
                        Diploma / Certificate
                      </option>
                      <option value="Trade Certificate">
                        Trade Certificate
                      </option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="PhD">PhD</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                {addEducation?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddEducation}>
                      Add Education <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
              </div>
              {addEducation &&
                addEducation.length > 0 &&
                addEducation.map((child, i) => (
                  <>
                    <h6 className="child-heading education-heading">
                      Education #{i + 1}
                    </h6>
                    <div className="education-info">
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Field of Study</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: dentistry, law, optometry..."
                            name="fieldStudy"
                            disabled={mainDis ? mainDis : false}
                            defaultValue={child.fieldStudy}
                            onChange={(e) => handleChange(e, i)}
                          />
                          {/* {error && error[`[${i}].fieldStudy`] ? (
                            <span className="error-msg">
                              {error[`[${i}].fieldStudy`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Institution</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="institution"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleChange(e, i)}
                          >
                            <option value="">
                              {child.institution ? child.institution : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="College">College</option>
                            <option value="University">University</option>
                            <option value="Trade School">Trade School</option>
                            <option value="Technical School">
                              Technical School
                            </option>
                            <option value="Online Course">Online Course</option>
                          </Form.Select>
                          {/* {error && error[`[${i}].institution`] ? (
                            <span className="error-msg">
                              {error[`[${i}].institution`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Country of Study</Form.Label>
                          <Form.Select
                            name="countryStudy"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleChange(e, i)}
                          >
                            <option value="">
                              {child.countryStudy
                                ? child.countryStudy
                                : 'Select'}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          {/* {error && error[`[${i}].countryStudy`] ? (
                            <span className="error-msg">
                              {error[`[${i}].countryStudy`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Study Duration</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="studyDuration"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleChange(e, i)}
                          >
                            <option value="">
                              {child?.studyDuration
                                ? child?.studyDuration
                                : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="Less than 1 Academic Year">
                              Less than 1 Academic Year
                            </option>
                            <option value="1 Academic Year">
                              1 Academic Year
                            </option>
                            <option value="2 Academic Years">
                              2 Academic Years
                            </option>
                            <option value="3 Academic Years">
                              3 Academic Years
                            </option>
                            <option value="4 Academic Years">
                              4 Academic Years
                            </option>
                            <option value="5 Academic Years and above">
                              5 Academic Years and above
                            </option>
                          </Form.Select>
                          {/* {error && error[`[${i}].studyDuration`] ? (
                            <span className="error-msg">
                              {error[`[${i}].studyDuration`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Completed?</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="completed"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleChange(e, i)}
                          >
                            <option value="">
                              {child.completed ? child.completed : 'Select'}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                          {/* {error && error[`[${i}].completed`] ? (
                            <span className="error-msg">
                              {error[`[${i}].completed`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        {child && child.completed === 'No' ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              how long until studies are completed?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="studyCompletionDetail"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child.studyCompletionDetail}
                              onChange={(e) => handleChange(e, i)}
                            />
                            {/* {error && error[`[${i}].studyCompletionDetail`] ? (
                              <span className="error-msg">
                                {error[`[${i}].studyCompletionDetail`]}
                              </span>
                            ) : (
                              ''
                            )} */}
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="add-btn">
                        {i >= 1 &&
                          (!mainDis ? (
                            <Button
                              className="delete-btn"
                              disabled={mainDis ? mainDis : false}
                              onClick={() => removeEducation(i)}
                            >
                              Delete Education #{i + 1}{' '}
                              <i class="las la-minus"></i>
                            </Button>
                          ) : (
                            ''
                          ))}

                        <Button
                          onClick={handleAddEducation}
                          disabled={mainDis ? mainDis : false}
                        >
                          Add Education <i className="las la-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Add Spouse Information</Accordion.Header>
            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Total years of education (since Grade 1)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="sYearsEducation"
                      disabled={mainDis ? mainDis : false}
                      defaultValue={educationInfo?.sYearsEducation}
                      {...register('sYearsEducation')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Your Highest Level of Education</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="sHighestLevelEdu"
                      disabled={spouseDis ? spouseDis : false}
                      {...register('sHighestLevelEdu')}
                    >
                      <option value="">
                        {educationInfo?.highestLevelEdu
                          ? educationInfo?.highestLevelEdu
                          : 'Select'}
                      </option>
                      <option value="None">None</option>
                      <option value="High School">High School</option>
                      <option value="Diploma / Certificate">
                        Diploma / Certificate
                      </option>
                      <option value="Trade Certificate">
                        Trade Certificate
                      </option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="PhD">PhD</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              {addSpouseEducation &&
                addSpouseEducation.length > 0 &&
                addSpouseEducation.map((child, i) => (
                  <>
                    <h6 className="child-heading education-heading">
                      Education #{i + 1}
                    </h6>
                    <div className="education-info">
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Field of Study</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: dentistry, law, optometry..."
                            name="fieldStudy"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={child.fieldStudy}
                            onChange={(e) => handleSpouseChange(e, i)}
                          />
                          {/* {error && error[`[${i}].fieldStudy`] ? (
                            <span className="error-msg">
                              {error[`[${i}].fieldStudy`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Institution</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="institution"
                            disabled={spouseDis ? spouseDis : false}
                            onChange={(e) => handleSpouseChange(e, i)}
                          >
                            <option value="">
                              {child.institution ? child.institution : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="College">College</option>
                            <option value="University">University</option>
                            <option value="Trade School">Trade School</option>
                            <option value="Technical School">
                              Technical School
                            </option>
                            <option value="Online Course">Online Course</option>
                          </Form.Select>
                          {/* {error && error[`[${i}].institution`] ? (
                            <span className="error-msg">
                              {error[`[${i}].institution`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Country of Study</Form.Label>
                          <Form.Select
                            name="countryStudy"
                            disabled={spouseDis ? spouseDis : false}
                            onChange={(e) => handleSpouseChange(e, i)}
                          >
                            <option value="">
                              {child.countryStudy
                                ? child.countryStudy
                                : 'Select'}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          {/* {error && error[`[${i}].countryStudy`] ? (
                            <span className="error-msg">
                              {error[`[${i}].countryStudy`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Study Duration</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="studyDuration"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={child.studyDuration}
                            onChange={(e) => handleSpouseChange(e, i)}
                          >
                            <option value="">
                              {child?.studyDuration
                                ? child?.studyDuration
                                : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="Less than 1 Academic Year">
                              Less than 1 Academic Year
                            </option>
                            <option value="1 Academic Year">
                              1 Academic Year
                            </option>
                            <option value="2 Academic Years">
                              2 Academic Years
                            </option>
                            <option value="3 Academic Years">
                              3 Academic Years
                            </option>
                            <option value="4 Academic Years">
                              4 Academic Years
                            </option>
                            <option value="5 Academic Years and above">
                              5 Academic Years and above
                            </option>
                          </Form.Select>
                          {/* {error && error[`[${i}].studyDuration`] ? (
                            <span className="error-msg">
                              {error[`[${i}].studyDuration`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Completed?</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="completed"
                            disabled={spouseDis ? spouseDis : false}
                            onChange={(e) => handleSpouseChange(e, i)}
                          >
                            <option value="">
                              {child.completed ? child.completed : 'Select'}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                          {/* {error && error[`[${i}].completed`] ? (
                            <span className="error-msg">
                              {error[`[${i}].completed`]}
                            </span>
                          ) : (
                            ''
                          )} */}
                        </Form.Group>
                        {child && child.completed === 'No' ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              how long until studies are completed?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="studyCompletionDetail"
                              disabled={spouseDis ? spouseDis : false}
                              defaultValue={child.studyCompletionDetail}
                              onChange={(e) => handleSpouseChange(e, i)}
                            />
                            {/* {error && error[`[${i}].studyCompletionDetail`] ? (
                              <span className="error-msg">
                                {error[`[${i}].studyCompletionDetail`]}
                              </span>
                            ) : (
                              ''
                            )} */}
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="add-btn">
                        {i >= 1 && !spouseDis && (
                          <Button
                            className="delete-btn"
                            disabled={spouseDis ? spouseDis : false}
                            onClick={() => removeSpouseEducation(i)}
                          >
                            Delete Education #{i + 1}{' '}
                            <i class="las la-minus"></i>
                          </Button>
                        )}
                        <Button
                          onClick={handleAddSpouseEducation}
                          disabled={spouseDis ? spouseDis : false}
                        >
                          Add Education <i className="las la-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="2">
            <Accordion.Header>Sponsored Applicant</Accordion.Header>

            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Total years of education (since Grade 1)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="sponsoredYearsEducation"
                      disabled={mainDis ? mainDis : false}
                      defaultValue={educationInfo?.sponsoredYearsEducation}
                      {...register('sponsoredYearsEducation')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Your Highest Level of Education</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="sponsoredHighestLevelEdu"
                      disabled={mainDis ? mainDis : false}
                      {...register('sponsoredHighestLevelEdu')}
                    >
                      <option value="">
                        {educationInfo?.sponsoredHighestLevelEdu
                          ? educationInfo?.sponsoredHighestLevelEdu
                          : 'Select'}
                      </option>
                      <option value="None">None</option>
                      <option value="High School">High School</option>
                      <option value="Diploma / Certificate">
                        Diploma / Certificate
                      </option>
                      <option value="Trade Certificate">
                        Trade Certificate
                      </option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="PhD">PhD</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                {addSponsoredEducation && addSponsoredEducation?.length === 0 && (
                  <div className="add-btn">
                    <Button onClick={handleAddEducation}>
                      Add Education <i className="las la-plus"></i>
                    </Button>
                  </div>
                )}
              </div>
              {addSponsoredEducation &&
                addSponsoredEducation.length > 0 &&
                addSponsoredEducation.map((child, i) => (
                  <>
                    <h6 className="child-heading education-heading">
                      Education #{i + 1}
                    </h6>
                    <div className="education-info">
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Field of Study</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: dentistry, law, optometry..."
                            name="fieldStudy"
                            disabled={mainDis ? mainDis : false}
                            defaultValue={child.fieldStudy}
                            onChange={(e) => handleAddSponseredChange(e, i)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Institution</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="institution"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponseredChange(e, i)}
                          >
                            <option value="">
                              {child.institution ? child.institution : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="College">College</option>
                            <option value="University">University</option>
                            <option value="Trade School">Trade School</option>
                            <option value="Technical School">
                              Technical School
                            </option>
                            <option value="Online Course">Online Course</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Country of Study</Form.Label>
                          <Form.Select
                            name="countryStudy"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponseredChange(e, i)}
                          >
                            <option value="">
                              {child.countryStudy
                                ? child.countryStudy
                                : 'Select'}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Study Duration</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="studyDuration"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponseredChange(e, i)}
                          >
                            <option value="">
                              {child?.studyDuration
                                ? child?.studyDuration
                                : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="Less than 1 Academic Year">
                              Less than 1 Academic Year
                            </option>
                            <option value="1 Academic Year">
                              1 Academic Year
                            </option>
                            <option value="2 Academic Years">
                              2 Academic Years
                            </option>
                            <option value="3 Academic Years">
                              3 Academic Years
                            </option>
                            <option value="4 Academic Years">
                              4 Academic Years
                            </option>
                            <option value="5 Academic Years and above">
                              5 Academic Years and above
                            </option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Completed?</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="completed"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponseredChange(e, i)}
                          >
                            <option value="">
                              {child.completed ? child.completed : 'Select'}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Form.Group>
                        {child && child.completed === 'No' ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              how long until studies are completed?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="studyCompletionDetail"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child.studyCompletionDetail}
                              onChange={(e) => handleAddSponseredChange(e, i)}
                            />
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="add-btn">
                        {i >= 1 &&
                          (!mainDis ? (
                            <Button
                              className="delete-btn"
                              disabled={mainDis ? mainDis : false}
                              onClick={() => removeSponseredEducation(i)}
                            >
                              Delete Education #{i + 1}{' '}
                              <i class="las la-minus"></i>
                            </Button>
                          ) : (
                            ''
                          ))}

                        <Button
                          onClick={handleAddSponseredEducation}
                          disabled={mainDis ? mainDis : false}
                        >
                          Add Education <i className="las la-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="3">
            <Accordion.Header>
              Add Sponsoring Applicant Information
            </Accordion.Header>

            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Total years of education (since Grade 1)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="sponsoringYearsEducation"
                      disabled={mainDis ? mainDis : false}
                      defaultValue={educationInfo?.sponsoringYearsEducation}
                      {...register('sponsoringYearsEducation')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Your Highest Level of Education</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="sponsoringHighestLevelEdu"
                      disabled={mainDis ? mainDis : false}
                      {...register('sponsoringHighestLevelEdu')}
                    >
                      <option value="">
                        {educationInfo?.sponsoringHighestLevelEdu
                          ? educationInfo?.sponsoringHighestLevelEdu
                          : 'Select'}
                      </option>
                      <option value="None">None</option>
                      <option value="High School">High School</option>
                      <option value="Diploma / Certificate">
                        Diploma / Certificate
                      </option>
                      <option value="Trade Certificate">
                        Trade Certificate
                      </option>
                      <option value="Bachelor's Degree">
                        Bachelor's Degree
                      </option>
                      <option value="Master's Degree">Master's Degree</option>
                      <option value="PhD">PhD</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                {addSponsoringEducation &&
                  addSponsoringEducation?.length === 0 && (
                    <div className="add-btn">
                      <Button onClick={handleAddSponsoringEducation}>
                        Add Education <i className="las la-plus"></i>
                      </Button>
                    </div>
                  )}
              </div>
              {addSponsoringEducation &&
                addSponsoringEducation.length > 0 &&
                addSponsoringEducation.map((child, i) => (
                  <>
                    <h6 className="child-heading education-heading">
                      Education #{i + 1}
                    </h6>
                    <div className="education-info">
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Field of Study</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: dentistry, law, optometry..."
                            name="fieldStudy"
                            disabled={mainDis ? mainDis : false}
                            defaultValue={child.fieldStudy}
                            onChange={(e) => handleAddSponsoringChange(e, i)}
                          />
                          
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Institution</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="institution"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponsoringChange(e, i)}
                          >
                            <option value="">
                              {child.institution ? child.institution : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="College">College</option>
                            <option value="University">University</option>
                            <option value="Trade School">Trade School</option>
                            <option value="Technical School">
                              Technical School
                            </option>
                            <option value="Online Course">Online Course</option>
                          </Form.Select>
                          
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Country of Study</Form.Label>
                          <Form.Select
                            name="countryStudy"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponsoringChange(e, i)}
                          >
                            <option value="">
                              {child.countryStudy
                                ? child.countryStudy
                                : 'Select'}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Study Duration</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="studyDuration"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponsoringChange(e, i)}
                          >
                            <option value="">
                              {child?.studyDuration
                                ? child?.studyDuration
                                : 'Select'}
                            </option>
                            <option value="None">None</option>
                            <option value="Less than 1 Academic Year">
                              Less than 1 Academic Year
                            </option>
                            <option value="1 Academic Year">
                              1 Academic Year
                            </option>
                            <option value="2 Academic Years">
                              2 Academic Years
                            </option>
                            <option value="3 Academic Years">
                              3 Academic Years
                            </option>
                            <option value="4 Academic Years">
                              4 Academic Years
                            </option>
                            <option value="5 Academic Years and above">
                              5 Academic Years and above
                            </option>
                          </Form.Select>
                         
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Completed?</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="completed"
                            disabled={mainDis ? mainDis : false}
                            onChange={(e) => handleAddSponsoringChange(e, i)}
                          >
                            <option value="">
                              {child.completed ? child.completed : 'Select'}
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                          
                        </Form.Group>
                        {child && child.completed === 'No' ? (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              how long until studies are completed?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="studyCompletionDetail"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child.studyCompletionDetail}
                              onChange={(e) => handleAddSponsoringChange(e, i)}
                            />
                            
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="add-btn">
                        {i >= 1 &&
                          (!mainDis ? (
                            <Button
                              className="delete-btn"
                              disabled={mainDis ? mainDis : false}
                              onClick={() => removeSponsoringEducation(i)}
                            >
                              Delete Education #{i + 1}{' '}
                              <i class="las la-minus"></i>
                            </Button>
                          ) : (
                            ''
                          ))}

                        <Button
                          onClick={handleAddSponsoringEducation}
                          disabled={mainDis ? mainDis : false}
                        >
                          Add Education <i className="las la-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <div className="visa-btn">
          <Button type="submit" disabled={spouseDis ? spouseDis : false}>
            Save
          </Button>
          <div className="visa-desc-text">
            <p>
              Once your form is completed, you will be able to make new changes
              by contacting our Support team.
            </p>
            <p>When you're ready, click "Submit"!</p>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default EducationInfo
