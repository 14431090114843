import React, { useState, useContext, useEffect } from 'react'
//import { useNavigate } from 'react-router-dom'

import caseicon from '../../assets/images/application/case.svg'
// import logouticon from '../../assets/images/application/logout.svg'
import { Link } from 'react-router-dom'
import UserContext from '../../context/UserContext'
import bg from '../../assets/images/application/application-bg.svg'
// import { CircularProgressbar } from 'react-circular-progressbar'
// import 'react-circular-progressbar/dist/styles.css'
import { CircleProgress } from 'react-gradient-progress'
import SubMenu from './submenu'

const ApplicationHeader = ({ handleSideBar }) => {
  // const navigate = useNavigate()
  const { user, savePercentage } = useContext(UserContext)
  const [activeLink, setActiveLink] = useState()
  const [showsidebar, setShowSidebar] = useState(false)
  // const handleLogout = () => {
  //   localStorage.removeItem('token')
  //   setToken('')
  //   navigate('/login')
  // }
  useEffect(() => {
    setActiveLink(window?.location?.pathname)
  }, [])

  const handleSidebarMob = () => {
    setShowSidebar(!showsidebar)
  }
  return (
    <div className={`application-header`}>
      <div className="container">
        <div className="application-header-grid">
          <div className="application-menu">
            <div className="top-header-section">
              {/* <div className="application-user">
                <img src={user1} alt="" />
                <span>{user?.name}</span>
              </div> */}
              <div className="application-case">
                <div className="application-case-icon">
                  <img src={caseicon} alt="" /> case: 12345697 - {user?.name}
                </div>
                {/* <div className="application-case-working">working holiday</div> */}
              </div>
              <div className="bottom-content">
                <h6>
                  Welcome, <span>{user?.name}</span>!
                </h6>
                <p>
                  <span>Your Progress...</span> Legal department analyzes your
                  profile...
                </p>
              </div>
            </div>
            <div className="progress-bar-section">
              <CircleProgress
                percentage={parseInt(savePercentage)}
                strokeWidth={20}
                secondaryColor={'#E0D9F5'}
                primaryColor={['#8171E6', '#F6F4FF']}
                width={207}
                fontSize={20}
                fontColor={'#6243CE'}
                strokeLinecap={'round'}
              />
            </div>
            <div className="bg-image">
              <img src={bg} alt="bg" />
            </div>
          </div>
          <div className="application-action">
            <div
              className={`application-noti ${
                activeLink === '/notifications' ? 'active-link' : ''
              }`}
            >
              <div className="count">1</div>
              <Link to="/notifications">
                <NotificationIcon />
              </Link>
            </div>

            <span
              className={`desktop-icon ${
                activeLink === '/settings' ? 'active-link' : ''
              }`}
            >
              <Link to="/settings">
                <SettingsIcon />
              </Link>
            </span>
            <div className="mob-icon">
              <i class="las la-bars" onClick={handleSidebarMob}></i>
            </div>
          </div>
        </div>
      </div>
      <SubMenu show={showsidebar} handleClose={handleSidebarMob} />
    </div>
  )
}

export default ApplicationHeader

const NotificationIcon = () => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.18271 0.94165C4.84918 0.94165 1.33613 4.4547 1.33613 8.78827V13.4777L0.411393 14.4024C0.0373706 14.7764 -0.0745091 15.3389 0.127907 15.8276C0.330324 16.3163 0.807189 16.6349 1.33613 16.6349H17.0293C17.5583 16.6349 18.0351 16.3163 18.2376 15.8276C18.44 15.3389 18.3281 14.7764 17.954 14.4024L17.0293 13.4777V8.78827C17.0293 4.4547 13.5163 0.94165 9.18271 0.94165Z"
        fill="#AFB7C4"
      />
      <path
        d="M9.18307 21.8657C7.0163 21.8657 5.25977 20.1092 5.25977 17.9424H13.1064C13.1064 20.1092 11.3499 21.8657 9.18307 21.8657Z"
        fill="#AFB7C4"
      />
    </svg>
  )
}

const SettingsIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7337 1.77639C14.1587 -0.592131 10.7902 -0.592131 10.2152 1.77639C9.84376 3.30644 8.0908 4.03254 6.74623 3.21329C4.66484 1.94506 2.28295 4.32695 3.55118 6.40834C4.37043 7.75291 3.64433 9.50585 2.11428 9.87731C-0.254241 10.4523 -0.254241 13.8208 2.11428 14.3958C3.64433 14.7673 4.37043 16.5203 3.55118 17.8647C2.28295 19.9461 4.66484 22.3281 6.74625 21.0598C8.0908 20.2406 9.84376 20.9667 10.2152 22.4968C10.7902 24.8652 14.1587 24.8652 14.7337 22.4968C15.1052 20.9667 16.8582 20.2406 18.2026 21.0598C20.284 22.3281 22.666 19.9461 21.3977 17.8647C20.5785 16.5203 21.3046 14.7673 22.8347 14.3958C25.2031 13.8208 25.2031 10.4523 22.8347 9.87731C21.3046 9.50585 20.5785 7.75291 21.3977 6.40834C22.666 4.32695 20.284 1.94506 18.2026 3.21329C16.8582 4.03254 15.1052 3.30644 14.7337 1.77639ZM12.4745 16.6879C14.9881 16.6879 17.0257 14.6503 17.0257 12.1367C17.0257 9.62312 14.9881 7.58547 12.4745 7.58547C9.96092 7.58547 7.92327 9.62312 7.92327 12.1367C7.92327 14.6503 9.96092 16.6879 12.4745 16.6879Z"
        fill="#AFB7C4"
      />
    </svg>
  )
}
