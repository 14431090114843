import { Form, Accordion, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'

const LanguagesSection = ({ LanguageInfo, setLanguageInfo }) => {
  const [langDis, setLangDis] = useState(false)
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)

  const { handleSubmit, register, reset } = useForm()
  useEffect(() => {
    if (LanguageInfo) {
      setLangDis(true)
    }
  }, [LanguageInfo])
  const submitLanguageInfo = async (values) => {
    const res = await axios.post(
      `${serverPath}/visa/visaLanguageSkill`,
      {
        ...values,
        visaTypeId: selectedVisa.visaId,
        invoiceId: selectedVisa.invoiceId,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const { code, create } = res.data
    if (code === 200) {
      reset()
      setLanguageInfo(create)
      setSelectedVisa({
        invoiceId: create?.invoiceId,
        visaId: create?.visaTypeId,
      })
      toast.success('Your information was saved successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    }
  }
  return (
    <div className="personal-info education-info-section">
      <Form onSubmit={handleSubmit(submitLanguageInfo)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>

            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Level of English</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="levelOfEnglish"
                      {...register('levelOfEnglish')}
                      disabled={langDis ? langDis : false}
                    >
                      <option value="">
                        {LanguageInfo?.levelOfEnglish
                          ? LanguageInfo?.levelOfEnglish
                          : 'Select'}
                      </option>
                      <option value="Basic">Basic</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Fluent">Fluent</option>
                      <option value="Mother Tongue">Mother Tongue</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Level of French</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="levelOfFrench"
                      disabled={langDis ? langDis : false}
                      {...register('levelOfFrench')}
                    >
                      <option value="">
                        {LanguageInfo?.levelOfFrench
                          ? LanguageInfo?.levelOfFrench
                          : 'Select'}
                      </option>
                      <option value="Basic">Basic</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Fluent">Fluent</option>
                      <option value="Mother Tongue">Mother Tongue</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              {/* {childs.map((child, i) => (
            <>
              <h6 className="child-heading education-heading">Education #{i + 1}</h6>
              <div className="education-info">
                <div className="box-grid-three">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Field of Study</Form.Label>
                    <Form.Control type="text" placeholder="Example: dentistry, law, optometry..." />
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Institution</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option>None</option>
                      <option value="1">None</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Country of Study</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </div>
                <div className="box-grid-three">
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Study Duration</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option>None</option>
                      <option value="1">None</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Completed?</Form.Label>
                    <Form.Select aria-label="Default select example">
                      <option>Yes</option>
                      <option value="1">No</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>If not, how long until studies are completed?</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                </div>
                <div className="add-btn">
                  {i >=1 && (
                    <Button className="delete-btn">
                    Delete Education #{i+1} <i class="las la-minus"></i>
                  </Button>
                  )}
                  <Button onClick={handleAddChild}>
                  our Language Courses <i className="las la-plus"></i>
                  </Button>
                </div>
              </div>
            </>
          ))} */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="visa-btn">
          <Button type="submit" disabled={langDis ? langDis : false}>
            Save
          </Button>
          <div className="visa-desc-text">
            <p>
              Once your form is completed, you will be able to make new changes
              by contacting our Support team.
            </p>
            <p>When you're ready, click "Submit"!</p>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default LanguagesSection
