/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ApplicationLayout from '../layout/layout'
import { Table, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import GetUserDetailForPayment from '../Payments/getUserDetail'

let options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
const AllInvoices = () => {
  const [allInvoice, setAllInvoice] = useState([])
  const [openThankYou, setOpenThankYou] = useState(false)

  const getAllInvoices = async () => {
    try {
      const response = await axios.get(`${serverPath}/invoice/getAllInvoice`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const { invoice } = response?.data || []
      if (invoice?.length > 0) {
        setAllInvoice(invoice)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'errr' })
    }
  }
  useEffect(() => {
    getAllInvoices()
  }, [])

  const handleRedirect = async (id) => {
    const response = await axios.post(
      `${serverPath}/invoice/generateSignedUrl`,
      { pdfId: id },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const { data, status } = response
    if (status === 200) {
      window.open(data?.signedUrl, '_blank')
    }
  }

  const handleClose = () => {
    setOpenThankYou(false)
  }
  return (
    <ApplicationLayout>
      <div className={`invoices-page`}>
        <div className="container">
          <GetUserDetailForPayment
            openThankYou={openThankYou}
            handleClose={handleClose}
          />
          <h3>Invoices</h3>
          <div className={`invoices-page-content scroll-content`}>
            <Table responsive>
              <tbody>
                {allInvoice &&
                  allInvoice.length > 0 &&
                  allInvoice.map((el) => (
                    <tr key={el._id}>
                      <td>{el.companyId.name}</td>
                      <td>
                        <div className="invoice-date">
                          {new Date(el?.date)?.toLocaleDateString(
                            'en-US',
                            options,
                          )}
                          <span>#{el.invoiceNumber}</span>
                        </div>
                      </td>
                      <td>
                        <span className="invoice-price">
                          ${el?.productId?.price}
                        </span>
                      </td>
                      <td>
                        <span className="invoice-pdf-menu">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <i class="las la-ellipsis-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {el?.invoicePdf.map((ele) => (
                                <>
                                  {ele.type === 'Proforma' && (
                                    <Dropdown.Item
                                      onClick={() => handleRedirect(ele._id)}
                                    >
                                      {ele.type}
                                    </Dropdown.Item>
                                  )}
                                  {ele?.companyId && (
                                    <Dropdown.Item
                                      onClick={() => handleRedirect(ele._id)}
                                    >
                                      {ele?.companyId?.name}
                                    </Dropdown.Item>
                                  )}
                                </>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default AllInvoices
