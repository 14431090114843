import immigrbanner from '../../assets/images/immigration/immigrbanner.webp'
import studentV from '../../assets/images/home/studentV.webp'
import workingV from '../../assets/images/home/workingV.webp'
import businessV from '../../assets/images/home/businessV.webp'
import whV from '../../assets/images/home/whV.webp'
import familyV from '../../assets/images/home/familyV.webp'
import './immigration.css'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput from 'react-phone-input-2'
import { serverPath } from '../../config/key'
import { Form, Button, Spinner } from 'react-bootstrap'
import Layout from '../layout/layout'
import { useTranslation } from 'react-i18next'
import { ContactSchema } from '../../utils/validationSchema'
import { toast } from 'react-toastify'
import checkicon from '../../assets/images/header/success.svg'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
//import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import ContactForm from './cotactForm'
import { phone } from 'phone'
const Immigration = () => {
  //const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [succ, setSucc] = useState(false)
  const { userGeoData } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
    reset,
    watch,
    control,
  } = useForm({ resolver: yupResolver(ContactSchema) })
  //  const [contactinfo, setContactInfo] = useState({})
  const setContactInfo = useState({})

  const contactData = async () => {
    let ipData
    try {
      ipData = await axios.get(
        'https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0',
      )
    } catch (e) {
      ipData = {
        data: {
          country_code: 'US',
          country_name: 'United States Of America',
          ip: '0.0.0.0',
        },
      }
    }

    const res = await axios.post(`${serverPath}/contact/contactInfo`, {
      countryCode: ipData.data.country_code,
      countryName: ipData.data.country_name,
      ip: ipData.data.IPv4,
      lang: t('language'),
    })
    if (res.data) {
      const cinfo = res.data
      setContactInfo(cinfo)
    }
  }

  useEffect(() => {
    contactData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    i18n.on('languageChanged', contactData)
    return () => {
      i18n.off('languageChanged', contactData)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const [phoneCountryCode, setPhoneCountryCode] = useState('')
  const [loader, setLoader] = useState(false)
  const phoneInputRef = useRef(null)
  const termsCheck = watch('terms')
  const handleChange = (e, country) => {
    setValue('phoneNumber', '+' + e)
    setPhoneCountryCode(country?.countryCode)
    setError('phoneNumber', { message: '' })
  }

  const ContactUser = async (values) => {
    try {
      const validate = phone(values.phoneNumber, {
        country: phoneCountryCode || userGeoData?.country_code,
      })
      if (!validate?.isValid) {
        setError('phoneNumber', { message: 'Your phone number is Invalid' })
        return
      }
      const [first, last] = values?.name?.split(' ')
      values.firstName = first ? first : 'NA'
      values.lastName = last ? last : 'NA'
      setLoader(true)
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      })
      setLoader(false)
      const {
        data: { msg, success },
        status,
      } = response

      if (success || status === 200 || status === 201) {
        reset({
          name: '',
          email: '',
          phoneNumber: '',
        })
        setSucc(true)
        toast.success(msg || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <div className="immigration-page">
        {/*------------------ s1 - immigration about ---------------*/}
        <div className="immigration-about">
          <div className="container">
            <div className="immigration-about-help-content">
              <h2>{t('aboutImmigration.aboutHeading')}</h2>
              <p>{t('aboutImmigration.aboutParagraph1')}</p>
              <p className="text-uppercase">
                {t('aboutImmigration.aboutParagraph2')}
              </p>
              <p>{t('aboutImmigration.aboutParagraph3')}</p>
              <p>{t('aboutImmigration.aboutParagraph4')}</p>
            </div>
          </div>
        </div>
        <img className="immigration-main-banner" src={immigrbanner} alt="" />

        {/*------------------ s2 - immigration eligible ---------------*/}
        <div className="immigration-eligible">
          <div className="container">
            <div className="immigration-eligible-help-content">
              <h2>{t('eligibleImmigration.aboutHeading')}</h2>
              <p>{t('eligibleImmigration.aboutParagraph1')}</p>

              <h5>{t('eligibleImmigration.headerp2')}</h5>
              <p>{t('eligibleImmigration.aboutParagraph2')}</p>
              <ul>
                <li>{t('eligibleImmigration.p2-li1')}</li>
                <li>{t('eligibleImmigration.p2-li2')}</li>
                <li>{t('eligibleImmigration.p2-li3')}</li>
              </ul>

              <h5>{t('eligibleImmigration.headerp3')}</h5>
              <p>{t('eligibleImmigration.aboutParagraph3')}</p>

              <h5>{t('eligibleImmigration.headerp4')}</h5>
              <p>{t('eligibleImmigration.aboutParagraph4')}</p>
              <div className="busineselig">
                <ul>
                  <li>{t('eligibleImmigration.p4-li1')}</li>
                  <li>{t('eligibleImmigration.p4-li2')}</li>
                </ul>
                <ul>
                  <li>{t('eligibleImmigration.p4-li3')}</li>
                  <li>{t('eligibleImmigration.p4-li4')}</li>
                </ul>
                <ul>
                  <li>{t('eligibleImmigration.p4-li5')}</li>
                  <li>{t('eligibleImmigration.p4-li6')}</li>
                </ul>
              </div>

              <h5>{t('eligibleImmigration.headerp5')}</h5>
              <p>{t('eligibleImmigration.aboutParagraph5')}</p>
              <ul>
                <li>{t('eligibleImmigration.p5-li1')}</li>
                <li>{t('eligibleImmigration.p5-li2')}</li>
                <li>{t('eligibleImmigration.p5-li3')}</li>
              </ul>
            </div>
          </div>
        </div>
        {/*------------------ s3 - immigration form ---------------*/}
        {/* <div className="immigration-form home-eligibility">
          <div className="container">
            <div className="eligibility-grid">
              <div className="eligibility-content">
                <h4>{t('Immigrationform.formheading')}</h4>
                <p>{t('Immigrationform.paragraph')}</p>

                <Form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div className=" d-lg-flex d-sm-block elForm">
                    <Form.Group>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder={t('visaContact.fullname')}
                        {...register('name')}
                      />
                      {errors?.name ? (
                        <span className="error-msg">{errors.name.message}</span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        name="email"
                        type="email"
                        placeholder={t('visaContact.emailaddress')}
                        {...register('email')}
                      />
                      {errors?.email ? (
                        <span className="error-msg">{errors.email.message}</span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group>
                      <PhoneInput
                        country={'de'}
                        onChange={handleChange}
                        inputProps={{
                          name: 'phoneNumber',
                        }}
                      />

                      {errors?.phoneNumber ? (
                        <span className="error-msg">{errors.phoneNumber.message}</span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group class="checkbox d-lg-none">
                      <Form.Check.Input type="checkbox" defaultChecked />
                      <Form.Check.Label>{parse(t("homeEligibility.checkbox"))}</Form.Check.Label>
                    </Form.Group>

                    <Button
                      type="submit"
                      className={`blue-button ${succ ? `success-btn` : ``}`}
                    >
                      {succ
                        ? t('Immigrationform.successButton')
                        : t('Immigrationform.blueButton')}
                      {succ ? <img src={checkicon} alt="" /> : ''}
                    </Button>
                  </div>
                  <Form.Group class="checkbox d-none d-lg-flex">
                    <Form.Check.Input type="checkbox" defaultChecked />
                    <Form.Check.Label>{parse(t("homeEligibility.checkbox"))}</Form.Check.Label>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div> */}
        <ContactForm />

        {/*------------------ s4 - immigration Interest ---------------*/}
        <div className="immigration-eligible">
          <div className="container">
            <div className="immigration-eligible-help-content">
              <h2>{t('InterestImmigration.InterestHeading')}</h2>
              <p>{t('InterestImmigration.InterestParagraph1')}</p>
              <p>{t('InterestImmigration.InterestParagraph2')}</p>
              <p>
                <a
                  href="https://immi.homeaffairs.gov.au/visas/working-in-australia/skillselect"
                  rel="noreferrer"
                  target="_blank"
                  className="link"
                >
                  {t('InterestImmigration.InterestParagraph3')}
                </a>
                {t('InterestImmigration.InterestParagraph4')}
              </p>
              <p>{t('InterestImmigration.InterestParagraph5')}</p>
            </div>
          </div>
        </div>

        {/*------------------ s5 - immigration Apply ---------------*/}
        <div className="immigration-eligible">
          <div className="container">
            <div className="immigration-eligible-help-content">
              <h2>{t('ApplyImmigration.ApplyHeading')}</h2>
              <p>{t('ApplyImmigration.ApplyParagraph1')}</p>
              <p>{t('ApplyImmigration.ApplyParagraph7')}</p>

              <h5>{t('ApplyImmigration.headerp2')}</h5>
              <p>{t('ApplyImmigration.ApplyParagraph2')}</p>

              <h5>{t('ApplyImmigration.headerp3')}</h5>
              <p>{t('ApplyImmigration.ApplyParagraph3')}</p>

              <h5>{t('ApplyImmigration.headerp4')}</h5>
              <p>{t('ApplyImmigration.ApplyParagraph4')}</p>

              <h5>{t('ApplyImmigration.headerp5')}</h5>
              <p>{t('ApplyImmigration.ApplyParagraph5')}</p>

              <h5>{t('ApplyImmigration.headerp6')}</h5>
              <p>{t('ApplyImmigration.ApplyParagraph6')}</p>
            </div>
          </div>
        </div>

        {/*------------------ s6 - immigration visas ---------------*/}
        <div className="immigration-eligible3">
          <div className="container">
            <h2>{t('visaImmigration.visaHeading')}</h2>
            <p className="caption text-center">{t('homeVisa.permanent')}</p>
            <div className="home-visa-grid-section">
              <div className="home-visa-grid">
                <div className="home-visa-content">
                  <div className="home-visa-grid">
                    {/* visa1   */}
                    <div className="home-visa-box">
                      <a href="/visa/worker">
                        <div className="home-visa-gutter">
                          <div className="home-visa-cover">
                            <img src={workingV} alt="" />
                          </div>

                          <div className="home-visa-content-text">
                            <h4>{t('homevisabox1.heading')}</h4>
                            <p>{t('homevisabox1.paragraph')}</p>
                            <a
                              className="link-hover"
                              href="https://www.uiscanada.com/blog/the-4-best-apps-to-learn-english-before-moving-to-canada/"
                            >
                              {t('homevisabox1.learnmore')}
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* visa2   */}
                    <div className="home-visa-box">
                      <a href="/visa/family">
                        <div className="home-visa-gutter">
                          <div className="home-visa-cover">
                            <img src={familyV} alt="" />
                          </div>

                          <div className="home-visa-content-text">
                            <h4>{t('homevisabox2.heading')}</h4>
                            <p>{t('homevisabox2.paragraph')}</p>
                            <a
                              className="link-hover"
                              href="https://www.uiscanada.com/blog/7-things-youll-wish-someone-told-you-before-moving-to-canada/"
                            >
                              {t('homevisabox2.learnmore')}
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* visa3   */}
                    <div className="home-visa-box">
                      <a href="/visa/business">
                        <div className="home-visa-gutter">
                          <div className="home-visa-cover">
                            <img src={businessV} alt="" />
                          </div>

                          <div className="home-visa-content-text">
                            <h4>{t('homevisabox3.heading')}</h4>
                            <p>{t('homevisabox3.paragraph')}</p>
                            <a
                              className="link-hover"
                              href="https://www.uiscanada.com/blog/managing-tax-as-a-backpacker-in-canada-a-quick-guide/"
                            >
                              {t('homevisabox3.learnmore')}
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>{' '}
            </div>

            <p className="caption text-center">{t('homeVisa.temporary')}</p>
            <div className="home-visa-grid-section">
              <div className="home-visa-grid">
                <div className="home-visa-content">
                  <div className="home-visa-grid">
                    {/* visa4   */}
                    <div className="home-visa-box">
                      <a href="/visa/holiday">
                        <div className="home-visa-gutter">
                          <div className="home-visa-cover">
                            <img src={whV} alt="" />
                          </div>

                          <div className="home-visa-content-text">
                            <h4>{t('homevisabox4.heading')}</h4>
                            <p>{t('homevisabox4.paragraph')}</p>
                            <a
                              className="link-hover"
                              href="https://www.uiscanada.com/blog/the-4-best-apps-to-learn-english-before-moving-to-canada/"
                            >
                              {t('homevisabox4.learnmore')}
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* visa6   */}
                    <div className="home-visa-box">
                      <a href="/visa/student">
                        <div className="home-visa-gutter">
                          <div className="home-visa-cover">
                            <img src={studentV} alt="" />
                          </div>

                          <div className="home-visa-content-text">
                            <h4>{t('homevisabox6.heading')}</h4>
                            <p>{t('homevisabox6.paragraph')}</p>
                            <a
                              className="link-hover"
                              href="https://www.uiscanada.com/blog/managing-tax-as-a-backpacker-in-canada-a-quick-guide/"
                            >
                              {t('homevisabox6.learnmore')}
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------------ s7 - immigration contact ---------------*/}
        <div className="immigration-contact">
          <div className="container d-lg-flex d-sm-block">
            <div className="immigration-contact-content">
              <h2>{t('contactImmigration.contactHeading')}</h2>
              <p>{t('contactImmigration.contactParagraph1')}</p>
              <p>{t('contactImmigration.contactParagraph2')}</p>
              <p>{t('contactImmigration.contactParagraph3')}</p>
            </div>
            <div className="immigration-contact-form">
              <div className="contact-form">
                <div className="contact-form-content">
                  <h2>{t('contactImmigration.contactformHeading1')}</h2>
                  <p>{t('contactImmigration.contactformParagraph1')}</p>

                  <Form onSubmit={handleSubmit(ContactUser)}>
                    <Form.Group>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder={t('contactImmigration.fullname')}
                        {...register('name')}
                      />

                      {errors?.name ? (
                        <span className="error-msg">{errors.name.message}</span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        name="email"
                        type="text"
                        placeholder={t('contactImmigration.emailaddress')}
                        {...register('email')}
                      />
                      {errors?.email ? (
                        <span className="error-msg">
                          {errors.email.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Controller
                        name="phoneNumber" // Replace with the actual name of your input field
                        control={control}
                        defaultValue="" // Set a default empty value
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={
                              userGeoData?.country_code
                                ? userGeoData?.country_code?.toLowerCase()
                                : 'de'
                            }
                            ref={phoneInputRef}
                            onChange={(e, country) => handleChange(e, country)}
                            inputProps={{
                              name: 'phoneNumber', // Replace with the actual name of your input field
                            }}
                          />
                        )}
                      />

                      {errors?.phoneNumber ? (
                        <span className="error-msg">
                          {errors.phoneNumber.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group class="checkbox ">
                      <Form.Check.Input
                        type="checkbox"
                        name="terms"
                        defaultChecked={true}
                        {...register('terms')}
                      />
                      <Form.Check.Label>
                        {parse(t('homeEligibility.checkbox'))}
                      </Form.Check.Label>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className={`${succ ? `success-btn` : ``}`}
                      disabled={
                        (termsCheck === undefined && !loader) ||
                        (termsCheck && !loader)
                          ? false
                          : loader
                          ? loader
                          : true
                      }
                    >
                      {loader && (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      )}
                      &nbsp;
                      {t('contactImmigration.submit')}{' '}
                      {succ ? <img src={checkicon} alt="" /> : ''}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Immigration
