import axios from "axios";
import { useContext } from "react";
import { serverPath } from "../../config/key";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import attached from "../../assets/images/application/check-circle.svg";
import trash from "../../assets/images/application/delete.svg";
import UploadIcon from "../../assets/icons/UploadIcon";
const LetterClearanceInfo = ({
  documentInfo,
  setDocumentInfo,
  documentDis,
}) => {
  const { selectedVisa } = useContext(UserContext);
  const handleFile = async (e) => {
    const {
      target: { files, name },
    } = e;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", name);
    const res = await axios.post(`${serverPath}/visa/visaDocuments`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
      },
    });
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc;
      setDocumentInfo({ ...documentInfo, ...data });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeDocument`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo(data);
      }
    } catch (err) {
      console.log("Error is here-->", err);
      toast.error(err.message, { toastId: "erro" });
    }
  };
  return (
    <div className="general-info">
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter clearance Info</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.letterClearanceInfo ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("letterClearanceInfo")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="uploadClearance1"
                type="file"
                name="letterClearanceInfo"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance1">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter of Acceptance</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.letterAcceptance ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("letterAcceptance")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="uploadClearance2"
                type="file"
                name="letterAcceptance"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance2">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Educational Diploma</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.educationalDiploma ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("educationalDiploma")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="uploadClearance3"
                type="file"
                name="educationalDiploma"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance3">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>
            Proof of Medical Exam From A Canadian Approved Physician (see
            Guidelines)
          </h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.canadianMedicalExamProof ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("canadianMedicalExamProof")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="uploadClearance4"
                type="file"
                name="canadianMedicalExamProof"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance4">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Police Clearance (see Guidelines) </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.policeClearance ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("policeClearance")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="uploadClearance5"
                type="file"
                name="policeClearance"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance5">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Language Test Results</h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.langTestResult ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("langTestResult")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="uploadClearance6"
                type="file"
                name="langTestResult"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance6">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Copy of Visa or Resident Visa</h6>
        </div>
        <div className="middle-info">
          <h6>(if you are a resident of another country) </h6>
        </div>
        {documentInfo?.visaResident ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("visaResident")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            <div className="upload-image">
              <input
                id="uploadClearance7"
                type="file"
                name="visaResident"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance7">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter of Motives (see Guidelines)</h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.letterOfMotive ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("letterOfMotive")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            <div className="upload-image">
              <input
                id="uploadClearance8"
                type="file"
                name="letterOfMotive"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="uploadClearance8">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default LetterClearanceInfo;
