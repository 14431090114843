import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import { toast } from 'react-toastify'
import { Form, Button } from 'react-bootstrap'
import logo from '../../assets/images/header/logo-white.svg'
// import envelope from '../../assets/images/login/envelope.svg'
import instagram from '../../assets/images/login/instagram.svg'
import facebook from '../../assets/images/login/facebook.svg'
import linkedin from '../../assets/images/login/linkedin.svg'
import twitter from '../../assets/images/login/twitter.svg'
import 'react-toastify/dist/ReactToastify.css'
import './login.css'
import { LoginSchema } from '../../utils/validationSchema'
import UserContext from '../../context/UserContext'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const navigate = useNavigate()
  const [logInData, setLogInData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  })
  const { setUser, setToken } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) })
  const { t } = useTranslation()

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    if (name === 'rememberMe') {
      setLogInData({
        ...logInData,
        rememberMe: checked,
      })
    } else
      setLogInData({
        ...logInData,
        [name]: value,
      })
  }

  const logInUser = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/users/login`, values)
      const { status, token, user } = response.data
      if (status === 200) {
        toast.success('Login Successful', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        setUser(user)
        setToken(token)
        navigate('/welcome')
      }
    } catch (error) {
      if (error?.response?.data) {
        if (error.response.data.status === 401) {
          navigate('/verifyUser')
          localStorage.setItem('phone', error.response.data.phone)
        }
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">

          <div className="login-content">
            <div className='login-container'>
              <Link to="/">
                <img src={logo} alt="" className="logo-login" />
              </Link>
              <div className='login-text'>
                <h2>Welcome to UIS!</h2>
                <div className="login-social">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/uisAustraliaoffical"
                      >
                        <img src={facebook} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/uis_australia/"
                      >
                        <img src={instagram} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/universal-immigration-visa-services-australia/"
                      >
                        <img src={linkedin} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/uis_australia"
                      >
                        <img src={twitter} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="login-form">
            
              <a className="breadcrum" href="/">
                {t('login.Heading')}
              </a>
              <div className="login-popup-content">
              <h5>Sign In</h5>
              <p>Enter your email and password to sign in</p>

              <Form onSubmit={handleSubmit(logInUser)}>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    {...register('email')}
                    defaultValue={logInData.email}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    className={`${errors?.password ? `error-border` : ``}`}
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    defaultValue={logInData.password}
                    {...register('password')}
                  />
                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <div className="one-time">
                  <Link to="/phoneverify">Login with a one-time password</Link>
                </div>

                <div className="forget-section">
                  <div className="remember-me">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Remember me"
                      name="rememberMe"
                      onChange={handleChange}
                      {...register('rememberMe', { required: false })}
                    />
                  </div>
                  <Link to="/phoneverify">Forgot Password</Link>
                </div>

                <Button type="submit">Sign In</Button>
                <div className="one-time">
                  Don't have an account?{' '}
                  <Link to="/register"> &nbsp;Register</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Login
