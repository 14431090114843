import { Form, Accordion } from 'react-bootstrap'

const AdaptabilityInfo = () => {
  return (
    <div className="personal-info adaptability-info">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Main Applicant</Accordion.Header>
          <Accordion.Body>
            <h6>Education</h6>
            <div className="box-grid-three">
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Did You Study in Canada??</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Are Your Relatives 18+?</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>What is Your Relationship?</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
            <h6>Canadian Relatives</h6>
            <div className="box-grid-three">
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>
                  Do You Have Relatives With PR/Citizenship?
                </Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Are Your Relatives 18+?</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>What is Your Relationship?</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
            <h6>Internship</h6>
            <div className="box-grid-two">
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>
                  Did You Have Paid or Non-Paid Internship in Canada?
                </Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
export default AdaptabilityInfo
