import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput from 'react-phone-input-2'
import { serverPath } from '../../config/key'
import { Form, Button, Spinner } from 'react-bootstrap'
import {phone} from 'phone'
import Layout from '../layout/layout'
import { useTranslation } from 'react-i18next'
import { ContactSchema } from '../../utils/validationSchema'
import { toast } from 'react-toastify'
import envelope from '../../assets/images/contact/envelope.svg'
import call from '../../assets/images/contact/call.svg'
import locationicon from '../../assets/images/contact/location.svg'
import contactbg from '../../assets/images/contact/contactbg.svg'
import checkicon from '../../assets/images/header/success.svg'
import './contact.css'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'

const Contact = () => {
  const { t, i18n } = useTranslation()
  const [succ, setSucc] = useState(false)
  const [loading,setLoading]=useState(false)
  const { userGeoData } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError
  } = useForm({ resolver: yupResolver(ContactSchema) })
  const [contactinfo, setContactInfo] = useState({})
  const [phoneCountryCode,setPhoneCountryCode]=useState('')
  const contactData = async () => {
    let ipData
    try {
      ipData = await axios.get(
        'https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0',
      )
    } catch (e) {
      ipData = {
        data: {
          country_code: 'US',
          country_name: 'United States Of America',
          ip: '0.0.0.0',
        },
      }
    }

    const res = await axios.post(`${serverPath}/contact/contactInfo`, {
      countryCode: ipData.data.country_code,
      countryName: ipData.data.country_name,
      ip: ipData.data.IPv4,
      lang: t('language'),
    })
    if (res.data) {
      const cinfo = res.data
      setContactInfo(cinfo)
    }
  }

  useEffect(() => {
    contactData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    i18n.on('languageChanged', contactData)
    return () => {
      i18n.off('languageChanged', contactData)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e,country) => {
      setPhoneCountryCode(country?.countryCode)
      setValue('phoneNumber', '+' + e)
      setError('phoneNumber',{message:""}) 
  }

  const ContactUser = async (values) => {
    try {
      
      const validate=phone(values.phoneNumber, {country: phoneCountryCode});
      if(!validate?.isValid){
        setError('phoneNumber',{message:"Your phone number is Invalid"})
        return
      } 
      setLoading(true)
      const response = await axios.post(`${serverPath}/forms/contact`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      })
      setLoading(false)
      const {
        data: { msg, code },
      } = response

      if (code === 200) {
        setSucc(true)
        toast.success(msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <div className="contact-page">
        <div className="container">
          <div className="contact-grid">
            <div className="contact-content">
              <h2>{t('contactPage.contactHeading1')}</h2>
              <div className="contact-box">
                <div className="contact-info-grid">
                  <img src={call} alt="" />
                  <div className="contact-info-text">
                    <span>{contactinfo?.phone?.phone1}</span>

                    <span>
                      {contactinfo?.phone?.phone2}{' '}
                      {contactinfo?.phone?.tollFree}
                    </span>
                  </div>
                </div>

                <div className="contact-info-grid">
                  <img src={envelope} alt="" />
                  <div className="contact-info-text">
                    <span> {contactinfo?.email}</span>
                  </div>
                </div>
              </div>

              <h2 class="mt-4">{t('contactPage.contactHeading2')}</h2>

              <div className="contact-box">
                <div className="contact-info-grid">
                  <img src={locationicon} alt="" />
                  <div className="contact-info-text">
                    <span>
                      {contactinfo?.address?.line1}
                      <br />
                      {contactinfo?.address?.line2}
                    </span>
                  </div>
                </div>

                <div className="contact-info-grid">
                  <img src={locationicon} alt="" />
                  <div className="contact-info-text">
                    <span>
                      {' '}
                      {contactinfo?.address2?.line1}
                      <br />
                      {contactinfo?.address2?.line2}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={contactbg}
              alt=""
              className="d-lg-none bridge"
            />
            <div className="contact-form">
              <div className="contact-form-content">
                <h2>{t('contactForm.contactHeading')}</h2>
                <p>{t('contactForm.contactParagraph')}</p>

                <Form onSubmit={handleSubmit(ContactUser)}>
                  <Form.Group>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder={t('visaContact.fullname')}
                      {...register('name')}
                    />

                    {errors?.name ? (
                      <span className="error-msg">{errors.name.message}</span>
                    ) : (
                      ''
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder={t('visaContact.emailaddress')}
                      {...register('email')}
                    />
                    {errors?.email ? (
                      <span className="error-msg">{errors.email.message}</span>
                    ) : (
                      ''
                    )}
                  </Form.Group>

                  <Form.Group>
                    <PhoneInput
                      country={
                        userGeoData?.country_code
                          ? userGeoData?.country_code?.toLowerCase()
                          : 'de'
                      }
                      onChange={(e,country)=>handleChange(e,country)}
                      inputProps={{
                        name: 'phoneNumber',
                      }}
                    />

                    {errors?.phoneNumber ? (
                      <span className="error-msg">
                        {errors.phoneNumber.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      name="message"
                      as="textarea"
                      rows={3}
                      placeholder={t('visaContact.massege')}
                      {...register('message', { required: false })}
                    />
                    {errors?.message ? (
                      <span className="error-msg">
                        {errors.message.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className={`${succ ? `success-btn` : ``}`}
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;
                    {t('contactForm.submit')}{' '}
                    {succ ? <img src={checkicon} alt="" /> : ''}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
