/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
import ApplicationLayout from '../myapplication/layout'
import { Tab, Tabs, Button } from 'react-bootstrap'
import PersonalInfo from './personalInfo'
import './index.css'
import EducationInfo from './educationInfo'
import WorkExperience from './workExperience'
import LanguagesSection from './languagesSection'
import Documents from './documents'
// import BusinessInfo from './businessInfo'
// import AdaptabilityInfo from './adaptabilityInfo'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import axios from 'axios'
import lock from '../../assets/images/visa/lock.svg'
import { useParams } from 'react-router-dom'
import AdaptabilityInfo from './adaptabilityInfo'
import BusinessInfo from './businessInfo'

const VisaForm = () => {
  const { formType } = useParams()
  console.log(' abrar form type=>', formType)
  const [openVisaApplication, setOpenVisaApplication] = useState(false)
  ///const [visaDetail, setVisaDetail] = useState({})
  const { user, selectedVisa, setSavePercentage } = useContext(UserContext)
  const [openForm, setOpenForm] = useState([])
  const [showSelectedMessage, setShowSelectMessage] = useState(false)
  const [personalInformation, setPersonalInfo] = useState()
  const [educationInfo, setEducationInfo] = useState()
  const [experienceInfo, setExperienceInfo] = useState()
  const [LanguageInfo, setLanguageInfo] = useState()
  const [documentInfo, setDocumentInfo] = useState()
  const [saveCount, setSaveCount] = useState(0)
  const [formCount, setFormCount] = useState(0)
  const [sponsorForms, setSponsorForms] = useState([])
  const [showFormTab, setShowFormTab] = useState([])
  const [sponsoredInfo, setSponsoredInfo] = useState()
  const [adaptabilityInfo, setAdaptabilityInfo] = useState()
  const [businessInfo, setBusinessInfo] = useState()
  const checkProVisaType = async () => {
    const res = await axios.get(`${serverPath}/product/checkProductVisaType`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      params: selectedVisa,
    })
    const {
      visaData,
      personal,
      education,
      Experience,
      language,
      document,
      business,
      personalSponsoredInfo,
      personalSponsoringInfo,
      adaptability,
    } = res?.data?.visaData || {}
    const { visaType } = visaData?.productId || {}
    if (visaData?.productId && visaType?.forms?.length) {
      setFormCount(visaType?.forms?.length)
      setOpenForm(visaType?.forms)
    }
    if (visaData?.productId && visaType?.formsSponsor?.length) {
      setSponsorForms(visaType?.formsSponsor)
    }
    let count = []
    if (personal) {
      if (personal?.isLocked) {
        count.push(1)
        setPersonalInfo({ ...personalSponsoredInfo, ...personal })
      }
    }
    if (personalSponsoringInfo) {
      if (personalSponsoringInfo?.isLocked) {
        setPersonalInfo({
          ...personal,
          ...personalSponsoredInfo,
          ...personalSponsoringInfo,
        })
      }
    }
    if (personalSponsoredInfo) {
      if (personalSponsoredInfo?.isLocked) {
        setPersonalInfo({
          ...personal,
          ...personalSponsoredInfo,
          ...personalSponsoringInfo,
        })
      }
    }
    if (education) {
      if (education?.isLocked) {
        count.push(2)
        setEducationInfo(education)
      }
    }
    if (Experience) {
      if (Experience?.isLocked) {
        count.push(3)
        setExperienceInfo(Experience)
      }
    }
    if (language) {
      if (language?.isLocked) {
        count.push(4)
        setLanguageInfo(language)
      }
    }
    if (document) {
      if (document?.isLocked) {
        count.push(5)
        setDocumentInfo(document)
      }
    }
    if (adaptabilityInfo) {
      if (adaptability?.isLocked) {
        count.push(5)
        setDocumentInfo(document)
      }
    }
    if (businessInfo) {
      if (business?.isLocked) {
        count.push(5)
        setDocumentInfo(document)
      }
    }
    setSaveCount(count?.length)
  }
  useEffect(() => {
    if (formType === 'sponsored') {
      setShowFormTab(sponsorForms)
    } else if (formType === 'sponsoring') {
      setShowFormTab(openForm)
    } else {
      setShowFormTab(openForm)
    }
  }, [formType, openForm, sponsorForms])
  useEffect(() => {
    if (saveCount !== 0 && formCount !== 0) {
      const per = (Number(saveCount) / Number(formCount)) * 100
      setSavePercentage(per)
    }
  }, [
    selectedVisa,
    openForm,
    personalInformation,
    educationInfo,
    experienceInfo,
    LanguageInfo,
    documentInfo,
  ])

  useEffect(() => {
    if (selectedVisa?.invoiceId) {
      setOpenVisaApplication(false)
      setShowSelectMessage(false)
      checkProVisaType()
    } else if (selectedVisa?.visaId) {
      setShowSelectMessage(false)
      setOpenVisaApplication(true)
    } else setShowSelectMessage(true)
  }, [selectedVisa])
  const purchasePackage = async () => {
    const providerId = '62e9117ccc0c0af330f7c40c'
    const res1 = await axios.get(`${serverPath}/product/visaProductByVisaId`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      params: { visaId: selectedVisa.visaId },
    })
    const { productData } = res1?.data || {}
    const res = await axios.get(
      `${serverPath}/payment-provider/supportPayment/${productData._id}/${user._id}/${providerId}`,
    )
    if (res?.data) {
      window.location.href = res?.data?.url
    }
  }

  const capitalize = (strg) => {
    let str2 = ''
    strg.split('').forEach((el, index) => {
      if (index !== 0 && el === el.toUpperCase()) {
        str2 += ` ${el}`
      } else {
        str2 += el
      }
    })
    return str2.charAt(0).toUpperCase() + str2.slice(1)
  }
  const getFromTab = (tab) => {
    switch (tab) {
      case 'personalInfo':
        return (
          <PersonalInfo
            personalInformation={personalInformation}
            setPersonalInfo={setPersonalInfo}
            setSponsoredInfo={setSponsoredInfo}
            sponsoredInfo={sponsoredInfo}
          />
        )
      case 'education':
        return (
          <EducationInfo
            educationInfo={educationInfo}
            setEducationInfo={setEducationInfo}
          />
        )
      case 'workExperience':
        return (
          <WorkExperience
            experienceInfo={experienceInfo}
            setExperienceInfo={setExperienceInfo}
          />
        )
      case 'language':
        return (
          <LanguagesSection
            LanguageInfo={LanguageInfo}
            setLanguageInfo={setLanguageInfo}
          />
        )
      case 'documents':
        return (
          <Documents
            documentInfo={documentInfo}
            setDocumentInfo={setDocumentInfo}
          />
        )
      case 'adaptability':
        return (
          <AdaptabilityInfo
            adaptabilityInfo={adaptabilityInfo}
            setAdaptabilityInfo={setAdaptabilityInfo}
          />
        )
      case 'business':
        return (
          <BusinessInfo
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
          />
        )
      default:
        return ''
    }
  }
  const getDynamicClass = (tab) => {
    switch (tab) {
      case 'personalInfo':
        return personalInformation && personalInformation?.isLocked
          ? true
          : false
      case 'education':
        return educationInfo && educationInfo?.isLocked ? true : false
      case 'workExperience':
        return experienceInfo && experienceInfo?.isLocked ? true : false
      case 'language':
        return LanguageInfo && LanguageInfo?.isLocked ? true : false
      case 'documents':
        return documentInfo && documentInfo?.isLocked ? true : false
      case 'adaptability':
        return adaptabilityInfo && adaptabilityInfo?.isLocked ? true : false
      case 'business':
        return businessInfo && businessInfo?.isLocked ? true : false
      default:
        return ''
    }
  }

  return (
    <ApplicationLayout>
      {openVisaApplication ? (
        <>
          <h2>
            In order to start your visa application please purchase a package
            first
          </h2>
          <Button onClick={purchasePackage}>Buy</Button>
        </>
      ) : showSelectedMessage ? (
        <div className="select-visatype">
          <h2>Please Select visa type</h2>
        </div>
      ) : (
        <div className="visa-form-page">
          <div className="container">
            <div className="home-visa-tab">
              <Tabs
                id="uncontrolled-tab-example"
                className=""
                defaultActiveKey="personalInfo"
              >
                {console.log('Sponsor form detail=>', showFormTab)}
                {showFormTab &&
                  showFormTab?.length > 0 &&
                  showFormTab?.map((el) => (
                    <Tab
                      eventKey={el}
                      title={
                        <span>
                          {capitalize(el)}
                          {getDynamicClass(el) && <img src={lock} alt="lock" />}
                        </span>
                      }
                    >
                      {getFromTab(el)}
                    </Tab>
                  ))}
                {/* <Tab eventKey="Personal Info" title="Personal Info">
                <PersonalInfo />
              </Tab>
              <Tab eventKey="Education" title="Education">
                <EducationInfo />
              </Tab>
              <Tab eventKey="Work Experience" title="Work Experience">
                <WorkExperience />
              </Tab>
              <Tab eventKey="Languages" title="Languages">
                <LanguagesSection />
              </Tab>
              <Tab eventKey="Business" title="Business">
                <BusinessInfo />
              </Tab>
              <Tab eventKey="Adaptability" title="Adaptability">
                <AdaptabilityInfo />
              </Tab>
              <Tab eventKey="Documents" title="Documents">
                <Documents />
              </Tab> */}
              </Tabs>
              {/* <div className="visa-btn">
              <Button>Save</Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </ApplicationLayout>
  )
}
export default VisaForm
