import axios from "axios";
import { useContext } from "react";
import { toast } from "react-toastify";
import { serverPath } from "../../config/key";
import UserContext from "../../context/UserContext";
import attached from "../../assets/images/application/check-circle.svg";
import trash from "../../assets/images/application/delete.svg";
import UploadIcon from "../../assets/icons/UploadIcon";
const EmployeementFinancialDoc = ({
  documentInfo,
  setDocumentInfo,
  documentDis,
}) => {
  const { selectedVisa } = useContext(UserContext);
  const handleFile = async (e) => {
    const {
      target: { files, name },
    } = e;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", name);

    const res = await axios.post(`${serverPath}/visa/visaDocuments`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
      },
    });
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc;
      setDocumentInfo({ ...documentInfo, ...data });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeDocument`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc;
        setDocumentInfo(data);
      }
    } catch (err) {
      toast.error(err.message, { toastId: "erro" });
    }
  };
  return (
    <div className="general-info">
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Employment Reference Letter </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.empReferenceLetter ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("empReferenceLetter")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="empFinance1"
                type="file"
                name="empReferenceLetter"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="empFinance1">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Recent Bank Statement</h6>
        </div>
        <div className="middle-info">
          <h6>
            (account balance must include at least $10,000 CAD + 1st year
            tuition fees){" "}
          </h6>
        </div>
        {documentInfo?.bankStatement ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("bankStatement")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
           
            <div className="upload-image">
              <input
                id="empFinance2"
                type="file"
                name="bankStatement"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="empFinance2">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>
            Payment Receipt (to establish that the 1st semester has been paid)
          </h6>
        </div>
        <div className="middle-info">
          <h6>(to establish that the 1st semester has been paid) </h6>
        </div>
        {documentInfo?.paymentReceipts ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <span onClick={() => removeFile("paymentReceipts")}>
                <img
                  src={trash}
                  alt="uploadimg"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="right-info">
            
            <div className="upload-image">
              <input
                id="empFinance3"
                type="file"
                name="paymentReceipts"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="empFinance3">
              <h6>Upload</h6>
                <UploadIcon />{" "}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EmployeementFinancialDoc;
