import * as yup from 'yup'

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required'),
    password: yup.string().required('Password is required'),
  })
  .required()
export const ResetPasswordSchema = yup.object({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})

export const PhoneVerifySchema = yup.object({
  phone: yup.string().required('Phone number is required'),
})

export const PhoneOtpSchema = yup.object({
  otp: yup.string().required('OTP is required'),
})

export const ContactSchema = yup.object().shape({
  name: yup.string().max(15,'Name cannot be longer than 15 characters').required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const ConsultForm = yup.object().shape({
  firstName: yup.string().max(15,'First name cannot be longer than 15 characters').required('First name is required'),
  lastName: yup.string().max(15,'Last name cannot be longer than 15 characters').required('Last name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})

export const RegisterSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  password: yup.string().required('Phone number is required'),
})
export const userSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  address: yup.string().required('Address is required'),
  lastName: yup.string().required('Last name is required'),
  name: yup.string().required('Name is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  phone: yup.string().required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})
export const updateUserSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})
export const NewsLetterSchema = yup.object({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})

export const updatePasswordSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
export const invoiceSchema = yup.object().shape({
  companyId: yup.string().required('Company Name is required'),
  productId: yup.string().required('Product Name is required'),
  brandId: yup.string().required('Brand is required'),
  clientEmail: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  clientName: yup.string().required('Client Name is required'),
  clientCity: yup.string().required('Client City is required'),
  clientCountry: yup.string().required('Client Country is required'),
  clientAddress: yup.string().required('Client Address is required'),
  paymentMethod: yup.string().required('Payment method is required'),
  clientPhone: yup.string().required('Phone number is required'),
})

export const userDetailSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email2: yup.string().required('Email is required'),
  address: yup.string().required('address is required'),
  city: yup.string().required('country is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const education = yup.object().shape({
  highestLevelEdu: yup.string().required('Highest level education is required'),
  fieldStudy: yup.string().required('Field study  is required'),
  institution: yup.string().required('Institution is required'),
  studyDuration: yup.string().required('Study duration is required'),
  countryStudy: yup.string().required('Country study is required'),
  completed: yup.string().required('Completed is required'),
  studyRemainTime: yup.string().required('Study remain time is required'),
  yearsEducation: yup.string().required('Year education is required'),
})
export const workExperience = yup.object().shape({
  numberOfJobs: yup.string().required('Number of jobs is required'),
  officialJobTitle: yup.string().required('Official job title is required'),
  jobDescription: yup.string().required('Job description is required'),
  yearsExperience: yup.string().required('Year Experience is required'),
  jobStartDate: yup.string().required('Job start date is required'),
  jobEndTime: yup.string().required('Job end time is required'),
  fullTimePosition: yup.string().required('Full time position is required'),
  hoursPerWeek: yup.string().required('Hours per week  is required'),
  monthlyIncome: yup.string().required('Monthly Income is required'),
  moneyBroughtToCanada: yup
    .string()
    .required('Money brought to canad is required'),
})
export const PersonalSpouseInfoSchema = yup.object().shape({
  sFirstName: yup.string().required('First Name is required'),
  sMidName: yup.string(),
  sLastName: yup.string().required('Last Name is required'),
  sAge: yup.number(),
  sPhoneNumber: yup.string(),
  sMobile: yup.string(),
  sOfficePhoneNumber: yup.string(),
  sGender: yup.string().required('Gender is required'),

  sPrimaryEmail: yup
    .string()
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sSecondaryEmail: yup.string(),
  sDateOfBirth: yup.string().required('Date of birth is required').nullable(),
  sBirthCountry: yup.string().required('Birth country is required'),
  sCity: yup.string().required('City is required'),
  sStreet: yup.string().required('Street is required'),
  sCountryCitizenship: yup.string().required('Country citizenship is required'),
  sCountryResidence: yup.string().required('Country residence is required'),
  sZipCode: yup.string().required('Zip code is required'),
  sMaritalStatus: yup.string().required('Marital status is required'),
  sDependentChildren: yup.string().when('sMaritalStatus', {
    is: 'Married' || 'Legally Separated' || 'Divorced' || 'Widowed',
    then: yup.string().required('Dependent children is required'),
  }),
  // sDependentChildren: yup.string().required('Dependent children is required'),
  sCriminalOffense: yup.string().required('Police Record is required'),
  sOffenseTypeDetail: yup.string().when('sCriminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sHealthCondition: yup.string().required('Health condition is required'),
  sHealthConditionDetail: yup.string().when('sHealthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})
export const PersonalMainApplicantInfoSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  midName: yup.string(),
  lName: yup.string().required('Last Name is required'),
  age: yup.number(),
  phoneNumber: yup.string(),

  mobile: yup.string(),
  officePhoneNumber: yup.string(),

  gender: yup.string().required('Gender is required'),

  primaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  secondaryEmail: yup.string(),
  dateOfBirth: yup.string().required('Date of birth is required').nullable(),
  birthCountry: yup.string().required('Birth country is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  countryCitizenship: yup.string().required('Country citizenship is required'),
  countryResidence: yup.string().required('Country residence is required'),
  zipCode: yup.string().required('Zip code is required'),
  maritalStatus: yup.string().required('Marital status is required'),
  //dependentChildren: yup.string().required('Dependent children is required'),
  dependentChildren: yup.string().when('maritalStatus', {
    is:
      'Married' || 'Married' || 'Legally Separated' || 'Divorced' || 'Widowed',
    then: yup.string().required('Dependent children is required'),
  }),
  criminalOffense: yup.string().required('Police Record is required'),
  offenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  healthCondition: yup.string().required('Health condition is required'),
  healthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
  isSpouseOpen: yup.bool(),
  spouseInformation: yup.object().when('isSpouseOpen', {
    is: true,
    then: PersonalSpouseInfoSchema,
  }),
})
export const PersonalSponsoredSpouseInfoSchema = yup.object().shape({
  sFirstName: yup.string().required('First Name is required'),
  sMidName: yup.string(),
  sLastName: yup.string().required('Last Name is required'),
  sAge: yup.number(),
  sPhoneNumber: yup.string(),
  sMobile: yup.string(),
  sOfficePhoneNumber: yup.string(),
  sGender: yup.string().required('Gender is required'),

  sPrimaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sSecondaryEmail: yup.string(),
  sDateOfBirth: yup.string().required('Date of birth is required').nullable(),
  sBirthCountry: yup.string().required('Birth country is required'),
  sCity: yup.string().required('City is required'),
  sStreet: yup.string().required('Street is required'),
  sCountryCitizenship: yup.string().required('Country citizenship is required'),
  sCountryResidence: yup.string().required('Country residence is required'),
  sZipCode: yup.string().required('Zip code is required'),
  sMaritalStatus: yup.string().required('Marital status is required'),
  sDependentChildren: yup.string().when('sMaritalStatus', {
    is: 'Married' || 'Legally Separated' || 'Divorced' || 'Widowed',
    then: yup.string().required('Dependent children is required'),
  }),
  // sDependentChildren: yup.string().required('Dependent children is required'),
  sCriminalOffense: yup.string().required('Police Record is required'),
  sOffenseTypeDetail: yup.string().when('sCriminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sHealthCondition: yup.string().required('Health condition is required'),
  sHealthConditionDetail: yup.string().when('sHealthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})
export const sponsoredMainApplicant = yup.object().shape({
  sponsorFirstName: yup.string().required('First Name is required'),
  sponsorMidName: yup.string(),
  sponsorLName: yup.string().required('Last Name is required'),
  sponsorAge: yup.number(),
  sponsorPhoneNumber: yup.string(),

  sponsorMobile: yup.string(),
  sponsorOfficePhoneNumber: yup.string(),

  sponsorGender: yup.string().required('Gender is required'),

  sponsorPrimaryEmail: yup
    .string()
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sponsorSecondaryEmail: yup.string(),
  sponsorDateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .nullable(),
  sponsorBirthCountry: yup.string().required('Birth country is required'),
  sponsorCity: yup.string().required('City is required'),
  sponsorStreet: yup.string().required('Street is required'),
  sponsorCountryCitizenship: yup
    .string()
    .required('Country citizenship is required'),
  sponsorCountryResidence: yup
    .string()
    .required('Country residence is required'),
  sponsorZipCode: yup.string().required('Zip code is required'),
  sponsorMaritalStatus: yup.string().required('Marital status is required'),
  //dependentChildren: yup.string().required('Dependent children is required'),
  sponsorDependentChildren: yup.string().when('maritalStatus', {
    is: 'Married' || 'Legally Separated' || 'Divorced' || 'Widowed',
    then: yup.string().required('Dependent children is required'),
  }),
  sponsorCriminalOffense: yup.string().required('Police Record is required'),
  sponsorOffenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sponsorHealthCondition: yup.string().required('Health condition is required'),
  sponsorHealthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
  isSponsorSpouseOpen: yup.bool(),
  sponsorSpouseInformation: yup.object().when('isSponsorSpouseOpen', {
    is: true,
    then: PersonalSponsoredSpouseInfoSchema,
  }),
})
export const childValidation = yup.array().of(
  yup.object().shape({
    childFirstName: yup.string().required('First Name is required'),
    childMiddleName: yup.string(),
    childLastName: yup.string(),
    childGender: yup.string().required('Gender is required'),
    childDateOfBirth: yup.string().required('Date of birth is required'),
    childBirthCountry: yup.string().required('Birth country is required'),
    childBirthCity: yup.string().required('Birth city is required'),
    childCitizenship: yup.string().required('Child citizenship is required'),
    childSecondCitizenship: yup
      .string()
      .required('Child second citizenship is required'),
  }),
)
export const sponsoredChildValidation = yup.array().of(
  yup.object().shape({
    childFirstName: yup.string().required('First Name is required'),
    childMiddleName: yup.string(),
    childLastName: yup.string(),
    childGender: yup.string().required('Gender is required'),
    childDateOfBirth: yup.string().required('Date of birth is required'),
    childBirthCountry: yup.string().required('Birth country is required'),
    childBirthCity: yup.string().required('Birth city is required'),
    childCitizenship: yup.string().required('Child citizenship is required'),
    childSecondCitizenship: yup
      .string()
      .required('Child second citizenship is required'),
  }),
)

export const educationMainApplicant = yup.object().shape({
  yearsEducation: yup.string().required('Year of education is required'),
  highestLevelEdu: yup.string().required('Highest level education is required'),
  sYearsEducation: yup.string().required('Year of education is required'),
  sHighestLevelEdu: yup
    .string()
    .required('Highest level education is required'),
})
export const addEducationSchema = yup.array().of(
  yup.object().shape({
    fieldStudy: yup.string().required('Field study is required'),
    institution: yup.string().required('Institution is required'),
    countryStudy: yup.string().required('Country education is required'),
    studyDuration: yup.string().required('Study duration is required'),
    completed: yup.string().required('Education completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)

export const addEducationSpouseSchema = yup.array().of(
  yup.object().shape({
    fieldStudy: yup.string().required('Field study is required'),
    institution: yup.string().required('Institution is required'),
    countryStudy: yup.string().required('Country education is required'),
    studyDuration: yup.string().required('Study duration is required'),
    completed: yup.string().required('Education completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)
export const languageExperienceSchema = yup.object().shape({
  levelOfEnglish: yup.string().required('Level of english is required'),
  levelOfFrench: yup.string().required('Level of french is required'),
})
export const workExperienceMain = yup.object().shape({
  numberOffJobOffshore: yup
    .string()
    .required('Number of job offshore is required'),
  numberOffJobOnshore: yup
    .string()
    .required('Number of job onshore is required'),
})
export const documentSchema = yup.object().shape({
  frontPassportCopy: yup
    .mixed()
    .nullable()
    .required('Front passport copy is required'),
  updatedCv: yup.mixed().nullable().required('Updated CV is required'),
  digitalPhoto: yup.mixed().nullable().required('Digital photo is required'),
  birthCertificate: yup
    .mixed()
    .nullable()
    .required('Birth certificate is required'),
  bankStatement: yup.mixed().nullable().required('Bank statement  is required'),
  paymentReceipts: yup
    .mixed()
    .nullable()
    .required('Payment receipt is required'),
  letterClearanceInfo: yup
    .mixed()
    .nullable()
    .required('Letter clearance info is required'),
  letterAcceptance: yup
    .mixed()
    .nullable()
    .required('Letter acceptance is required'),
  educationalDiploma: yup
    .mixed()
    .nullable()
    .required('Education diploma is required'),
  canadianMedicalExamProof: yup
    .mixed()
    .nullable()
    .required('Canadian medical exam proof is required'),
  visaResident: yup.mixed().nullable().required('Visa residence is required'),
  spouseBirthCertificate: yup
    .mixed()
    .nullable()
    .required('Birth certificate is required'),
  spouseDigitalPhoto: yup
    .mixed()
    .nullable()
    .required('Digital photo is required'),
  spousePassportCopy: yup
    .mixed()
    .nullable()
    .required('Passport copy is required'),
})

export const workExperienceSchema = yup.array().of(
  yup.object().shape({
    yearsEducation: yup.string().required('Year of education is required'),
    highestLevelEdu: yup
      .string()
      .required('Highest level education is required'),
    fieldStudy: yup.string().required('Field study is required'),
    institution: yup.string().required('Institution is required'),
    countryStudy: yup.string().required('Country education is required'),
    studyDuration: yup.string().required('Study duration is required'),
    completed: yup.string().required('Education completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)
export const mainWorkExperience = yup.object().shape({
  numberOfJobOffshore: yup
    .string()
    .required('Number of job offshore is required'),
  numberOfJobOnshore: yup
    .string()
    .required('Number of job onshore is required'),
  sNumberOfJobOffshore: yup
    .string()
    .required('Number of job offshore is required'),
  sNumberOfJobOnshore: yup
    .string()
    .required('Number of job onshore is required'),
})

export const workExperiencesInCanada = yup.array().of(
  yup.object().shape({
    officialJobTitle: yup.string().required('Official job title is required'),
    jobDescription: yup.string().required('Job description is required'),
    fullTimePosition: yup.string().required('Full time position is required'),
    hoursPerWeek: yup.string().required('Hours week is required'),
    yearsExperience: yup.string().required('Years of experience is required'),
    jobStartDate: yup.string().required('Job start date is required'),
    jobEndTime: yup.string().required('Job end time is required'),
  }),
)
export const workExperiencesOutCanada = yup.array().of(
  yup.object().shape({
    InCanOfficialJobTitle: yup
      .string()
      .required('Official job title is required'),
    InCanJobDescription: yup.string().required('Job description is required'),
    InCanFullTimePosition: yup
      .string()
      .required('Full time position is required'),
    InCanHoursPerWeek: yup.string().required('Hours week is required'),
    InCanYearsExperience: yup
      .string()
      .required('Year of education is required'),
    InCanJobStartDate: yup.string().required('Job start date is required'),
    InCanJobEndTime: yup.string().required('Job end time required'),
  }),
)
// export const workExperienceOutsideCanada = yup.object({
//   numberOfJobOffshore: yup.string(),
//   jobsError: yup.array().when('numberOfJobOffshore', {
//     is: function (item) {
//       console.log('item is here in validation schema-->', item)
//       return getValue('numberOfJobOffshore', item)
//     },
//     then: yup.array().of(
//       yup.object({
//         officialJobTitle: yup
//           .string()
//           .required('Official job title is required'),
//         jobDescription: yup.string().required('Job description is required'),
//         fullTimePosition: yup
//           .string()
//           .required('Full time position is required'),
//         hoursPerWeek: yup.string().required('Hours week is required'),
//         yearsExperience: yup
//           .string()
//           .required('Years of experience is required'),
//         jobStartDate: yup.string().required('Job start date is required'),
//         jobEndTime: yup.string().required('Job end time is required'),
//       }),
//     ),
//   }),
// })
// export const workExperienceInsideCanada = yup.object({
//   numberOfJobOnshore: yup.string(),
//   jobsError: yup.array().when('numberOfJobOnshore', {
//     is: function (item) {
//       console.log('item is here in validation schema-->', item)
//       return getValue('numberOfJobOnshore', item)
//     },
//     then: yup.array().of(
// yup.object({
//   InCanOfficialJobTitle: yup
//     .string()
//     .required('Official job title is required'),
//   InCanJobDescription: yup
//     .string()
//     .required('Job description is required'),
//   InCanFullTimePosition: yup
//     .string()
//     .required('Full time position is required'),
//   InCanHoursPerWeek: yup.string().required('Hours week is required'),
//   InCanYearsExperience: yup
//     .string()
//     .required('Year of education is required'),
//   InCanJobStartDate: yup.string().required('Job start date is required'),
//   InCanJobEndTime: yup.string().required('Job end time required'),
// }),
//     ),
//   }),
// })
export const invoiceReportSchema = yup.object().shape({
  fromDate: yup.string().required('From Date is required'),
  toDate: yup.string().required('To date is required'),
  type: yup.string().required('Report type is required'),
})
export const visaEvaluationSchema = yup.object({
  age: yup.string().required('age is required'),
  workExperience: yup.string().required('Work experience is required'),
  academic: yup.string().required('Academic is required'),
  langWrite: yup
    .number()
    .min(1, 'Language write skills is required')
    .required('Language write skills is required'),
  langRead: yup
    .number()
    .min(1, 'Language read skills is required')
    .required('Language read skills is required'),
  langSpeak: yup
    .number()
    .min(1, 'Language speak skills is required')
    .required('Language speak skills is required'),
  langListen: yup
    .number()
    .min(1, 'Language listening skills is required')
    .required('Language listening skills is required'),
})
