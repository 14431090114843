import React from "react";
import { Link } from "react-router-dom";
import heart from "../../assets/images/application/heart.svg";

const ApplicationFooter = () => {
  return (
    <div className={`application-footer`}>
      <div className="container">
        <div className={`application-footer-menu`}>
          <ul>
            <li>
              <Link to="/termconditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <a href="mailto:support@uiscanada.com">Support</a>
            </li>
          </ul>
          <p>
            Copyright © 2010-2021 All rights reserved UIS Canada with{" "}
            <img src={heart} alt="" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApplicationFooter;
